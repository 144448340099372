import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Container, Button, Modal, Form } from "react-bootstrap";
import WarningModel from "../../../GlobalComponents/WarningModel";
import { useNavigate } from "react-router-dom";
import { LoginInfoContext, LoginUserLocationContext } from "../../../Context";
import InputWithLoader from "./SearchInputFieldWithLoader";
import SelectBody from "./SelectBody";
import DropdownTreeSelect from "react-dropdown-tree-select";

function SearchFacility({
  searchFacilityState,
  setSearchFacilityState,
  setFacility,
  selectFacility,
}) {
  const [selectedFacility, setSelectedFacility] = useState("");
  const [locationsDataJson, setLocationsDataJson] = useState(
    JSON.parse(localStorage.getItem("locations"))
  );
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const handleCloseSaveChangesModal = async () => {
    if (selectedFacilityId != "") {
      setSelectedFacility(selectedFacilityId);
      if (selectedFacilityId != selectedLocation && !setFacility) {
        setCancelModalShow(true);
      } else {
        if (selectedFacilityId != "") {
          selectFacility(selectedFacilityId);
        } else {
          selectFacility(selectedFacility);
        }
      }
    } else if (selectedFacility != "") {
      if (!setFacility && selectedFacility != selectedLocation) {
        setCancelModalShow(true);
      } else {
        selectFacility(selectedFacility);
      }
    }
    selectedFacilityId = "";
    setSearchFacilityState(false);
    setFilter("");
    setFacilityCodeState("");
  };
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [facilityCodeState, setFacilityCodeState] = useState("");
  const [facilitiesCode, setFacilitiesCode] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const rowClickHandler = (selectedFacilityCodeObj, id) => {
    setShowResults(false);
    setFilter("");
    setFacilityCodeState(
      selectedFacilityCodeObj[1] + ": " + selectedFacilityCodeObj[2]
    );
    setSelectedFacility(id);
    selectedFacilityId = "";
    // accordionToggle("patientAccordion", "open");
    // onChange(id);
  };
  const facilityCodeData = [];

  if (localStorage.getItem("locations") != null) {
    const locations = JSON.parse(localStorage.getItem("selectableLocations"));
    for (let i = 0; i < locations.length; i++) {
      const facilityCodeDataObj = [
        locations[i].id.toString(),
        locations[i].facilityCode,
        locations[i].label,
      ];
      facilityCodeData.push(facilityCodeDataObj);
    }
  }
  var selectedFacilityId = "";
  var favilityDivs;
  return (
    <>
      <Modal
        show={searchFacilityState}
        onHide={() => {
          setSearchFacilityState(false);
          setSelectedFacility("");
          setFilter("");
          setFacilityCodeState("");
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Search and Select Facility</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={{ span: 6 }}>
                {" "}
                <h5 className="label-style">Facility Name</h5>{" "}
                <DropdownTreeSelect
                  mode="radioSelect"
                  keepTreeOnSearch={true}
                  keepChildrenOnSearch={true}
                  keepOpenOnSelect={false}
                  onFocus={() => {
                    setFilter("");
                    setFacilityCodeState("");
                  }}
                  data={locationsDataJson}
                  onChange={(e) => {
                    selectedFacilityId = e.id;
                  }}
                  className="bootstrap-demo"
                />
              </Col>
              <Col md={{ span: 6 }}>
                <h5 className="label-style">Facility Code</h5>

                {/* <Form.Control
                  required
                  aria-label="Facility Code"
                  type="text"
                  name="facilityCode"
                  className="input-style"
                  onChange={(e) => {}}
                /> */}

                <InputWithLoader
                  id="facilityCode"
                  filter={filter}
                  placeholder="Start typing..."
                  apiEndPoint=""
                  setData={setFacilitiesCode}
                  setShowResults={setShowResults}
                  setFilter={setFilter}
                  className="input-style form-control"
                />
                {facilityCodeState != "" && (
                  <span className="facilityCodeState">
                    {facilityCodeState}
                    <button
                      className="facilityCodeStateClose"
                      onClick={() => {
                        setFilter("");
                        setFacilityCodeState("");
                      }}
                    >
                      x
                    </button>
                  </span>
                )}
                {showResults ? (
                  <SelectBody
                    rowClickHandler={rowClickHandler}
                    filter={filter}
                    column={["Facility Code", "Facility Name"]}
                    dataArray={facilityCodeData}
                  />
                ) : null}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="primary btn button"
            onClick={handleCloseSaveChangesModal}
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
      <WarningModel
        show={cancelModalShow}
        heading="Warning"
        bodytext="You are trying to change the facility. Any changes that you have made will be lost. Are you sure you want to change the facility?"
        onHide={() => {
          setCancelModalShow(false);
          setSelectedFacility("");
        }}
        onyes={async () => {
          localStorage.setItem("selectedLocation", selectedFacility);
          setSelectedLocation(selectedFacility);
          setCancelModalShow(false);
          setSelectedFacility("");
          navigate("/patients");
        }}
      />
    </>
  );
}
export default SearchFacility;

export function getTreeOptionsSelected(obj, type, seletedArray) {
  obj.forEach((element, index) => {
    if (element["children"] != undefined) {
      if (element["children"].length != 0) {
        if (seletedArray.indexOf(element[type]) > -1) {
          element.checked = true;
        } else {
          element.checked = false;
        }
        getTreeOptionsSelected(element["children"], type, seletedArray);
      } else {
        if (seletedArray.indexOf(element[type]) > -1) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      }
    } else {
      if (seletedArray.indexOf(element[type]) > -1) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    }
  });
  return obj;
}

export function getAllPhysicalFacilitiesFromTree(obj, facilitiesObjArray) {
  obj.forEach((element, index) => {
    if (element["children"].length != 0) {
      if (element.facilityType == 44) {
        facilitiesObjArray.push({ id: element.id, code: element.facilityCode });
      }
      getAllPhysicalFacilitiesFromTree(element["children"], facilitiesObjArray);
    } else {
      if (element.facilityType == 44) {
        facilitiesObjArray.push({ id: element.id, code: element.facilityCode });
      }
    }
  });
  return facilitiesObjArray;
}
export function getAllPhysicalFromTree(obj, facilitiesObjArray, searchQuery) {
  obj[0].children
    ?.filter((x) => x.label.toLowerCase().includes(searchQuery.toLowerCase()))
    .forEach((element, index) => {
      if (element["children"].length != 0) {
        if (element.id != 0) facilitiesObjArray.push({ id: element.id });

        getAllPhysicalFromTree(element["children"], facilitiesObjArray);
      } else {
        facilitiesObjArray.push({ id: element.id });
      }
    });
  return facilitiesObjArray;
}

export function getChildObjStructure(obj, id) {
  // obj.forEach((element, index) => {
  var objTOreturn = null;
  for (var i = 0; i < obj.length; i++) {
    if (obj[i]["children"].length != 0) {
      if (obj[i].id == id) {
        return obj[i];
      }
      objTOreturn = getChildObjStructure(obj[i]["children"], id);
      if (objTOreturn != null) {
        return objTOreturn;
      }
    } else {
      if (obj[i].id == id) {
        return obj[i];
      }
    }
  }
  // });
  return objTOreturn;
}
