import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./General.css";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import OrderPage from "./Pages/OrderPage/OrderPage";
import Patients from "./Pages/PatientPage/Patients";
import Orders from "./Pages/OrderPage/Orders";
import LogIn from "./Pages/LogIn";
import { FaCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import { FaExclamationCircle } from "react-icons/fa";
import { LoginInfoContext, LoginUserLocationContext } from "./Context.js";
import Results from "./Pages/ResultPage/Results";
import Profile from "./Pages/ProfilePage/Profile";
import AddPatient from "./Pages/PatientPage/Components/AddPatient";
import ResultList from "./Pages/ResultPage/ResultList";
import ResultInboxDetail from "./Pages/ResultPage/ResultInboxDetail";
import Upload from "./Pages/UploadPage/Upload";
import DownloadLabelApp from "./Pages/DownloadLabelApp";
import ChangePassword from "./Pages/ChangePassword";
import Hl7ResultsStatus from "./Pages/Hl7Results/Hl7ResultsStatus";
import Hl7ResultsDetail from "./Pages/Hl7Results/Hl7ResultsDetail";
import DownloadResultPdf from "./Pages/DownloadResultPdf";
import OrderManifest from "./Pages/OrderManiFest/OrderManifest";
import MNFailedOrders from "./Pages/OrderManiFest/MNFailedOrders.js";
import PendingOrders from "./Pages/OrderManiFest/PendingOrders";
import PhlebotomyOrders from "./Pages/PSCPage/PhlebotomyOrders.js";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import FirstLoginCheck from "./FirstLoginCheck.js";
import { ColorRing } from "react-loader-spinner";
import CheckIfUserLoggedIn from "./CheckIfUserLoggedIn.js";

function App() {
  const [selectedOrderPatientId, setSelectedOrderPatientId] = useState("");
  const [context, setContext] = useState({});
  const [selectedLocation, setSelectedLocation] = useState("");
  const [resultIds, setResultIds] = useState([]);
  const [savedOrderId, setSavedOrderId] = useState("");
  useEffect(() => {
    //if (context == {}) {
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") != null &&
      localStorage.getItem("userInfo") != null &&
      userInfoJson?.userFirstName != undefined &&
      userInfoJson?.userLastName != undefined
    )
      setContext({
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
        token: localStorage.getItem("token"),
        locations: JSON.parse(localStorage.getItem("selectableLocations")),
      });
    //}
    // if (selectedLocation == "") {
    if (localStorage.getItem("selectedLocation") != null) {
      setSelectedLocation(localStorage.getItem("selectedLocation"));
    } else {
      localStorage.removeItem("locations");
      localStorage.removeItem("token");
      localStorage.removeItem("selectedLocation");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userPermissions");
      setSelectedLocation("");
      localStorage.removeItem("selectableLocations");
      localStorage.removeItem("userId");
      localStorage.removeItem("firstLogin");
    }
    //}
  }, []);
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true}
      isVerboseMode={false}
      metaFileDirectory={"."}
      reloadOnDowngrade={true}
      loadingComponent={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#03316d", "#03316d", "#03316d", "#03316d", "#03316d"]}
          />
          <h2>Checking for the updates</h2>
        </div>
      }
    >
      <LoginInfoContext.Provider value={[context, setContext]}>
        <LoginUserLocationContext.Provider
          value={[selectedLocation, setSelectedLocation]}
        >
          <Router>
            <Routes>
              <Route
                path="/patients"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <Patients
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/orders/:id"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <Orders
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/order/new"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <OrderPage
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                          createPatient={"N"}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/order/:id"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <OrderPage
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                          createPatient={"N"}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/ordertemplate/:id"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <OrderPage
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                          createPatient={"N"}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route path="/" element={<LogIn />} />
              <Route
                path="/results"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <Results
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/profile"
                element={
                  //localStorage.getItem("token") != null ? (
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <Profile
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>

                  // ) : (
                  //   <Navigate to="/" />
                  // )
                }
              />
              <Route
                path="/patient/new"
                element={
                  // <OrderPage
                  //   selectedOrderPatientId={selectedOrderPatientId}
                  //   setSelectedOrderPatientId={setSelectedOrderPatientId}
                  //   savedOrderId={savedOrderId}
                  //   setSavedOrderId={setSavedOrderId}
                  //   createPatient={"Y"}
                  // />
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <AddPatient
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/patient/:id"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <AddPatient
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>

                  // <OrderPage
                  //   selectedOrderPatientId={selectedOrderPatientId}
                  //   setSelectedOrderPatientId={setSelectedOrderPatientId}
                  //   savedOrderId={savedOrderId}
                  //   setSavedOrderId={setSavedOrderId}
                  //   createPatient={"Y"}
                  // />
                }
              />
              <Route
                path="/resultList"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <ResultList
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                          setResultIds={setResultIds}
                          resultIds={resultIds}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              {/* <Route
              path="/resultDetail/:id"
              element={
                <ResultInboxDetail
                  selectedOrderPatientId={selectedOrderPatientId}
                  setSelectedOrderPatientId={setSelectedOrderPatientId}
                  savedOrderId={savedOrderId}
                  setSavedOrderId={setSavedOrderId}
                  setResultIds={setResultIds}
                  resultIds={resultIds}
                />
              }
            /> */}
              <Route
                path="/upload/orders"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <Upload
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/downloads"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <DownloadLabelApp
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/changePassword"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <ChangePassword
                        selectedOrderPatientId={selectedOrderPatientId}
                        setSelectedOrderPatientId={setSelectedOrderPatientId}
                        savedOrderId={savedOrderId}
                        setSavedOrderId={setSavedOrderId}
                      />
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/hl7ResultsStatus"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <Hl7ResultsStatus
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/Hl7ResultDetail/:id"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <Hl7ResultsDetail
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                          setResultIds={setResultIds}
                          resultIds={resultIds}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/downloadPdf/:id"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <DownloadResultPdf
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/orderManifest"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <OrderManifest
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                          setResultIds={setResultIds}
                          resultIds={resultIds}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/MNFailedOrders"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <MNFailedOrders
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                          setResultIds={setResultIds}
                          resultIds={resultIds}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              <Route
                path="/PhlebotomyOrders"
                element={
                  <CheckIfUserLoggedIn
                    children={
                      <FirstLoginCheck>
                        <PhlebotomyOrders
                          selectedOrderPatientId={selectedOrderPatientId}
                          setSelectedOrderPatientId={setSelectedOrderPatientId}
                          savedOrderId={savedOrderId}
                          setSavedOrderId={setSavedOrderId}
                          setResultIds={setResultIds}
                          resultIds={resultIds}
                        />
                      </FirstLoginCheck>
                    }
                  ></CheckIfUserLoggedIn>
                }
              />
              {/* <Route
              path="/pendingOrders"
              element={
                <PendingOrders
                  selectedOrderPatientId={selectedOrderPatientId}
                  setSelectedOrderPatientId={setSelectedOrderPatientId}
                  savedOrderId={savedOrderId}
                  setSavedOrderId={setSavedOrderId}
                />
              }
            /> */}
            </Routes>
          </Router>
          <div
            id="alertMessageDiv"
            className="positionedtopright notification"
            hidden
          >
            <FaExclamationCircle
              id="icon-danger"
              className="iconAlert"
              hidden
              size={25}
            />
            <FaCheckCircle
              id="icon-success"
              className="iconAlert"
              hidden
              size={25}
            />
            <FaExclamationCircle
              id="icon-info"
              className="iconAlert"
              hidden
              size={25}
            />
            &nbsp; <span id="alertMessageBody"></span>
            <FaTimesCircle
              className="closeButton"
              size={15}
              onClick={() => {
                let snackBar = document.getElementById("alertMessageDiv");
                snackBar.classList.remove("success");
                snackBar.classList.remove("danger");
                snackBar.classList.remove("info");
                let snackBarIcons =
                  document.getElementsByClassName("iconAlert");
                for (let icon of snackBarIcons) {
                  icon.removeAttribute("hidden");
                  icon.setAttribute("hidden", "true");
                }
                let snackBarMessage =
                  document.getElementById("alertMessageBody");
                snackBarMessage.innerHTML = "";
                snackBar.hidden = true;
              }}
            />
          </div>
        </LoginUserLocationContext.Provider>
      </LoginInfoContext.Provider>
    </CacheBuster>
  );
}

export default App;
