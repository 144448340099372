import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents/mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import "../OrderPage/OrderPage.css";
import { ApiPostCalls } from "../../services/Api";
import { useParams } from "react-router-dom";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import CustomLoader from "../../GlobalComponents/Loader";
import { apiUrl } from "../../config/config";
import { param } from "jquery";

function Hl7ResultsDetail(props) {
  const [modalShow, setModalShow] = useState(false);
  const [searchFilter, setSearchFilter] = useState({});
  const [reportErrors, setReportErrors] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecordPerPage, setTotalRecordPerPage] = useState(10);
  const [pagenationState, setPagenationState] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);

  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Reports";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("#/");
    } else {
      setSearchFilter({
        fileName: params != null ? params.fileName != null ? params.fileName : null : null,
        status: null,
        requisition: params != null ? params.requisition != null ? params.requisition : null : null,
        accession: params != null ? params.accession != null ? params.accession : null : null,
        summaryId: id,
        pageNumber: 1,
        totalRecordPerPage: totalRecordPerPage,
      });
      setPagenationState(Math.random());
    }
  }, []);
  const [orderList, setOrderList] = useState([]);
  const handleSearchFilterChange = (name, value) => {
    setSearchFilter((values) => ({
      ...values,
      [name]: value == "" ? null : value,
    }));
  };
  let { id } = useParams();
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * 1) % 2;
    handleSearchFilterChange("pageNumber", event.selected + 1);
    setPagenationState(Math.random());
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSearchFilterChange("pageNumber", 1);
      setPagenationState(Math.random());
      setCurrentPage(0);
    }
  };
  async function getResults() {
    try {
      var body = JSON.stringify(searchFilter);
      var data = await ApiPostCalls(body, "getHl7LogDetail");
      if (data.length > 0) {
        setTotalRecords(Math.ceil(data[0].totalRecords / totalRecordPerPage));
        setOrderList(data);
      } else {
        setOrderList([]);
        setTotalRecords(0);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }

  const [showResults, setShowResults] = useState(false);
  const [sexLookup, setSexLookup] = useState({});

  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });

  useEffect(() => {
    if (pagenationState != -1) {
      setLoader(true);
      getResults();
    }
  }, [pagenationState]);
  const [showLoader, setLoader] = useState(false);
  return (
    <div onClick={() => setShowResults(false)}>
      {showLoader && <CustomLoader />}
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="pageheight-orderPage">
        <Container className="mb-5">
          <div className="partition">
            <h3 className="d-flex flex-row align-items-end align-content-end">
              Hl7 Files
            </h3>
            <span className="mb-4 d-flex flex-row align-items-end align-content-end">
              Process Id{": "}
              {orderList.length > 0
                ? id +
                  " - " +
                  moment(orderList[0].results[0].startedAt).format(
                    "MM/DD/yyyy HH:mm:ss"
                  ) +
                  " - " +
                  moment(orderList[0].results[0].endedAt).format(
                    "MM/DD/yyyy HH:mm:ss"
                  ) +
                  " - Process duration: " +
                  Math.floor(
                    moment
                      .duration(
                        moment(new Date(orderList[0].results[0].endedAt)).diff(
                          moment(new Date(orderList[0].results[0].startedAt))
                        )
                      )
                      .asHours()
                  ) +
                  moment
                    .utc(
                      moment(new Date(orderList[0].results[0].endedAt)).diff(
                        moment(new Date(orderList[0].results[0].startedAt))
                      )
                    )
                    .format(":mm:ss")
                : id}
            </span>
            <span className="mb-4 d-flex flex-row align-items-end align-content-end">
              {orderList.length > 0
                ? "Total files processed: " +
                  orderList[0].results[0].totalFilesProcessed +
                  " - No of files failed: " +
                  orderList[0].results[0].noOfFilesFailed +
                  " - No of files passed: " +
                  orderList[0].results[0].noOfSuccess
                : ""}
            </span>
            <hr></hr>
            <h5>Search</h5>
            <hr></hr>

            <Row>
              <Col>
                <h5 className="label-style">File Name</h5>
                <input
                  name="fileName"
                  value={
                    searchFilter["fileName"] == null
                      ? ""
                      : searchFilter["fileName"]
                  }
                  type="text"
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                  className="input-style form-control mb-3"
                  onKeyDown={handleKeypress}
                />
              </Col>
              <Col>
                <h5 className="label-style">Status</h5>
                <Form.Select
                  name="status"
                  value={
                    searchFilter["status"] == null ? "" : searchFilter["status"]
                  }
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                >
                  <option value={""}>All</option>
                  <option value={"completed"}>Passed</option>

                  <option value={"errors"}>Failed</option>
                </Form.Select>
              </Col>
              <Col>
                <h5 className="label-style">Requisition #</h5>
                <input
                  name="requisition"
                  value={
                    searchFilter["requisition"] == null
                      ? ""
                      : searchFilter["requisition"]
                  }
                  type="text"
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                  className="input-style form-control mb-3"
                  onKeyDown={handleKeypress}
                />
              </Col>
              </Row>
              <Row>
                <Col className="col-md-4">
                <h5 className="label-style">Accession #</h5>
                <input
                  name="accession"
                  value={
                    searchFilter["accession"] == null
                      ? ""
                      : searchFilter["accession"]
                  }
                  type="text"
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                  className="input-style form-control mb-3"
                  onKeyDown={handleKeypress}
                />
                </Col>
              </Row>
              <Row>
              <Col className="d-flex flex-row align-items-end align-content-end ">
                <Button
                  variant="outline "
                  className="outline button mb-3 ml-auto"
                  onClick={() => {
                    setSearchFilter({
                      fileName: null,
                      requisition: null,
                      accession: null,
                      status: null,
                      summaryId: id,
                      pageNumber: 1,
                      totalRecordPerPage: totalRecordPerPage,
                    });
                    setPagenationState(Math.random());
                    setCurrentPage(0);
                    window.history.replaceState({}, '');
                  }}
                >
                  Reset
                </Button>
                <Button
                  variant="primary"
                  className="primary btn button ml-2 mb-3"
                  onClick={() => {
                    handleSearchFilterChange("pageNumber", 1);
                    setPagenationState(Math.random());
                    setCurrentPage(0);
                  }}
                >
                  Filter
                </Button>
              </Col>
            </Row>
            <Table className="mt-4" responsive="md" bordered hover>
              <thead>
                <tr className="DarkHeading">
                  <th width="30%">File Name</th>
                  <th className="text-center">Requisition #</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Errors & Warnings</th>
                  <th className="text-center">Started at</th>
                  <th className="text-center">Ended at</th>
                  <th className="text-center">Process duration</th>
                </tr>
              </thead>
              <tbody>
                {orderList.length > 0 ? (
                  orderList.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>
                        <a
                              title="Download Log File"
                              className="errorsCount cursorPointer"
                              onClick={() => {
                                exportHl7LogFile(value.hL7LogHeadersId, value.results[0].hL7FileName);
                              }}
                            >
                              {value.results[0].hL7FileName}
                            </a>
                          </td>
                          <td>
                          {value.results[0].requisitionNumber != null
                            ? value.results[0].requisitionNumber
                            : "-"}
                        </td>
                        <td>
                          {value.results[0].errorsCount > 0
                            ? "Failed"
                            : value.results[0].warningsCount > 0
                            ? "Passed"
                            : "Passed"}
                        </td>
                        {/* <td>{}</td> */}
                        <td align="center">
                          <a
                            title="View Errors & Warnings"
                            className={
                              value.results[0].warningsCount +
                                value.results[0].errorsCount >
                              0
                                ? "errorsCount cursorPointer"
                                : ""
                            }
                            onClick={() => {
                              if (
                                value.results[0].warningsCount +
                                  value.results[0].errorsCount >
                                0
                              ) {
                                setReportErrors(value.results);

                                setModalShow(true);
                              }
                            }}
                          >
                            (
                            {value.results[0].warningsCount +
                              value.results[0].errorsCount}
                            )
                          </a>
                        </td>
                        <td>
                          {moment(value.results[0]["fileStartedAt"]).format(
                            "MM/DD/yyyy HH:mm:ss.SSS"
                          )}
                        </td>
                        <td>
                          {moment(value.results[0]["fileEndedAt"]).format(
                            "MM/DD/yyyy HH:mm:ss.SSS"
                          )}
                        </td>
                        <td>
                          {Math.floor(
                            moment
                              .duration(
                                moment(
                                  new Date(value.results[0]["fileEndedAt"])
                                ).diff(
                                  moment(
                                    new Date(value.results[0]["fileStartedAt"])
                                  )
                                )
                              )
                              .asHours()
                          ) +
                            moment
                              .utc(
                                moment(
                                  new Date(value.results[0]["fileEndedAt"])
                                ).diff(
                                  moment(
                                    new Date(value.results[0]["fileStartedAt"])
                                  )
                                )
                              )
                              .format(":mm:ss")}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>{"No Files Found"}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <ReactPaginate
              forcePage={currentPage}
              className="pagination"
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalRecords}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              activeClassName="active"
              pageClassName="link"
            />

            <>
              <span>showing</span>
              <Form.Select
                value={totalRecordPerPage}
                className={"form-select w-auto d-inline ml-1 mr-1 form-control"}
                onChange={(e) => {
                  setTotalRecordPerPage(e.target.value);
                  handleSearchFilterChange(
                    "totalRecordPerPage",
                    e.target.value
                  );
                  handleSearchFilterChange("pageNumber", 1);
                  setCurrentPage(0);
                  setPagenationState(Math.random());
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Form.Select>
              <span>per page</span>
              <div className="leftcontrolls" style={{ float: "right" }}>
                <Button
                  variant="outline"
                  className="outline btn button ml-auto"
                  onClick={() => {
                    navigate('/hl7ResultsStatus');
                  }}
                >
                  Back
                </Button>
              </div>
            </>
          </div>
        </Container>
      </div>

      <CustomFooter showOrderFooterBar={false} />
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setTimeout(() => {
            document
              .getElementById("selectedTestsAccordions")
              .scrollIntoView({ behavior: "smooth", block: "center" });
          }, 300);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Errors & Warnings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="suggestedDiagnosisModal">
            <table
              className="MNDialogBox table"
              cellPadding={"2"}
              cellSpacing={"0"}
            >
              <tr className="DarkHeading sticky-top">
                <th>Line #</th>
                <th>Errors & Warnings</th>
              </tr>

              {reportErrors.map((value) => {
                return value.hL7LogDetails.map((val) => (
                  <tr>
                    <td>{val.lineNumber}</td>
                    <td>
                      <Row>{val.logLevel + ": " + val.notes}</Row>
                    </td>
                  </tr>
                ));
              })}
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="outline btn button"
            onClick={() => {
              setModalShow(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
  async function exportHl7LogFile(id, fileName) {
    setLoader(true);
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Response-Type": "blob",
      },
    };
    const response = await fetch(
      apiUrl + "DownloadHL7File/" + id,
      requestOptions
    )
      .then(async (response) => {
        if (response.status.toString() == "200") {
          return response.blob();
        } else {
          if (response.status.toString() == "401") {
            const accessToken = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            const refreshPayload = JSON.stringify({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });

            var refreshTokenResponse = await ApiPostCalls(
              refreshPayload,
              "refresh-token"
            );
            localStorage.setItem("token", refreshTokenResponse["accessToken"]);
            localStorage.setItem(
              "refreshToken",
              refreshTokenResponse["refreshToken"]
            );
            exportHl7LogFile(id, fileName);
          }
          return false;
        }
      })
      .then((blob) => {
        setLoader(false);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        var today = new Date();
        // var fileNameWithDateString =
        //   today.getFullYear().toString() +
        //   (today.getMonth() + 1).toString().padStart(2, "0") +
        //   today.getDate().toString() +
        //   today.getHours().toString() +
        //   today.getMinutes().toString();
        a.download = fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }
}
export default Hl7ResultsDetail;
