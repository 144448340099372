import React, { useState, useEffect, useContext, useRef } from "react";
import { Container } from "react-bootstrap";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents/mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";
import "../OrderPage/OrderPage.css";
import { ApiGetCalls, ApiPostCalls, downloadFile } from "../../services/Api";

import dateFormat from "dateformat";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { FaTrash } from "react-icons/fa";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import InputWithLoader from "../OrderPage/Components/SearchInputFieldWithLoader";
import SelectBody from "../OrderPage/Components/SelectBody";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import SaveSearchCriteriaModel from "./Components/SaveSearchCriteriaModel";
import WarningModel from "../../GlobalComponents/WarningModel";
import { LoginUserLocationContext } from "../../Context";
import axios from "axios";
import fileDownload from "react-file-download";
import { apiUrl } from "../../config/config";
import DropdownTreeSelect from "react-dropdown-tree-select";
import {
  getAllPhysicalFacilitiesFromTree,
  getChildObjStructure,
  getTreeOptionsSelected,
  getAllPhysicalFromTree,
} from "../OrderPage/Components/SearchFacilityModel";
import { BsPrinter, BsTrash } from "react-icons/bs";
import { snackBar } from "../../services/Alert";
import CustomLoader from "../../GlobalComponents/Loader";
import { LookupText } from "../../GlobalComponents/ValidatorAndSubmitForm";
import MaskedInput from "react-text-mask";

function Results(props) {
  const [insuranceSearchQuery, setInsuranceSearchQuery] = useState("");
  const [providerSearchQuery, setProviderSearchQuery] = useState("");
  const [facilitySearchQuery, setFacilitySearchQuery] = useState("");
  const [orderStatusState, setOrderStatusState] = useState("All");
  const [showLoader, setLoader] = useState(false);
  const concernedElement = document.querySelector(".search");

  document.addEventListener("keyup", (event) => {
    if (
      event.target.classList.contains("search") &&
      event.target.closest(".facilityTree")
    ) {
      setFacilitySearchQuery(event.target.value);
    }
  });
  document.addEventListener("keyup", (event) => {
    if (
      event.target.classList.contains("search") &&
      event.target.closest(".insuranceTree")
    ) {
      setInsuranceSearchQuery(event.target.value);
    }
  });
  document.addEventListener("keyup", (event) => {
    if (
      event.target.classList.contains("search") &&
      event.target.closest(".providerTree")
    ) {
      setProviderSearchQuery(event.target.value);
    }
  });
  useEffect(() => {
    setTimeout(function () {
      var divId = document.getElementById("customSearchButtonId");
      if (divId) {
        divId.remove();
      }
      var searchButton = document.getElementsByClassName(
        "insuranceTreeOkButton"
      );

      if (searchButton.length == 0) {
        var scrollDiv = document.getElementsByClassName(
          "infinite-scroll-component"
        );
        var scrollDiv1 = document.getElementsByClassName("dropdown-content");
        var noMatch = document.getElementsByClassName("no-matches");
        var div = document.createElement("div");
        div.className = "customSearchButton";
        div.id = "customSearchButtonId";
        var button = document.createElement("button");
        button.className =
          "primary btn button SearchBtnCustom insuranceTreeOkButton";
        button.innerHTML = "OK";
        button.addEventListener("click", (event) => {
          var facilityDiv = document.getElementsByClassName("insuranceTree");
          var toggleButton =
            facilityDiv[0].getElementsByClassName("dropdown-trigger");
          toggleButton[0].click();
          // divToMention.classList.remove("form-control");
        });
        div.appendChild(button);
        if (noMatch.length != 0) {
          scrollDiv1[0].appendChild(div);
        } else {
          if (scrollDiv.length > 0) {
            scrollDiv[0].parentElement.appendChild(div);
          }
        }
      }
    }, 100);
  }, [insuranceSearchQuery]);
  useEffect(() => {
    setTimeout(function () {
      var divId = document.getElementById("customSearchButtonId");
      if (divId) {
        divId.remove();
      }
      var searchButton = document.getElementsByClassName(
        "providerTreeOkButton"
      );
      if (searchButton.length == 0) {
        var scrollDiv = document.getElementsByClassName(
          "infinite-scroll-component"
        );
        var scrollDiv1 = document.getElementsByClassName("dropdown-content");
        var noMatch = document.getElementsByClassName("no-matches");
        var div = document.createElement("div");
        div.className = "customSearchButton";
        div.id = "customSearchButtonId";
        var button = document.createElement("button");
        button.className =
          "primary btn button SearchBtnCustom providerTreeOkButton";
        button.innerHTML = "OK";
        button.addEventListener("click", (event) => {
          var facilityDiv = document.getElementsByClassName("providerTree");
          var toggleButton =
            facilityDiv[0].getElementsByClassName("dropdown-trigger");
          toggleButton[0].click();
          // divToMention.classList.remove("form-control");
        });

        div.appendChild(button);
        if (noMatch.length != 0) {
          scrollDiv1[0].appendChild(div);
        } else {
          if (scrollDiv.length > 0) {
            scrollDiv[0].parentElement.appendChild(div);
          }
        }
      }
    }, 100);
  }, [providerSearchQuery]);
  useEffect(() => {
    setTimeout(function () {
      var divId = document.getElementById("customSearchButtonId");
      if (divId) {
        divId.remove();
      }
      var searchButton = document.getElementsByClassName(
        "facilityTreeOkButton"
      );
      if (searchButton.length == 0) {
        var scrollDiv = document.getElementsByClassName(
          "infinite-scroll-component"
        );
        var scrollDiv1 = document.getElementsByClassName("dropdown-content");
        var noMatch = document.getElementsByClassName("no-matches");
        var div = document.createElement("div");
        div.className = "customSearchButton";
        div.id = "customSearchButtonId";
        var button = document.createElement("button");
        button.className =
          "primary btn button SearchBtnCustom facilityTreeOkButton";
        button.innerHTML = "OK";
        button.addEventListener("click", (event) => {
          var facilityDiv = document.getElementsByClassName("facilityTree");
          var toggleButton =
            facilityDiv[0].getElementsByClassName("dropdown-trigger");
          toggleButton[0].click();
          // divToMention.classList.remove("form-control");
        });
        div.appendChild(button);
        if (noMatch.length != 0) {
          scrollDiv1[0].appendChild(div);
        } else {
          if (scrollDiv.length > 0) {
            scrollDiv[0].parentElement.appendChild(div);
          }
        }
      }
    }, 100);
  }, [facilitySearchQuery]);

  const navigate = useNavigate();
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Facility Reports";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    } else {
      var curr = new Date();
      var lastMonday = getPreviousMonday(curr);
      setSearchFilter({
        startDate: moment(new Date(lastMonday)).format("yyyy-MM-DD"),
        endDate: moment(new Date(curr)).format("yyyy-MM-DD"),
        dateType: "Result",
        pageNumber: 1,
        totalRecordPerPage: totalRecordPerPage,
        mainOperationType: "Any",
        tableView: "Horizontal",
        resultValues: [],
        facilities: [],
        facilitiesIdsArray: [],
        insurances: [],
        providers: [],
        paginate: true,
      });
      setTestAnalyteJson({
        startDate: moment(new Date(lastMonday)).format("yyyy-MM-DD"),
        endDate: moment(new Date(curr)).format("yyyy-MM-DD"),
        dateType: "Result",
      });
      GetSex();
      fetchStatusesInfoDataOnLoad();
      GetAllSaveSearchCriteria();
      // GetProviders();

      GetInsuranceCompanies();
      GetUserRolesPermissions();
    }
  }, []);
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  const [totalRecordPerPage, setTotalRecordPerPage] = useState(10);
  const [searchFilter, setSearchFilter] = useState({});
  var curr = new Date();
  var lastMonday = getPreviousMonday(curr);
  const [dateRange, setDateRange] = useState([lastMonday, curr]);
  const [startDate, endDate] = dateRange;
  const [showDateRange, setShowDateRange] = useState(true);
  const [tableView, setTableView] = useState("Horizontal");
  const [tableViewValue, setTableViewValue] = useState("Horizontal");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showValueField, setShowValueField] = useState([]);
  const [selectedFacilityArray, setSelectedFacilityArray] = useState([]);
  const [selectedFacilityCodesArray, setSelectedFacilityCodesArray] = useState(
    []
  );
  const [selectedInsuranceArray, setSelectedInsuranceArray] = useState([]);
  const [selectedProvidersArray, setSelectedProvidersArray] = useState([]);
  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  const uniqueTags = [];
  const [results, setResults] = useState([]);
  const [selectedresults, setSelectedResults] = useState([]);
  const [allSaveSearchCriteria, setAllSaveSearchCriteria] = useState([]);
  const [locationsDataJson, setLocationsDataJson] = useState(
    JSON.parse(localStorage.getItem("locations"))
  );
  async function onchangeHandler(e) {
    if (e.target.value != "") {
      setShowDateRange(true);
      if (e.target.value == "cWeek") {
        var lastMonday = getPreviousMonday(curr);

        setDateRange([lastMonday, curr]);
        handleSearchFilterChange(
          "startDate",
          moment(new Date(lastMonday)).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
      } else if (e.target.value == "today") {
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([curr, curr]);
      } else if (e.target.value == "yesterday") {
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr.setDate(curr.getDate() - 1))).format(
            "yyyy-MM-DD"
          )
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([curr, curr]);
      } else if (e.target.value == "cMonth") {
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr.getFullYear(), curr.getMonth(), 1)).format(
            "yyyy-MM-DD"
          )
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([new Date(curr.getFullYear(), curr.getMonth(), 1), curr]);
      } else if (e.target.value == "cMonth") {
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr.getFullYear(), curr.getMonth(), 1)).format(
            "yyyy-MM-DD"
          )
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([new Date(curr.getFullYear(), curr.getMonth(), 1), curr]);
      } else if (e.target.value == "cQuarter") {
        var quarter = getQuarter(curr);
        var month;
        if (quarter == 1) {
          month = 0;
        } else if (quarter == 2) {
          month = 3;
        } else if (quarter == 3) {
          month = 6;
        } else if (quarter == 4) {
          month = 9;
        }
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr.getFullYear(), month, "01")).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([new Date(curr.getFullYear(), month, "01"), curr]);
      } else if (e.target.value == "cYear") {
        var quarter = getQuarter(curr);
        var month = 0;
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr.getFullYear(), month, "01")).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([new Date(curr.getFullYear(), month, "01"), curr]);
      } else if (e.target.value == "lWeek") {
        var lastSunday = getPreviousSunday(curr);

        var lastMonday = getPreviousMonday(lastSunday);
        handleSearchFilterChange(
          "startDate",
          moment(new Date(lastMonday)).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(lastSunday)).format("yyyy-MM-DD")
        );
        setDateRange([lastMonday, lastSunday]);
      } else if (e.target.value == "lMonth") {
        var prevMonthLastDate = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          0
        );
        var prevMonthFirstDate = new Date(
          curr.getFullYear() - (curr.getMonth() > 0 ? 0 : 1),
          (curr.getMonth() - 1 + 12) % 12,
          1
        );
        handleSearchFilterChange(
          "startDate",
          moment(new Date(prevMonthFirstDate)).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(prevMonthLastDate)).format("yyyy-MM-DD")
        );
        setDateRange([prevMonthFirstDate, prevMonthLastDate]);
      } else if (e.target.value == "lQuarter") {
        // curr = new Date();
        var quarterWithYear = getLastQuarter(curr.getMonth(), new Date()).split(
          ","
        );

        var quarter = quarterWithYear[0];
        var month;
        var date = 31;
        if (quarter == 1) {
          month = 0;
        } else if (quarter == 2) {
          month = 3;
          date = 30;
        } else if (quarter == 3) {
          month = 6;
          date = 30;
        } else if (quarter == 4) {
          month = 9;
        }
        handleSearchFilterChange(
          "startDate",
          moment(new Date(quarterWithYear[1], month, "01")).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(quarterWithYear[1], month + 2, date)).format(
            "yyyy-MM-DD"
          )
        );
        setDateRange([
          new Date(quarterWithYear[1], month, "01"),
          new Date(quarterWithYear[1], month + 2, date),
        ]);
      } else if (e.target.value == "lYear") {
        var prevYearLastDate = new Date(curr.getFullYear() - 1, 11, 31);
        var prevYearFirstDate = new Date(curr.getFullYear() - 1, 0, 1);
        handleSearchFilterChange(
          "startDate",
          moment(new Date(prevYearFirstDate)).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(prevYearLastDate)).format("yyyy-MM-DD")
        );
        setDateRange([prevYearFirstDate, prevYearLastDate]);
      }

      // const response = await ApiGetCalls("GetResults");
      //setResults(response.results);
      else if (e.target.value == "dateRange") {
        setDateRange([]);
        setShowDateRange(false);
      }
    } else {
      //setResults([]);
    }
    setSelectedDate(e.target.value);
    handleSearchFilterChange("selectedDate", e.target.value);
  }
  const handleSearchFilterChange = (name, value) => {
    setSearchFilter((values) => ({ ...values, [name]: value }));
    if (name == "startDate" || name == "endDate") {
      handleTestAnalyteJsonFilter(name, value);
      setShowValueField([]);
    }
  };
  const GetAllSaveSearchCriteria = async () => {
    const response = await ApiGetCalls(
      "GetAllSaveSearchCriteria/" + localStorage.getItem("userId")
    );
    if (response.length > 0) {
      setAllSaveSearchCriteria(response);
    }
  };
  const [userPermissions, setUserPermissions] = useState([]);
  const GetUserRolesPermissions = async () => {
    const response = await ApiGetCalls(
      "GetUserRoleFreatures/" + localStorage.getItem("userId")
    );
    if (response.status == "Success") {
      setUserPermissions(response.data.filter((x) => x.featureId == 6));
    }
  };

  const [filter, setFilter] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [noResultFound, setNoResultFound] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [showText, setShowText] = useState("");
  const [oldValue, setOldValue] = useState("");
  const rowClickHandler = (selectedFacilityCodeObj) => {
    setSelectedResults(selectedFacilityCodeObj);
    selectedFacilityCodeObj.map((value, index) => {
      resultTestDataType.map((resultType, key) => {
        if (resultType.id == value.id) {
          const showValueFieldObj = {
            id: value.id.toString(),
            resultCode: value.valueArray[1],
            resultName: value.valueArray[2],
            operationType: "All",
            value: null,
            valueText: null,
            dataType: resultType.valueType,
            valueArray: [value.id, value.valueArray[1], value.valueArray[2]],
          };
          if (!showValueField.some((item) => value.id === item.id)) {
            showValueField.push(showValueFieldObj);
          } else {
            showValueField.map((item, key) => {
              if (item.id != value.id) {
                var arrayIndex = showValueField.indexOf(item);
                showValueField.splice(arrayIndex, 1);
              }
            });
          }
        }
      });
    });
    setShowValueField(showValueField);
    setShowResults(false);
    setFilter("");
  };
  const [showSavedSearchModel, setShowSavedSearchModel] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteSavedSearchModel, setDeleteSavedSearchModel] = useState(true);
  const [selectedDate, setSelectedDate] = useState("cWeek");
  const [savedSearchCriteriaId, setSavedSearchCriteriaId] = useState(0);
  const [dateType, setDateType] = useState("Result");
  const [savedSearchsDropdown, setSavedSearchsDropdown] = useState(0);
  const [savedSearchCriteriaUserId, setSavedSearchCriteriaUserId] = useState(0);
  const [commonOrderIds, setCommonOrderIds] = useState([]);
  const [
    saveSearchCriteriaPermissionCheck,
    setSaveSearchCriteriaPermissionCheck,
  ] = useState(true);
  async function saveSearchClickHandler() {
    if (savedSearchCriteriaId != 0) {
      allSaveSearchCriteria.map(async (val, ind) => {
        if (val.id == savedSearchCriteriaId) {
          setSavedSearchCriteriaUserId(val.userId);
          if (val.userId == localStorage.getItem("userId")) {
            setSaveSearchCriteriaPermissionCheck(true);
          } else {
            if (userPermissions[0].rolePermissions.updateFeature == "Y") {
              setSaveSearchCriteriaPermissionCheck(true);
            } else {
              setSaveSearchCriteriaPermissionCheck(false);
              setSearchName("");
            }
          }
        }
      });
    }
    setShowSavedSearchModel(true);
  }
  async function getSearchResult() {
    const response = await ApiPostCalls(
      JSON.stringify(searchFilter),

      "AdvanceResultSearch"
    );
    if (selectedFacilityArray.length == 0) {
      handleSearchFilterChange("facilities", []);
      handleSearchFilterChange("facilitiesIdsArray", []);
    }
    if (response != null && response.length > 0) {
      setTotalRecords(Math.ceil(response[0].totalRecords / totalRecordPerPage));
      setNoResultFound(false);
      const uniqueIdsSet = new Set();
      if (searchFilter.tableView == "Horizontal") {
        response.forEach((val) => {
          val.results.forEach((value) => {
            uniqueIdsSet.add(value.resultCommonOrderId);
          });
        });
        const uniqueIdsArray = [...uniqueIdsSet];
        setCommonOrderIds(uniqueIdsArray);
      } else {
        response.forEach((val) => {
          uniqueIdsSet.add(val.resultCommonOrderId);
        });
        const uniqueIdsArray = [...uniqueIdsSet];
        setCommonOrderIds(uniqueIdsArray);
      }
    } else {
      setNoResultFound(true);
    }
    if (searchFilter.tableView == "Horizontal") {
      setTableViewValue("Horizontal");
      setTableView("Horizontal");
    } else {
      setTableViewValue("Vertical");
      setTableView("Vertical");
    }
    if (response != null) {
      setSearchResult(response);
    } else {
      setSearchResult([]);
    }
    setLoader(false);
    handleClickScroll();
  }
  async function exportSearchResult() {
    if (selectedFacilityArray.length == 0) {
      searchFilter.facilities = allFacilities;
      searchFilter.facilitiesIdsArray = allFacilitiesIds;

      // handleSearchFilterChange("facilities", allFacilities);
      // handleSearchFilterChange(
      //   "facilitiesIdsArray",
      //   allFacilitiesIds
      // );
    }

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Response-Type": "blob",
      },

      body: JSON.stringify({ ...searchFilter, ["paginate"]: false }),
    };
    const response = await fetch(
      apiUrl + "AdvanceResultSearchExport",
      requestOptions
    )
      .then(async (response) => {
        if (response.status.toString() == "200") {
          return response.blob();
        } else {
          if (response.status.toString() == "401") {
            const accessToken = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            const refreshPayload = JSON.stringify({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });

            var refreshTokenResponse = await ApiPostCalls(
              refreshPayload,
              "refresh-token"
            );
            localStorage.setItem("token", refreshTokenResponse["accessToken"]);
            localStorage.setItem(
              "refreshToken",
              refreshTokenResponse["refreshToken"]
            );
            exportSearchResult();
          }
          return false;
        }
      })
      .then((blob) => {
        setLoader(false);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        var today = new Date();
        var fileNameWithDateString =
          today.getFullYear().toString() +
          (today.getMonth() + 1).toString().padStart(2, "0") +
          today.getDate().toString() +
          today.getHours().toString() +
          today.getMinutes().toString() +
          today.getSeconds().toString();
        a.download = "ResultsExport_" + fileNameWithDateString + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }
  async function downloadSearchResults() {
    if (selectedFacilityArray.length == 0) {
      searchFilter.facilities = allFacilities;
      searchFilter.facilitiesIdsArray = allFacilitiesIds;

      // handleSearchFilterChange("facilities", allFacilities);
      // handleSearchFilterChange(
      //   "facilitiesIdsArray",
      //   allFacilitiesIds
      // );
    }
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Response-Type": "blob",
      },
      body: JSON.stringify(searchFilter),
    };
    const response = await fetch(
      apiUrl + "AdvanceResultsDownload",
      requestOptions
    )
      .then(async (response) => {
        if (response.status.toString() == "200") {
          return response.blob();
        } else {
          if (response.status.toString() == "401") {
            const accessToken = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            const refreshPayload = JSON.stringify({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });

            var refreshTokenResponse = await ApiPostCalls(
              refreshPayload,
              "refresh-token"
            );
            localStorage.setItem("token", refreshTokenResponse["accessToken"]);
            localStorage.setItem(
              "refreshToken",
              refreshTokenResponse["refreshToken"]
            );
            downloadSearchResults();
          }
          return false;
        }
      })
      .then((blob) => {
        setLoader(false);
        if (blob.size > 0) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          var today = new Date();
          var fileNameWithDateString =
            today.getFullYear().toString() +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            today.getDate().toString() +
            today.getHours().toString() +
            today.getMinutes().toString() +
            today.getSeconds().toString();
          a.download = "Results_" + fileNameWithDateString + ".pdf";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        } else {
          snackBar("No result found", "info");
        }
      });
  }
  const resultTestData = [];
  const resultTestDataType = [];

  // for (let i = 0; i < results.length; i++) {
  //   if (!showValueField.some((item) => results[i].id.toString() === item.id)) {
  //     const resultTestDataObj = [
  //       results[i].id.toString(),
  //       results[i].resultCode.toString(),
  //       results[i].resultName,
  //     ];
  //     const resultTestDataTypeObj = {
  //       id: results[i].id.toString(),
  //       valueType: results[i].valueType,
  //     };
  //     resultTestData.push(resultTestDataObj);
  //     resultTestDataType.push(resultTestDataTypeObj);
  //   }
  // }

  for (let i = 0; i < results.length; i++) {
    if (
      !showValueField.some(
        (item) => results[i].resultCode.toString() === item.id
      )
    ) {
      const resultTestDataObj = [
        results[i].resultCode.toString(),
        results[i].resultCode.toString(),
        results[i].resultName,
      ];
      const resultTestDataTypeObj = {
        id: results[i].resultCode.toString(),
        valueType: results[i].valueType,
      };
      resultTestData.push(resultTestDataObj);
      resultTestDataType.push(resultTestDataTypeObj);
    }
  }

  function getPreviousMonday(date) {
    var day = date.getDay();
    var prevMonday = new Date();
    if (date.getDay() == 0) {
      prevMonday.setDate(date.getDate() - 6);
    } else {
      prevMonday.setDate(date.getDate() - (day - 1));
    }

    return prevMonday;
  }
  function getPreviousSunday(date) {
    var day = date.getDay();
    var prevSunday = new Date();
    if (date.getDay() == 0) {
      prevSunday.setDate(date.getDate() - 7);
    } else {
      prevSunday.setDate(date.getDate() - day);
    }

    return prevSunday;
  }
  function getQuarter(date = new Date()) {
    return Math.floor(date.getMonth() / 3 + 1);
  }

  function getLastQuarter(month, date) {
    date.setDate(1);
    date.setMonth(date.getMonth() - 3);
    //var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var quarter = Math.floor(date.getMonth() / 3 + 1);
    return quarter + "," + year;
  }

  useEffect(() => {
    handleSearchFilterChange("resultValues", showValueField);
  }, [showValueField]);

  const [pagenationState, setPagenationState] = useState(-1);
  const [deleteSearchCriteria, setDeleteSearchCriteria] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * 1) % 2;
    if (selectedFacilityArray.length == 0) {
      handleSearchFilterChange("facilities", allFacilities);
      handleSearchFilterChange("facilitiesIdsArray", allFacilitiesIds);
    }
    handleSearchFilterChange("pageNumber", event.selected + 1);
    setPagenationState(Math.random());
  };
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (pagenationState != -1) {
      setLoader(true);
      getSearchResult();
    }
  }, [pagenationState]);
  const onConfirmationYes = async () => {
    const response = await ApiGetCalls(
      "RemoveSaveSearchCriteria/" + deleteSearchCriteria
    );

    if (response.status == "Success") {
      GetAllSaveSearchCriteria();
      setModalShow(false);
      resetPage();
    }
  };
  const resetPage = () => {
    setSavedSearchsDropdown(0);
    setDeleteSavedSearchModel(true);
    setSexState("All");
    setSearchName("");
    setShowText("");
    setMinAge("");
    setMaxAge("");
    setZipCode("");
    setLastName("");
    setFirstName("");
    setMrn("");
    setOrderNumber("");
    setPhone("");
    setDob("");
    setSavedSearchCriteriaId(0);
    setOrderStatusState("All");
    setInsuranceFilter("");
    setSearchFilter({
      startDate: moment(getPreviousMonday(new Date())).format("yyyy-MM-DD"),
      endDate: moment(new Date()).format("yyyy-MM-DD"),
      dateType: "Result",
      pageNumber: 1,
      totalRecordPerPage: 10,
      mainOperationType: "Any",
      tableView: "Horizontal",
      resultValues: [],
      facilities: [],
      facilitiesIdsArray: [],
      insurances: [],
      providers: [],
      paginate: true,
    });
    setTestAnalyteJson({
      startDate: moment(getPreviousMonday(new Date())).format("yyyy-MM-DD"),
      endDate: moment(new Date()).format("yyyy-MM-DD"),
      dateType: "Result",
    });
    setShowValueField([]);
    setPagenationState(-1);
    setSelectedDate("cWeek");
    setDateRange([getPreviousMonday(new Date()), new Date()]);
    setDateType("Result");
    setDeleteSearchCriteria(0);
    setTableView("Horizontal");
    setOldValue("");
    setSearchResult([]);
    setProvidersData([]);
    setSelectedFacilityArray([]);
    setSelectedInsuranceArray([]);
    setSelectedProvidersArray([]);
    setTotalRecordPerPage(10);
  };
  // const [insurances, setInsurance] = useState([]);
  const [insuranceFilter, setInsuranceFilter] = useState("");
  const [showInsuranceResults, setShowInsuranceResults] = useState(false);
  const [insurancesData, setInsurancesData] = useState([]);
  // const [insurancesDataJson, setinsurancesDataJson] = useState(
  //   getTreeOptionsSelected(insurancesData, "id", [])
  // );

  const rowClickHandlerInsurances = (selectedInsuranceObj, id) => {
    setShowInsuranceResults(false);
    setInsuranceFilter(selectedInsuranceObj[1]);
  };

  // const [providers, setProviders] = useState([]);
  const [providerFilter, setProviderFilter] = useState("");
  const [showProviderResults, setShowProviderResults] = useState(false);
  const [providersData, setProvidersData] = useState([]);

  const rowClickHandlerProviders = (selectedInsuranceObj, id) => {
    setShowProviderResults(false);
    setProviderFilter(selectedInsuranceObj[1]);
  };
  const [sex, setSex] = useState([]);
  const [sexState, setSexState] = useState("All");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [mrn, setMrn] = useState("");
  const GetSex = async () => {
    const response = await ApiGetCalls("GetQuickLookupsByName/Sex");
    if (response.length > 0) {
      setSex(response);
    }
  };
  const [lookOrderInfo, setLookOrderInfo] = useState({
    reportStatuses: [],
  });
  async function fetchStatusesInfoDataOnLoad() {
    try {
      const [reportStatuses, orderStatuses] = await Promise.all([
        await ApiGetCalls("GetQuickLookupsByName/reportstatus"),
      ]);
      setLookOrderInfo({
        reportStatuses: reportStatuses,
      });
    } catch (error) {}
  }
  const GetMultiFacilityProviders = async (ids) => {
    // const response = await ApiPostCalls(
    //   "GetProvidersByFacilities/" + selectedLocation
    // );
    const response = await ApiPostCalls(
      JSON.stringify(ids),

      "GetProvidersByFacilities"
    );
    if (response.status == "Success") {
      const insurancesDataObjWithAll = {
        id: 0,
        label: "All",
        expanded: true,
        className: "non-selective allSelection",
        children: [],
      };
      for (let i = 0; i < response.data.length; i++) {
        const providersDataObj = {
          //providers[i].id.toString(),
          label: response.data[i].lastName + " " + response.data[i].firstName,
          id: response.data[i].npi,
          className: "",
          children: [],
        };
        insurancesDataObjWithAll.children.push(providersDataObj);
      }
      providersData.push(insurancesDataObjWithAll);
      //setSelectedProvidersArray([]);
      setProvidersData([
        ...getTreeOptionsSelected(providersData, "id", selectedProvidersArray),
      ]);
    }
  };
  const GetInsuranceCompanies = async () => {
    const response = await ApiGetCalls("GetInsuranceCompanies");
    // setInsurance(response);
    const insurancesDataObjWithAll = {
      id: 0,
      label: "All",
      expanded: true,
      className: "non-selective allSelection",
      children: [],
    };
    for (let i = 0; i < response.length; i++) {
      const insurancesDataObj = {
        id: response[i].id,
        label: response[i].name,
        className: "",
        children: [],
      };
      insurancesDataObjWithAll.children.push(insurancesDataObj);
    }
    insurancesData.push(insurancesDataObjWithAll);
    setInsurancesData([...getTreeOptionsSelected(insurancesData, "id", [])]);
  };
  const [testAnalyteJson, setTestAnalyteJson] = useState({});
  const [allFacilities, setAllFacilities] = useState([]);
  const [allFacilitiesIds, setAllFacilitiesIds] = useState([]);
  const handleTestAnalyteJsonFilter = (name, value) => {
    setTestAnalyteJson((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    if (selectedFacilityArray.length == 0) {
      var facilityIds = [];
      var nonSelectedfacilityCodes = [];
      var physicalFacilities = getAllPhysicalFacilitiesFromTree(
        JSON.parse(localStorage.getItem("locations")),
        []
      );
      physicalFacilities.map((val) => {
        facilityIds.push(val.id);
      });
      physicalFacilities.map((val) => {
        nonSelectedfacilityCodes.push(val.code);
      });
      setAllFacilities(nonSelectedfacilityCodes);
      setAllFacilitiesIds(facilityIds);
      //handleSearchFilterChange("facilities", nonSelectedfacilityCodes);
      handleTestAnalyteJsonFilter("facilities", nonSelectedfacilityCodes);
      //handleSearchFilterChange("facilitiesIdsArray", facilityIds);
      handleTestAnalyteJsonFilter("facilitiesIdsArray", facilityIds);
      handleSearchFilterChange("providers", []);
      handleTestAnalyteJsonFilter("providers", []);
      GetMultiFacilityProviders(facilityIds);
    }
    setLocationsDataJson(
      getTreeOptionsSelected(
        JSON.parse(localStorage.getItem("locations")),
        "id",
        selectedFacilityArray
      )
    );
  }, [selectedFacilityArray]);
  useEffect(() => {
    //setProvidersData([]);
    if (selectedFacilityArray.length != 0)
      GetMultiFacilityProviders(selectedFacilityArray);
  }, [selectedFacilityArray]);

  useEffect(() => {
    setInsurancesData([
      ...getTreeOptionsSelected(insurancesData, "id", selectedInsuranceArray),
    ]);
  }, [selectedInsuranceArray]);

  useEffect(() => {
    setProvidersData([
      ...getTreeOptionsSelected(providersData, "id", selectedProvidersArray),
    ]);
  }, [selectedProvidersArray]);
  // var facilitiesCodeArray;
  // if (selectedFacilityArray.length == 0) {
  //   facilitiesCodeArray = [];
  // }
  const handleSearchClick = () => {
    // if(allFacilities.length == )
    if (selectedFacilityArray.length == 0) {
      handleSearchFilterChange("facilities", allFacilities);
      handleSearchFilterChange("facilitiesIdsArray", allFacilitiesIds);
    }
    handleSearchFilterChange("pageNumber", 1);
    setCurrentPage(0);
    setCount(count + 1);
    setPagenationState(count + 1);
    // getSearchResult();
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };
  const [scrollToElement, setScrollToElement] = useState(false);
  // Create a reference to the element you want to scroll to
  const myRef = useRef(null);

  // Event handler for the button click
  const handleClickScroll = () => {
    // Set the state variable to true to scroll to the element
    setScrollToElement(true);
  };
  useEffect(() => {
    if (scrollToElement) {
      // Scroll to the element if it exists
      myRef.current?.scrollIntoView({ behavior: "smooth" });
      // Reset the state variable to false
      setScrollToElement(false);
    }
  }, [scrollToElement]);
  return (
    <div>
      {showLoader && <CustomLoader />}
      <CustomNavBar
        patientDetails={patientDetails}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        selectedOrderPatientId={props.selectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        selectedOrderPatientId={props.selectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="pageheight resultsPage withOutbar">
        <Container>
          <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
            Facility Reports
            <Button
              variant="outline"
              className="outline btn button ml-auto"
              onClick={() => resetPage()}
            >
              Reset
            </Button>
            <Form.Select
              value={savedSearchsDropdown}
              className="w-25 ml-2 form-control"
              onChange={async (e) => {
                if (e.target.value > 0) {
                  resetPage();
                  // setSelectedFacilityArray([]);
                  // setSelectedInsuranceArray([]);
                  // setSelectedProvidersArray([]);
                  setSavedSearchsDropdown(e.target.value);
                  allSaveSearchCriteria.map(async (val, ind) => {
                    if (val.id == e.target.value) {
                      const data = JSON.parse(val.searchCriteria);
                      if (data.insurances.length > 0) {
                        setSelectedInsuranceArray(data.insurances);
                      }

                      setSelectedDate(data.selectedDate);
                      setTotalRecordPerPage(data.totalRecordPerPage);
                      setDateRange([
                        new Date(data.startDate),
                        new Date(data.endDate),
                      ]);
                      setDateType(data.dateType);
                      setDeleteSearchCriteria(e.target.value);
                      setSearchName(val.searchName);
                      setOldValue(val.searchName);
                      setSavedSearchCriteriaId(val.id);
                      setSexState(data.sex == undefined ? "" : data.sex);
                      setMinAge(data.minAge == undefined ? "" : data.minAge);
                      setMaxAge(data.maxAge == undefined ? "" : data.maxAge);
                      setZipCode(data.zip == undefined ? "" : data.zip);
                      setLastName(
                        data.lastName == undefined ? "" : data.lastName
                      );
                      setFirstName(
                        data.firstName == undefined ? "" : data.firstName
                      );
                      setMrn(data.mrn == undefined ? "" : data.mrn);
                      setOrderNumber(
                        data.orderNumber == undefined ? "" : data.orderNumber
                      );
                      setPhone(data.phone == undefined ? "" : data.phone);
                      setDob(data.dob == undefined ? "" : data.dob);
                      setOrderStatusState(
                        data.orderStatus == undefined ? "" : data.orderStatus
                      );

                      if (val.userId == localStorage.getItem("userId")) {
                        setDeleteSavedSearchModel(false);
                        setShowText("");
                        if (data.facilities.length != 0) {
                          setSelectedFacilityArray(data.facilities);
                          setSelectedFacilityCodesArray(
                            data.facilitiesIdsArray
                          );
                        } else {
                          data.facilities = allFacilities;
                          data.facilitiesIdsArray = allFacilitiesIds;
                        }
                        if (data.providers.length > 0) {
                          setSelectedProvidersArray(data.providers);
                        }
                      } else {
                        const filteredFacilitiesArray = allFacilities.filter(
                          (value) => data.facilities.includes(value)
                        );
                        const filteredFacilitiesIdsArray =
                          allFacilitiesIds.filter((value) =>
                            data.facilitiesIdsArray.includes(value)
                          );
                        if (data.facilities.length != 0) {
                          setSelectedFacilityArray(filteredFacilitiesArray);
                          setSelectedFacilityCodesArray(
                            filteredFacilitiesIdsArray
                          );
                          data.facilities = filteredFacilitiesArray;
                          data.facilitiesIdsArray = filteredFacilitiesIdsArray;
                        } else {
                          data.facilities = allFacilities;
                          data.facilitiesIdsArray = allFacilitiesIds;
                        }
                        data.providers = [];
                        if (
                          userPermissions[0].rolePermissions.deleteFeature ==
                          "Y"
                        ) {
                          setDeleteSavedSearchModel(false);
                        } else {
                          setShowText(val.searchName);
                          setDeleteSavedSearchModel(true);
                        }
                      }
                      setShowValueField(data.resultValues);
                      setSearchFilter(data);
                      setTestAnalyteJson(data);
                      setPagenationState(ind + 2);
                    }
                  });
                } else {
                  resetPage();
                }
              }}
            >
              <option value={0}>
                Saved searchs (
                {allSaveSearchCriteria.length > 0
                  ? allSaveSearchCriteria[0].totalCount
                  : 0}
                )
              </option>
              {allSaveSearchCriteria.length > 0 &&
                allSaveSearchCriteria.map((val, ind) => (
                  <option value={val.id}>{val.searchName}</option>
                ))}
            </Form.Select>
            <BsTrash
              className="mb-2 ml-2"
              size={20}
              disabled={deleteSavedSearchModel}
              color={deleteSavedSearchModel ? "#6c757d" : "black"}
              onClick={async () => {
                if (!deleteSavedSearchModel) {
                  setModalShow(true);
                }
              }}
            />
            <Button
              variant="primary"
              className="primary btn button ml-2"
              onClick={() => {
                saveSearchClickHandler();
              }}
            >
              Save Search
            </Button>
          </h3>
          <div className="whitepartition">
            <Row>
              <Col md={{ span: 6 }} lg={{ span: 3 }} className="mt-1">
                <h5 className="label-style">Date</h5>
                <Form.Select
                  className="form-control"
                  value={selectedDate}
                  onChange={(e) => onchangeHandler(e)}
                  onKeyDown={handleKeypress}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="cWeek">This Week</option>
                  <option value="cMonth">This Month</option>
                  <option value="cQuarter">This Quarter</option>
                  <option value="cYear">This Year</option>
                  <option value="lWeek">Last Week</option>
                  <option value="lMonth">Last Month</option>
                  <option value="lQuarter">Last Quarter</option>
                  <option value="lYear">Last Year</option>
                  <option value="dateRange">Custom</option>
                </Form.Select>
              </Col>
              <Col md={{ span: 6 }} lg={{ span: 3 }} className="mt-1">
                <h5 className="label-style">Date Range</h5>
                <DatePicker
                  preventOpenOnFocus={true}
                  className="form-control"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM-dd-yyyy"
                  onChange={(update) => {
                    handleSearchFilterChange(
                      "startDate",
                      moment(new Date(update[0])).format("yyyy-MM-DD")
                    );
                    if (update[1] != null) {
                      handleSearchFilterChange(
                        "endDate",
                        moment(new Date(update[1])).format("yyyy-MM-DD")
                      );
                    }
                    setDateRange(update);
                  }}
                  disabled={showDateRange}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                />
              </Col>
              <Col md={{ span: 6 }} lg={{ span: 3 }} className="mt-1">
                <h5 className="label-style">Date Type</h5>
                <Form.Select
                  className="form-control"
                  value={dateType}
                  onChange={(e) => {
                    handleSearchFilterChange("dateType", e.target.value);
                    handleTestAnalyteJsonFilter("dateType", e.target.value);
                    setShowValueField([]);
                    setDateType(e.target.value);
                  }}
                  onKeyDown={handleKeypress}
                >
                  <option value="Result">Result Date</option>
                  <option value="Collection">Collection Date</option>
                  {/*
                  remove RequestedDateTime filed from Orchard 
                  <option value="Order">Order Date</option> 
                  */}
                </Form.Select>
              </Col>
              <Col md={{ span: 6 }} lg={{ span: 3 }} className="mt-1">
                <h5 className="label-style">Display In</h5>
                <Form.Select
                  className="form-control"
                  value={tableView}
                  onChange={(e) => {
                    handleSearchFilterChange("tableView", e.target.value);
                    setTableView(e.target.value);
                    handleSearchFilterChange("pageNumber", 1);
                    // setPagenationState(e.target.value + 30);
                  }}
                  onKeyDown={handleKeypress}
                >
                  <option value={"Horizontal"}>Horizontal</option>
                  <option value={"Vertical"}>Vertical</option>
                </Form.Select>
              </Col>
              <Col className="d-flex flex-row align-items-end align-content-end"></Col>
            </Row>
            <Container className="resultAdvancesearch">
              <Row className="mt-3">
                <Col md={{ span: 3 }}>
                  <h5 className="label-style">Last Name</h5>
                  <input
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      handleSearchFilterChange(
                        "lastName",
                        e.target.value != "" ? e.target.value : null
                      );
                      handleTestAnalyteJsonFilter(
                        "lastName",
                        e.target.value != "" ? e.target.value : null
                      );
                      setShowValueField([]);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 3 }}>
                  <h5 className="label-style">First Name</h5>
                  <input
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      handleSearchFilterChange(
                        "firstName",
                        e.target.value != "" ? e.target.value : null
                      );
                      handleTestAnalyteJsonFilter(
                        "firstName",
                        e.target.value != "" ? e.target.value : null
                      );
                      setShowValueField([]);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 3 }}>
                  <h5 className="label-style">Requisition #</h5>
                  <input
                    value={orderNumber}
                    onChange={(e) => {
                      setOrderNumber(e.target.value);
                      handleSearchFilterChange(
                        "orderNumber",
                        e.target.value != "" ? e.target.value : null
                      );
                      handleTestAnalyteJsonFilter(
                        "orderNumber",
                        e.target.value != "" ? e.target.value : null
                      );
                      setShowValueField([]);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 3 }}>
                  <h5 className="label-style">Date of Birth</h5>
                  <DatePicker
                    preventOpenOnFocus={true}
                    name="advancedSearchDob"
                    ariaLabelledBy="Date of birth"
                    className="input-style form-control"
                    selected={dob != "" ? new Date(dob) : dob}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    onChange={(date) => {
                      if (date != null) {
                        handleSearchFilterChange(
                          "dob",
                          moment(date).format("yyyy-MM-DDT00:00:00")
                        );
                        handleTestAnalyteJsonFilter(
                          "dob",
                          moment(date).format("yyyy-MM-DDT00:00:00")
                        );
                        setDob(moment(date).format("yyyy-MM-DDT00:00:00"));
                      } else {
                        handleSearchFilterChange("dob", date);
                        handleTestAnalyteJsonFilter("dob", date);
                        setDob("");
                      }
                    }}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode="select"
                    autoComplete="off"
                    customInput={
                      <MaskedInput
                        mask={[
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        placeholder={"MM/dd/yyyy"}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onKeyDown={handleKeypress}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{ span: 3 }}>
                  <h5 className="label-style">Phone</h5>
                  <input
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      handleSearchFilterChange(
                        "phone",
                        e.target.value != "" ? e.target.value : null
                      );
                      handleTestAnalyteJsonFilter(
                        "phone",
                        e.target.value != "" ? e.target.value : null
                      );
                      setShowValueField([]);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 3 }}>
                  <h5 className="label-style">MRN</h5>
                  <input
                    value={mrn}
                    onChange={(e) => {
                      setMrn(e.target.value);
                      handleSearchFilterChange(
                        "mrn",
                        e.target.value != "" ? e.target.value : null
                      );
                      handleTestAnalyteJsonFilter(
                        "mrn",
                        e.target.value != "" ? e.target.value : null
                      );
                      setShowValueField([]);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 3 }}>
                  <h5 className="label-style">Gender</h5>

                  <Form.Select
                    className="form-control"
                    value={sexState}
                    onChange={(e) => {
                      setSexState(e.target.value);
                      handleSearchFilterChange(
                        "sex",
                        e.target.value != "All" ? e.target.value : null
                      );
                      handleTestAnalyteJsonFilter(
                        "sex",
                        e.target.value != "All" ? e.target.value : null
                      );
                      setShowValueField([]);
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value="All">All</option>
                    {sex.map((value, Index) => (
                      <option key={Index} value={value.id}>
                        {value.options}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={{ span: 3 }}>
                  <h5 className="label-style">Order Status</h5>

                  <Form.Select
                    className="form-control"
                    value={orderStatusState}
                    onChange={(e) => {
                      setOrderStatusState(e.target.value);
                      handleSearchFilterChange(
                        "orderStatus",
                        e.target.value != "All" ? e.target.value : null
                      );
                      handleTestAnalyteJsonFilter(
                        "orderStatus",
                        e.target.value != "All" ? e.target.value : null
                      );
                      setShowValueField([]);
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value="All">All</option>
                    {lookOrderInfo?.reportStatuses.map((val, ind) => {
                      return (
                        <option value={val.optionCode}>{val.options}</option>
                      );
                    })}
                    {/* <option value="IP">In Process</option>
                    <option value="CM">Final</option> */}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{ span: 2 }} lg={{ span: 1 }}>
                  <h5 className="label-style">Min Age</h5>
                  <input
                    value={minAge}
                    onChange={(e) => {
                      setMinAge(e.target.value);
                      handleSearchFilterChange(
                        "minAge",
                        e.target.value != "" ? e.target.value : null
                      );
                      handleTestAnalyteJsonFilter(
                        "minAge",
                        e.target.value != "" ? e.target.value : null
                      );
                      setShowValueField([]);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 2 }} lg={{ span: 1 }}>
                  <h5 className="label-style">Max Age</h5>
                  <input
                    value={maxAge}
                    onChange={(e) => {
                      setMaxAge(e.target.value);
                      handleSearchFilterChange(
                        "maxAge",
                        e.target.value != "" ? e.target.value : null
                      );
                      handleTestAnalyteJsonFilter(
                        "maxAge",
                        e.target.value != "" ? e.target.value : null
                      );
                      setShowValueField([]);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 2 }}>
                  <h5 className="label-style">Zip Code</h5>
                  <input
                    value={zipCode}
                    onChange={(e) => {
                      setZipCode(e.target.value);
                      handleSearchFilterChange(
                        "zip",
                        e.target.value != "" ? e.target.value : null
                      );
                      handleTestAnalyteJsonFilter(
                        "zip",
                        e.target.value != "" ? e.target.value : null
                      );
                      setShowValueField([]);
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{ span: 6 }} className="resultFacilityTree">
                  <h5 className="label-style">Facility</h5>
                  <DropdownTreeSelect
                    mode="multiSelect"
                    keepTreeOnSearch={true}
                    keepChildrenOnSearch={true}
                    keepOpenOnSelect={false}
                    data={locationsDataJson}
                    showPartiallySelected={true}
                    onChange={(e) => {
                      setSelectedProvidersArray([]);
                      setProvidersData([]);
                      setProvidersData([]);
                      if (e.className != "non-selective") {
                        var test = selectedFacilityArray;
                        var idsArray = selectedFacilityCodesArray;
                        if (selectedFacilityArray.length == 0) {
                        }

                        if (test.indexOf(e.id) == -1) {
                          test.push(e.id);
                          handleSearchFilterChange("facilitiesIdsArray", test);
                          handleTestAnalyteJsonFilter(
                            "facilitiesIdsArray",
                            test
                          );
                          setSelectedFacilityArray([...test]);
                        } else {
                          test.splice(test.indexOf(e.id), 1);
                          handleSearchFilterChange("facilitiesIdsArray", test);
                          handleTestAnalyteJsonFilter(
                            "facilitiesIdsArray",
                            test
                          );

                          setSelectedFacilityArray([...test]);
                        }
                        setShowValueField([]);

                        if (idsArray.indexOf(e.code) == -1) {
                          idsArray.push(e.code);
                          // handleSearchFilterChange("facilities", test);
                          //setSelectedFacilityCodesArray([...idsArray]);
                        } else {
                          idsArray.splice(idsArray.indexOf(e.code), 1);
                        }
                        setSelectedFacilityCodesArray([...idsArray]);
                        handleSearchFilterChange("facilities", idsArray);
                        handleTestAnalyteJsonFilter("facilities", idsArray);
                      } else {
                        const found = [];
                        found.push(
                          getChildObjStructure(
                            JSON.parse(localStorage.getItem("locations")),
                            e.id
                          )
                        );
                        var physicalFacilities =
                          getAllPhysicalFacilitiesFromTree(found, []);
                        physicalFacilities.map((val) => {
                          var test = selectedFacilityArray;
                          var idsArray = selectedFacilityCodesArray;
                          if (test.indexOf(val.id) == -1) {
                            test.push(val.id);
                            handleSearchFilterChange(
                              "facilitiesIdsArray",
                              test
                            );
                            handleTestAnalyteJsonFilter(
                              "facilitiesIdsArray",
                              test
                            );

                            setSelectedFacilityArray([...test]);
                          } else if (e.checked == false) {
                            test.splice(test.indexOf(val.id), 1);
                            handleSearchFilterChange(
                              "facilitiesIdsArray",
                              test
                            );
                            handleTestAnalyteJsonFilter(
                              "facilitiesIdsArray",
                              test
                            );
                            setSelectedFacilityArray([...test]);
                          }
                          setShowValueField([]);

                          if (idsArray.indexOf(val.code) == -1) {
                            idsArray.push(val.code);
                            // handleSearchFilterChange("facilities", test);
                            //setSelectedFacilityCodesArray([...idsArray]);
                          } else if (e.checked == false) {
                            idsArray.splice(idsArray.indexOf(val.code), 1);
                          }
                          setSelectedFacilityCodesArray([...idsArray]);
                          handleSearchFilterChange("facilities", idsArray);
                          handleTestAnalyteJsonFilter("facilities", idsArray);
                        });
                      }
                    }}
                    onFocus={() => {
                      setTimeout(function () {
                        var searchButton = document.getElementsByClassName(
                          "facilityTreeOkButton"
                        );
                        if (searchButton.length == 0) {
                          var scrollDiv = document.getElementsByClassName(
                            "infinite-scroll-component"
                          );
                          var div = document.createElement("div");
                          div.className = "customSearchButton";
                          div.id = "customSearchButtonId";
                          var button = document.createElement("button");
                          button.className =
                            "primary btn button SearchBtnCustom facilityTreeOkButton";
                          button.innerHTML = "OK";
                          button.addEventListener("click", (event) => {
                            var facilityDiv =
                              document.getElementsByClassName("facilityTree");
                            var toggleButton =
                              facilityDiv[0].getElementsByClassName(
                                "dropdown-trigger"
                              );
                            toggleButton[0].click();
                            // divToMention.classList.remove("form-control");
                          });

                          div.appendChild(button);
                          scrollDiv[0].parentElement.appendChild(div);
                        }
                      }, 100);
                    }}
                    className="bootstrap-demo facilityTree"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 3 }} className="resultInsuranceTree">
                  <h5 className="label-style">Insurance</h5>
                  <DropdownTreeSelect
                    mode="multiSelect"
                    keepTreeOnSearch={true}
                    keepChildrenOnSearch={true}
                    keepOpenOnSelect={true}
                    // onAction={() => {
                    // }}
                    onChange={(e) => {
                      if (e.id != 0) {
                        if (selectedInsuranceArray.indexOf(e.id) == -1) {
                          selectedInsuranceArray.push(e.id);
                        } else {
                          selectedInsuranceArray.splice(
                            selectedInsuranceArray.indexOf(e.id),
                            1
                          );
                          if (e.hide == undefined) {
                            handleSearchFilterChange(
                              "insurances",
                              selectedInsuranceArray
                            );
                            handleTestAnalyteJsonFilter(
                              "insurances",
                              selectedInsuranceArray
                            );
                            setShowValueField([]);
                            setSelectedInsuranceArray([
                              ...selectedInsuranceArray,
                            ]);
                            setInsuranceSearchQuery("");
                          }
                        }
                      } else {
                        const found = [];
                        found.push(getChildObjStructure(insurancesData, e.id));
                        var physicalFacilities = getAllPhysicalFromTree(
                          found,
                          [],
                          insuranceSearchQuery
                        );
                        physicalFacilities.map((val) => {
                          if (selectedInsuranceArray.indexOf(val.id) == -1) {
                            selectedInsuranceArray.push(val.id);
                          } else if (e.checked == false) {
                            selectedInsuranceArray.splice(
                              selectedInsuranceArray.indexOf(val.id),
                              1
                            );
                          }
                        });
                      }
                    }}
                    onFocus={() => {
                      setTimeout(function () {
                        var searchButton = document.getElementsByClassName(
                          "insuranceTreeOkButton"
                        );
                        if (searchButton.length == 0) {
                          var scrollDiv = document.getElementsByClassName(
                            "infinite-scroll-component"
                          );
                          var div = document.createElement("div");
                          div.className = "customSearchButton";
                          div.id = "customSearchButtonId";
                          var button = document.createElement("button");
                          button.className =
                            "primary btn button SearchBtnCustom insuranceTreeOkButton";
                          button.innerHTML = "OK";
                          button.addEventListener("click", (event) => {
                            var facilityDiv =
                              document.getElementsByClassName("insuranceTree");
                            var toggleButton =
                              facilityDiv[0].getElementsByClassName(
                                "dropdown-trigger"
                              );
                            toggleButton[0].click();
                            // divToMention.classList.remove("form-control");
                          });
                          var myimg =
                            document.getElementsByClassName("searchModeOn");
                          div.appendChild(button);
                          scrollDiv[0].parentElement.appendChild(div);
                        }
                      }, 100);
                    }}
                    onBlur={() => {
                      handleSearchFilterChange(
                        "insurances",
                        selectedInsuranceArray
                      );
                      handleTestAnalyteJsonFilter(
                        "insurances",
                        selectedInsuranceArray
                      );
                      setShowValueField([]);
                      setSelectedInsuranceArray([...selectedInsuranceArray]);
                      setInsuranceSearchQuery("");
                    }}
                    data={insurancesData}
                    className="bootstrap-demo insuranceTree"
                    onKeyDown={handleKeypress}
                  />
                  {/* <InputWithLoader
                    filter={insuranceFilter}
                    placeholder="Start typing..."
                    apiEndPoint="GetInsuranceCompaniesByName/"
                    setData={setInsurance}
                    setShowResults={setShowInsuranceResults}
                    setFilter={setInsuranceFilter}
                    name="insuranceCompanies"
                    aria_label="Insurance company name"
                    className="input-style form-control"
                    data_type="text"
                  />
                  {showInsuranceResults ? (
                    <SelectBody
                      rowClickHandler={rowClickHandlerInsurances}
                      filter={insuranceFilter}
                      column={["Name"]}
                      dataArray={insurancesData}
                      setShowResults={setShowInsuranceResults}
                    />
                  ) : null} */}
                </Col>

                <Col md={{ span: 3 }} className="resultProviderTree">
                  <h5 className="label-style">Provider</h5>
                  <DropdownTreeSelect
                    mode="multiSelect"
                    keepTreeOnSearch={true}
                    keepChildrenOnSearch={true}
                    keepOpenOnSelect={false}
                    onFocus={() => {
                      setTimeout(function () {
                        var searchButton = document.getElementsByClassName(
                          "providerTreeOkButton"
                        );
                        if (searchButton.length == 0) {
                          var scrollDiv = document.getElementsByClassName(
                            "infinite-scroll-component"
                          );
                          var div = document.createElement("div");
                          div.className = "customSearchButton";
                          div.id = "customSearchButtonId";
                          var button = document.createElement("button");
                          button.className =
                            "primary btn button SearchBtnCustom providerTreeOkButton";
                          button.innerHTML = "OK";
                          button.addEventListener("click", (event) => {
                            var facilityDiv =
                              document.getElementsByClassName("providerTree");
                            var toggleButton =
                              facilityDiv[0].getElementsByClassName(
                                "dropdown-trigger"
                              );
                            toggleButton[0].click();
                            // divToMention.classList.remove("form-control");
                          });

                          div.appendChild(button);
                          scrollDiv[0].parentElement.appendChild(div);
                        }
                      }, 100);
                    }}
                    data={providersData}
                    onChange={(e) => {
                      if (e.id != 0) {
                        if (selectedProvidersArray.indexOf(e.id) == -1) {
                          selectedProvidersArray.push(e.id);
                        } else {
                          selectedProvidersArray.splice(
                            selectedProvidersArray.indexOf(e.id),
                            1
                          );
                        }
                        if (e.hide == undefined) {
                          handleSearchFilterChange(
                            "providers",
                            selectedProvidersArray
                          );
                          handleTestAnalyteJsonFilter(
                            "providers",
                            selectedProvidersArray
                          );
                          setShowValueField([]);
                          setSelectedProvidersArray([
                            ...selectedProvidersArray,
                          ]);
                          setProviderSearchQuery("");
                        }
                      } else {
                        const found = [];
                        found.push(getChildObjStructure(providersData, e.id));
                        var physicalFacilities = getAllPhysicalFromTree(
                          found,
                          [],
                          providerSearchQuery
                        );
                        physicalFacilities.map((val) => {
                          if (selectedProvidersArray.indexOf(val.id) == -1) {
                            selectedProvidersArray.push(val.id);
                          } else if (e.checked == false) {
                            selectedProvidersArray.splice(
                              selectedProvidersArray.indexOf(val.id),
                              1
                            );
                          }
                        });
                      }
                    }}
                    onBlur={() => {
                      handleSearchFilterChange(
                        "providers",
                        selectedProvidersArray
                      );
                      handleTestAnalyteJsonFilter(
                        "providers",
                        selectedProvidersArray
                      );
                      setShowValueField([]);
                      setSelectedProvidersArray([...selectedProvidersArray]);
                      setProviderSearchQuery("");
                    }}
                    className="bootstrap-demo providerTree"
                    onKeyDown={handleKeypress}
                  />
                  {/* <InputWithLoader
                    filter={providerFilter}
                    placeholder="Start typing..."
                    apiEndPoint=""
                    setData={setProviders}
                    setShowResults={setShowProviderResults}
                    setFilter={setProviderFilter}
                    name="insuranceCompanies"
                    aria_label="Insurance company name"
                    className="input-style form-control"
                    data_type="text"
                  />
                  {showProviderResults ? (
                    <SelectBody
                      rowClickHandler={rowClickHandlerProviders}
                      filter={providerFilter}
                      column={["Name"]}
                      dataArray={providersData}
                      setShowResults={setShowProviderResults}
                    />
                  ) : null} */}
                </Col>
              </Row>
            </Container>
            <hr></hr>

            <Tabs id="serachtabs" defaultActiveKey="analyte" className="mb-0">
              <Tab eventKey="analyte" title="Analyte">
                <Row>
                  <Col>
                    <InputWithLoader
                      id="resultTestData"
                      filter={filter}
                      placeholder="Start typing..."
                      apiEndPoint="GetSearchedResults"
                      setData={setResults}
                      setShowResults={setShowResults}
                      setFilter={setFilter}
                      className="form-control"
                      method={"post"}
                      postApiJson={testAnalyteJson}
                    />
                    {showResults ? (
                      <SelectBody
                        rowClickHandler={rowClickHandler}
                        filter={filter}
                        column={["TestCode", "Tests"]}
                        dataArray={resultTestData}
                        multiSelect={true}
                        selectedValues={showValueField}
                      />
                    ) : null}
                  </Col>
                  <Col md={{ span: 2 }}>
                    <Form.Select
                      className="form-control"
                      onChange={(e) => {
                        handleSearchFilterChange(
                          "mainOperationType",
                          e.target.value
                        );
                      }}
                    >
                      <option value="Any">Any</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </Col>
                  <Col md={{ span: 3 }} className="helptext">
                    of the following conditions must be met
                  </Col>
                </Row>
                <hr></hr>
                {showValueField.length > 0 &&
                  showValueField.map((value, index) => (
                    <Row key={"testsId_" + index} className="dropdownrow">
                      <Col key={"testsIdCol_" + index}>
                        {value.resultCode + ": " + value.resultName}
                      </Col>
                      <Col key={"testsIdCol2_" + index} md={{ span: 3 }}>
                        {
                          //resultTestDataType.map((resultType, key) =>
                          //resultType.id == value.id ? (
                          value.dataType == "ST" ? (
                            <Form.Select
                              className="form-control"
                              value={
                                value.ResultFlag == "Abnormal"
                                  ? value.ResultFlag
                                  : value.operationType
                              }
                              onChange={(e) => {
                                if (e.target.value != "Abnormal") {
                                  setShowValueField(
                                    showValueField.map((item) => {
                                      return item.id === value.id
                                        ? {
                                            ...item,
                                            operationType: e.target.value,
                                            dataType: "ST",
                                            ResultFlag: "",
                                          }
                                        : item;
                                    })
                                  );
                                } else {
                                  setShowValueField(
                                    showValueField.map((item) => {
                                      return item.id === value.id
                                        ? {
                                            ...item,
                                            ResultFlag: "Abnormal",
                                          }
                                        : item;
                                    })
                                  );
                                }
                              }}
                              onKeyDown={handleKeypress}
                            >
                              <option value={"All"}>All</option>
                              <option value={"Equal"}>Equals</option>
                              <option value={"Like"}>Contains</option>
                              <option value={"StartsWith"}>Begins with</option>
                              <option value={"EndsWith"}>Ends with</option>
                              <option value={"Abnormal"}>Abnormal</option>
                            </Form.Select>
                          ) : (
                            <Form.Select
                              className="form-control"
                              value={
                                value.ResultFlag == "Abnormal"
                                  ? value.ResultFlag
                                  : value.operationType
                              }
                              onChange={(e) => {
                                if (e.target.value != "Abnormal") {
                                  setShowValueField(
                                    showValueField.map((item) => {
                                      return item.id === value.id
                                        ? {
                                            ...item,
                                            operationType: e.target.value,
                                            dataType: "NM",
                                            ResultFlag: "",
                                          }
                                        : item;
                                    })
                                  );
                                } else {
                                  setShowValueField(
                                    showValueField.map((item) => {
                                      return item.id === value.id
                                        ? {
                                            ...item,
                                            ResultFlag: "Abnormal",
                                          }
                                        : item;
                                    })
                                  );
                                }
                              }}
                              onKeyDown={handleKeypress}
                            >
                              <option value={"All"}>All</option>
                              <option value={"Equal"}>Equals</option>
                              <option value={"Less"}>Less than</option>
                              <option value={"Greater"}>Greater than</option>
                              <option value={"LessandEqual"}>
                                Less than or equals
                              </option>
                              <option value={"GreaterandEqual"}>
                                Greater than or equals
                              </option>
                              <option value={"Abnormal"}>Abnormal</option>
                            </Form.Select>
                          )
                          // )
                          // )
                        }
                      </Col>
                      <Col md={{ span: 3 }}>
                        {showValueField.length > 0 &&
                          showValueField.map((type, key) =>
                            type.id == value.id ? (
                              type.operationType != "All" &&
                              type.ResultFlag != "Abnormal" ? (
                                <input
                                  value={
                                    type.dataType == "ST"
                                      ? type.valueText
                                      : type.value
                                  }
                                  className="input-style form-control"
                                  onChange={(e) => {
                                    if (type.dataType == "ST") {
                                      setShowValueField(
                                        showValueField.map((item) => {
                                          return item.id === value.id
                                            ? {
                                                ...item,
                                                valueText: e.target.value,
                                              }
                                            : item;
                                        })
                                      );
                                    } else {
                                      setShowValueField(
                                        showValueField.map((item) => {
                                          return item.id === value.id
                                            ? {
                                                ...item,
                                                value: e.target.value,
                                              }
                                            : item;
                                        })
                                      );
                                    }
                                  }}
                                  onKeyDown={handleKeypress}
                                />
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )
                          )}
                      </Col>
                      <Col
                        key={"testsIdCol3_" + index}
                        md={{ span: 1 }}
                        className="d-flex flex-row align-items-end align-content-end "
                      >
                        <BsTrash
                          className="mb-2 ml-2"
                          size={20}
                          onClick={() => {
                            setSelectedResults(
                              selectedresults.filter(
                                (val) => value.id != val.id
                              )
                            );
                            setShowValueField(
                              showValueField.filter((val) => value.id != val.id)
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                <div ref={myRef}></div>
              </Tab>
              {/* <Tab eventKey="keywords" title="Keywords">
                <FaTrash className="mb-2 ml-2" size={20} />
              </Tab>
              <Tab eventKey="recentReports" title="Recent Reports">
                <FaTrash className="mb-2 ml-2" size={20} />
              </Tab> */}
            </Tabs>

            <Row>
              <Col className="d-flex flex-row align-items-end align-content-end ">
                {searchResult.length > 0 && (
                  <>
                    <Button
                      variant="primary"
                      className="primary btn button  mb-3"
                      onClick={() => {
                        setLoader(true);
                        exportSearchResult();
                      }}
                    >
                      Excel
                    </Button>
                    <Button
                      variant="primary"
                      className="primary btn button  mb-3 ml-3"
                      onClick={() => {
                        setLoader(true);
                        downloadSearchResults();
                      }}
                    >
                      Download Results
                    </Button>
                  </>
                )}
                <Button
                  variant="primary"
                  className="primary btn button ml-auto mb-3"
                  onClick={handleSearchClick}
                >
                  Search
                </Button>
              </Col>
            </Row>
            {noResultFound && (
              <Table
                className="mt-4 searchResultsTable"
                responsive
                bordered
                hover
              >
                <thead>
                  <tr className="DarkHeading">
                    <th>Name</th>
                    <th>Requisition #</th>
                    <th>MRN </th>
                    <th>Date Of Birth</th>
                    <th>Age</th>
                    <th>Phone</th>
                    <th>Gender</th>
                    <th>Zip</th>
                    <th>Facility Code</th>
                    <th>Facility Name</th>
                    <th>Provider</th>
                    <th>Order Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      There are no records matching your criteria
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            {searchResult.length > 0 && (
              <>
                <Table
                  className="mt-4 searchResultsTable"
                  responsive
                  bordered
                  hover
                >
                  <thead>
                    <tr className="DarkHeading">
                      <th>Name</th>
                      <th>Requisition #</th>
                      <th>MRN </th>
                      <th>Date Of Birth</th>
                      <th>Age</th>
                      <th>Phone</th>
                      <th>Gender</th>
                      <th>Zip</th>
                      <th>Facility Code</th>
                      <th>Facility Name</th>
                      <th>Provider</th>
                      <th>Order Status</th>
                      <th className="text-center">Actions</th>
                      {tableViewValue == "Horizontal" ? (
                        (searchResult.map((value) => {
                          value.results.map((val, index) => {
                            if (uniqueTags.indexOf(val.resultName) === -1) {
                              uniqueTags.push(val.resultName);
                              uniqueTags.sort();
                            }
                          });
                        }),
                        uniqueTags.map((value, index) => <th>{value} </th>))
                      ) : (
                        <>
                          <th>Test</th>
                          <th>Value</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {tableViewValue == "Horizontal"
                      ? searchResult.map((value, index) => {
                          var valueInsertedOn = -1;
                          return (
                            <tr>
                              <td>{value.results[0].patientName}</td>
                              <td>{value.results[0].orderNumber}</td>
                              <td>{value.results[0].mrn}</td>
                              <td>
                                {value.results[0].patientDOB == null
                                  ? "-"
                                  : dateFormat(
                                      value.results[0].patientDOB,
                                      "mm-dd-yyyy"
                                    )}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {value.results[0].patientDOB == null
                                  ? "-"
                                  : (() => {
                                      const today = new Date();
                                      const birthDate = new Date(
                                        value.results[0].patientDOB
                                      );

                                      let calculatedAge =
                                        today.getFullYear() -
                                        birthDate.getFullYear();

                                      // Go back to the year in which the person was born in case of a leap year
                                      if (
                                        birthDate >
                                        new Date(
                                          today.setFullYear(
                                            today.getFullYear() - calculatedAge
                                          )
                                        )
                                      ) {
                                        calculatedAge--;
                                      }

                                      return calculatedAge + " Yrs"; // This value will be rendered in the <td>
                                    })()}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {value.results[0].patientPhone}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {LookupText(sex, value.results[0].sex)}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {value.results[0].zipCode}
                              </td>
                              <td>{value.results[0].facilityCode}</td>
                              <td>{value.results[0].facilityName}</td>
                              <td>
                                {value.results[0].orderingProviderName == ", "
                                  ? ""
                                  : value.results[0].orderingProviderName}
                              </td>
                              <td>
                                {value.results[0].orderStatus == "IP"
                                  ? "In Process"
                                  : value.results[0].orderStatus == "CM"
                                  ? "Final"
                                  : value.results[0].orderStatus}
                              </td>
                              <td>
                                {value.results[0].embeddedPdf != null && (
                                  <BsPrinter
                                    title="Print"
                                    className="mr-1 cursorPointer"
                                    size={20}
                                    onClick={() => {
                                      setLoader(true);
                                      downloadFile(
                                        "downloadFile/" +
                                          value.results[0].resultCommonOrderId
                                      ).then((val) => setLoader(false));
                                    }}
                                  />
                                )}
                              </td>
                              {/* {value.results
                                .sort((a, b) =>
                                  a.resultName > b.resultName ? 1 : -1
                                )
                                .map((resultVal, index) => {
                                  var indexOfValue = uniqueTags.indexOf(
                                    resultVal.resultName
                                  );
                                  var printed = true;
                                  return uniqueTags.map((header, index) => {
                                    if (indexOfValue == index) {
                                      valueInsertedOn = index;
                                      printed = false;
                                      return (
                                        <td
                                          className={
                                            resultVal.resultFlag != "N" &&
                                            resultVal.resultFlag != null &&
                                            resultVal.resultFlag != ""
                                              ? "text-danger font-weight-bold"
                                              : ""
                                          }
                                        >
                                          {resultVal.observationValue}
                                          {resultVal.observationValueText}{" "}
                                        </td>
                                      );
                                    } else {
                                      if (printed) {
                                        if (
                                          valueInsertedOn == -1 ||
                                          valueInsertedOn < index
                                        ) {
                                          return <td>-</td>;
                                        }
                                      }
                                    }
                                  });
                                })}
                              {uniqueTags.map((val, ind) => {
                                if (ind > valueInsertedOn) {
                                  return <td>-</td>;
                                }
                              })} */}
                              {Array.from(
                                new Set(
                                  value.results.map(
                                    (result) => result.resultName
                                  )
                                )
                              )
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((resultName, index) => {
                                  const indexOfValue =
                                    uniqueTags.indexOf(resultName);
                                  let printed = true;

                                  return uniqueTags.map((header, index) => {
                                    if (indexOfValue === index) {
                                      valueInsertedOn = index;
                                      printed = false;
                                      const resultVal = value.results.find(
                                        (result) =>
                                          result.resultName === resultName
                                      );

                                      return (
                                        <td
                                          className={
                                            resultVal.resultFlag !== "N" &&
                                            resultVal.resultFlag != null &&
                                            resultVal.resultFlag !== ""
                                              ? "text-danger font-weight-bold"
                                              : ""
                                          }
                                        >
                                          {resultVal.observationValue}
                                          {resultVal.observationValueText}{" "}
                                        </td>
                                      );
                                    } else {
                                      if (printed) {
                                        if (
                                          valueInsertedOn === -1 ||
                                          valueInsertedOn < index
                                        ) {
                                          return <td key={index}>-</td>;
                                        }
                                      }
                                    }
                                  });
                                })}
                              {uniqueTags.map((val, ind) => {
                                if (ind > valueInsertedOn) {
                                  return <td key={ind}>-</td>;
                                }
                              })}
                            </tr>
                          );
                        })
                      : searchResult.map((value, index) => (
                          <tr>
                            <td>{value.patientName}</td>
                            <td>{value.orderNumber}</td>
                            <td>{value.mrn}</td>
                            <td>
                              {value.patientDOB == null
                                ? "-"
                                : dateFormat(value.patientDOB, "mm-dd-yyyy")}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {value.patientDOB == null
                                ? "-"
                                : (() => {
                                    const today = new Date();
                                    const birthDate = new Date(
                                      value.patientDOB
                                    );

                                    let calculatedAge =
                                      today.getFullYear() -
                                      birthDate.getFullYear();

                                    // Go back to the year in which the person was born in case of a leap year
                                    if (
                                      birthDate >
                                      new Date(
                                        today.setFullYear(
                                          today.getFullYear() - calculatedAge
                                        )
                                      )
                                    ) {
                                      calculatedAge--;
                                    }

                                    return calculatedAge + " Yrs"; // This value will be rendered in the <td>
                                  })()}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {value.patientPhone}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {LookupText(sex, value.sex)}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {value.zipCode}
                            </td>
                            <td>{value.facilityCode}</td>
                            <td>{value.facilityName}</td>
                            <td>
                              {value.orderingProviderName == ", "
                                ? ""
                                : value.orderingProviderName}
                            </td>
                            <td>
                              {value.orderStatus == "IP"
                                ? "In Process"
                                : value.orderStatus == "CM"
                                ? "Final"
                                : value.orderStatus}
                            </td>
                            <td>
                              {value.embeddedPdf != null && (
                                <BsPrinter
                                  title="Print"
                                  className="mr-1 cursorPointer"
                                  size={20}
                                  onClick={() => {
                                    setLoader(true);
                                    downloadFile(
                                      "downloadFile/" +
                                        value.resultCommonOrderId
                                    ).then((val) => setLoader(false));
                                  }}
                                />
                              )}
                            </td>

                            <td>{value.resultName}</td>
                            <td
                              className={
                                value.resultFlag != "N" &&
                                value.resultFlag != null &&
                                value.resultFlag != ""
                                  ? "text-danger font-weight-bold"
                                  : ""
                              }
                            >
                              {value.observationValue}
                              {value.observationValueText}{" "}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>

                <ReactPaginate
                  forcePage={currentPage}
                  className="pagination mt-3"
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={totalRecords}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  activeClassName="active"
                  pageClassName="link"
                />

                <>
                  <span>showing</span>
                  <Form.Select
                    value={totalRecordPerPage}
                    className={
                      "form-select w-auto d-inline ml-1 mr-1 form-control"
                    }
                    onChange={(e) => {
                      setTotalRecordPerPage(e.target.value);
                      handleSearchFilterChange(
                        "totalRecordPerPage",
                        e.target.value
                      );
                      if (selectedFacilityArray.length == 0) {
                        handleSearchFilterChange("facilities", allFacilities);
                        handleSearchFilterChange(
                          "facilitiesIdsArray",
                          allFacilitiesIds
                        );
                      }
                      handleSearchFilterChange("pageNumber", 1);
                      setCurrentPage(0);
                      setPagenationState(e.target.value + 30);
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </Form.Select>
                  <span>per page</span>
                </>
              </>
            )}
          </div>
        </Container>
      </div>
      <CustomFooter showOrderFooterBar={false} />
      <SaveSearchCriteriaModel
        setShowSavedSearchModel={setShowSavedSearchModel}
        showSavedSearchModel={showSavedSearchModel}
        searchFilter={searchFilter}
        searchName={searchName}
        showText={showText}
        setSearchName={setSearchName}
        oldValue={oldValue}
        setOldValue={setOldValue}
        savedSearchCriteriaId={savedSearchCriteriaId}
        setSavedSearchCriteriaId={setSavedSearchCriteriaId}
        savedSearchCriteriaUserId={savedSearchCriteriaUserId}
        setSaveSearchCriteriaPermissionCheck={
          setSaveSearchCriteriaPermissionCheck
        }
        saveSearchCriteriaPermissionCheck={saveSearchCriteriaPermissionCheck}
        GetAllSaveSearchCriteria={GetAllSaveSearchCriteria}
      />
      <WarningModel
        show={modalShow}
        heading="Confirmation"
        bodytext="Are you sure you want to delete this item?"
        onHide={() => setModalShow(false)}
        onyes={onConfirmationYes}
      />
    </div>
  );
}
export default Results;
