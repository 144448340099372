import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents/mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";
import { ApiGetCalls, ApiPostCalls, downloadFile } from "../../services/Api";
import "../OrderPage/OrderPage.css";
import { Row, Col, Form, Button } from "react-bootstrap";
import { BsPrinter, BsArchive } from "react-icons/bs";
import { IoArchiveSharp } from "react-icons/io5";
import dateFormat from "dateformat";
import DropdownTreeSelect from "react-dropdown-tree-select";
import {
  getAllPhysicalFacilitiesFromTree,
  getChildObjStructure,
  getTreeOptionsSelected,
  getAllPhysicalFromTree,
} from "../OrderPage/Components/SearchFacilityModel";
import ReactPaginate from "react-paginate";
import WarningModel from "../../GlobalComponents/WarningModel";
import { snackBar } from "../../services/Alert";
import { CgNotes } from "react-icons/cg";
import CustomLoader from "../../GlobalComponents/Loader";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

function ResultList(props) {
  var curr = new Date();
  const currentDate = new Date();
  const next2DaysDate = new Date(currentDate);
  const [dateRange, setDateRange] = useState([
    currentDate.setDate(currentDate.getDate() - 7),
    curr,
  ]);
  const [facilitySearchQuery, setFacilitySearchQuery] = useState("");
  const [startDate, endDate] = dateRange;
  const [providerSearchQuery, setProviderSearchQuery] = useState("");
  const [selectedProvidersArray, setSelectedProvidersArray] = useState([]);
  const [providersData, setProvidersData] = useState([]);
  const [showLoader, setLoader] = useState(false);

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [requisition, setRequisition] = useState("");
  const [lab, setLab] = useState("");
  const [status, setStatus] = useState("");
  const [abnormal, setAbnormal] = useState(false);
  const [viewed, setViewed] = useState(false);
  const [printed, setPrinted] = useState(false);

  document.addEventListener("keyup", (event) => {
    if (
      event.target.classList.contains("search") &&
      event.target.closest(".facilityTree")
    ) {
      setFacilitySearchQuery(event.target.value);
    }
  });
  useEffect(() => {
    setTimeout(function () {
      var divId = document.getElementById("customSearchButtonId");
      if (divId) {
        divId.remove();
      }
      var searchButton = document.getElementsByClassName(
        "facilityTreeOkButton"
      );
      if (searchButton.length == 0) {
        var scrollDiv = document.getElementsByClassName(
          "infinite-scroll-component"
        );
        var scrollDiv1 = document.getElementsByClassName("dropdown-content");
        var noMatch = document.getElementsByClassName("no-matches");
        var div = document.createElement("div");
        div.className = "customSearchButton";
        div.id = "customSearchButtonId";
        var button = document.createElement("button");
        button.className =
          "primary btn button SearchBtnCustom facilityTreeOkButton";
        button.innerHTML = "OK";
        button.addEventListener("click", (event) => {
          var facilityDiv = document.getElementsByClassName("facilityTree");
          var toggleButton =
            facilityDiv[0].getElementsByClassName("dropdown-trigger");
          toggleButton[0].click();
          // divToMention.classList.remove("form-control");
        });
        div.appendChild(button);
        if (noMatch.length != 0) {
          scrollDiv1[0].appendChild(div);
        } else {
          if (scrollDiv.length > 0) {
            scrollDiv[0].parentElement.appendChild(div);
          }
        }
      }
    }, 100);
  }, [facilitySearchQuery]);
  document.addEventListener("keyup", (event) => {
    if (
      event.target.classList.contains("search") &&
      event.target.closest(".providerTree")
    ) {
      setProviderSearchQuery(event.target.value);
    }
  });
  useEffect(() => {
    setTimeout(function () {
      var divId = document.getElementById("customSearchButtonId");
      if (divId) {
        divId.remove();
      }
      var searchButton = document.getElementsByClassName(
        "providerTreeOkButton"
      );
      if (searchButton.length == 0) {
        var scrollDiv = document.getElementsByClassName(
          "infinite-scroll-component"
        );
        var scrollDiv1 = document.getElementsByClassName("dropdown-content");
        var noMatch = document.getElementsByClassName("no-matches");
        var div = document.createElement("div");
        div.className = "customSearchButton";
        div.id = "customSearchButtonId";
        var button = document.createElement("button");
        button.className =
          "primary btn button SearchBtnCustom providerTreeOkButton";
        button.innerHTML = "OK";
        button.addEventListener("click", (event) => {
          var facilityDiv = document.getElementsByClassName("providerTree");
          var toggleButton =
            facilityDiv[0].getElementsByClassName("dropdown-trigger");
          toggleButton[0].click();
          // divToMention.classList.remove("form-control");
        });

        div.appendChild(button);
        if (noMatch.length != 0) {
          scrollDiv1[0].appendChild(div);
        } else {
          if (scrollDiv.length > 0) {
            scrollDiv[0].parentElement.appendChild(div);
          }
        }
      }
    }, 100);
  }, [providerSearchQuery]);
  const [locationsDataJson, setLocationsDataJson] = useState(
    JSON.parse(localStorage.getItem("locations"))
  );
  const navigate = useNavigate();

  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Result List";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    } else {
      // getResults();
      var curr = new Date();

      var curr = new Date();
      const currentDate = new Date();
      const next2DaysDate = new Date(currentDate);
      setSearchFilter({
        startDate: moment(
          new Date(currentDate.setDate(currentDate.getDate() - 7))
        ).format("yyyy-MM-DD"),
        endDate: moment(new Date(curr)).format("yyyy-MM-DD"),
        pageNumber: 1,
        totalRecordPerPage: totalRecordPerPage,
        facilities: [],
      });
      handleSearchFilterChange("pageNumber", 1);
      setCurrentPage(0);
      setAllCheckBoxChecked(false);
      setAllCheckBoxCheckedArray([]);
      setViewedArrayForCheck([]);
      setPagenationState(Math.random());
    }
    fetchStatusesInfoDataOnLoad();
  }, []);
  const [totalRecordPerPage, setTotalRecordPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFacilityArray, setSelectedFacilityArray] = useState([]);
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [allCheckBoxChecked, setAllCheckBoxChecked] = useState(false);
  const [allCheckBoxCheckedArray, setAllCheckBoxCheckedArray] = useState([]);
  const [viewedArrayForCheck, setViewedArrayForCheck] = useState([]);
  const [allFacilities, setAllFacilities] = useState([]);
  useEffect(() => {
    if (selectedFacilityArray.length == 0) {
      var facilityIds = [];
      var nonSelectedfacilityCodes = [];
      var physicalFacilities = getAllPhysicalFacilitiesFromTree(
        JSON.parse(localStorage.getItem("locations")),
        []
      );
      physicalFacilities.map((val) => {});
      physicalFacilities.map((val) => {
        if (val.id == JSON.parse(localStorage.getItem("selectedLocation"))) {
          nonSelectedfacilityCodes.push(val.code);
          facilityIds.push(val.id);
        }
      });
      if (allFacilities.length == 0) {
        setAllFacilities(nonSelectedfacilityCodes);
      }
      handleSearchFilterChange("facilities", nonSelectedfacilityCodes);
      setSelectedFacilityArray(facilityIds);
      setSelectedFacilityCodesArray(nonSelectedfacilityCodes);
      setLocationsDataJson(
        getTreeOptionsSelected(
          JSON.parse(localStorage.getItem("locations")),
          "id",
          nonSelectedfacilityCodes
        )
      );
    } else {
      setLocationsDataJson(
        getTreeOptionsSelected(
          JSON.parse(localStorage.getItem("locations")),
          "id",
          selectedFacilityArray
        )
      );
    }
  }, [selectedFacilityArray]);
  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  const [results, setResults] = useState([]);
  const [searchFilter, setSearchFilter] = useState({});
  const [pagenationState, setPagenationState] = useState(-1);
  const [selectedFacilityCodesArray, setSelectedFacilityCodesArray] = useState(
    []
  );

  async function getSearchResult() {
    if (searchFilter.facilities.length == 0) {
      searchFilter.facilities = allFacilities;
    }
    const response = await ApiPostCalls(
      JSON.stringify(searchFilter),

      "getResultWithFacility"
    );
    if (response.length > 0) {
      setTotalRecords(Math.ceil(response[0].totalRecords / totalRecordPerPage));
      setResults(response);
      var idsArray = [];
      response.map((val, ind) => {
        idsArray.push(val.commonOrderId);
      });
      props.setResultIds(idsArray);
      localStorage.setItem("resultIds", JSON.stringify(idsArray));
    } else {
      setResults(response);
    }
    setLoader(false);
  }
  useEffect(() => {
    if (pagenationState != -1) {
      setLoader(true);
      getSearchResult();
    }
  }, [pagenationState]);
  const handleSearchFilterChange = (name, value) => {
    value = value != "" ? value : null;
    setSearchFilter((values) => ({ ...values, [name]: value }));
  };
  const [updateResult, setUpdateResult] = useState({
    updateField: "IsArchived",
    updateFieldValue: "Y",
  });
  const handleUpdateResultChange = (name, value) => {
    setUpdateResult((values) => ({ ...values, [name]: value }));
  };
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * 1) % 2;
    handleSearchFilterChange("pageNumber", event.selected + 1);
    setAllCheckBoxChecked(false);
    setAllCheckBoxCheckedArray([]);
    setViewedArrayForCheck([]);
    setPagenationState(Math.random());
  };
  async function updateResultValue() {
    const response = await ApiPostCalls(
      JSON.stringify(updateResult),

      "updateResultValues"
    );
    if (response.status == "Success") {
      getSearchResult();
      snackBar("Success! Result archived successfully", "success");
    }
    if (response.status == "Error") {
      snackBar("Error! Result archived unable to updated", "danger");
    }
  }
  useEffect(() => {
    //setProvidersData([]);
    if (selectedFacilityArray.length != 0)
      GetMultiFacilityProviders(selectedFacilityArray);
  }, [selectedFacilityArray]);
  const GetMultiFacilityProviders = async (ids) => {
    // const response = await ApiPostCalls(
    //   "GetProvidersByFacilities/" + selectedLocation
    // );
    const response = await ApiPostCalls(
      JSON.stringify(ids),

      "GetProvidersByFacilities"
    );
    if (response.status == "Success") {
      const insurancesDataObjWithAll = {
        id: 0,
        label: "All",
        expanded: true,
        className: "non-selective allSelection",
        children: [],
      };
      for (let i = 0; i < response.data.length; i++) {
        const providersDataObj = {
          //providers[i].id.toString(),
          label: response.data[i].lastName + " " + response.data[i].firstName,
          id: response.data[i].npi,
          className: "",
          children: [],
        };
        insurancesDataObjWithAll.children.push(providersDataObj);
      }
      providersData.push(insurancesDataObjWithAll);
      //setSelectedProvidersArray([]);
      setProvidersData([
        ...getTreeOptionsSelected(providersData, "id", selectedProvidersArray),
      ]);
    }
  };
  const resetPage = () => {
    var curr = new Date();
    const currentDate = new Date();
    const next2DaysDate = new Date(currentDate);
    var curr1 = new Date();
    const currentDate1 = new Date();
    const next2DaysDate1 = new Date(currentDate1);

    setSearchFilter({
      // startDate: moment(
      //   new Date(currentDate.setDate(currentDate.getDate() - 2))
      // ).format("yyyy-MM-DD"),
      // endDate: moment(
      //   new Date(next2DaysDate.setDate(curr.getDate() + 2))
      // ).format("yyyy-MM-DD"),
      startDate: moment(
        new Date(currentDate.setDate(currentDate.getDate() - 7))
      ).format("yyyy-MM-DD"),
      endDate: moment(new Date(curr)).format("yyyy-MM-DD"),
      pageNumber: 1,
      totalRecordPerPage: totalRecordPerPage,
      facilities: [],
      providers: [],
    });
    setDateRange([currentDate1.setDate(currentDate1.getDate() - 7), curr1]);
    setProvidersData([]);
    setSelectedFacilityArray([]);
    setSelectedFacilityCodesArray([]);
    setSelectedProvidersArray([]);
    setTotalRecordPerPage(10);
    setLastName("");
    setFirstName("");
    setRequisition("");
    setLab("");
    setStatus("");
    setAbnormal(false);
    setViewed(false);
    setPrinted(false);
    setPagenationState(Math.random());
  };
  useEffect(() => {
    setProvidersData([
      ...getTreeOptionsSelected(providersData, "id", selectedProvidersArray),
    ]);
  }, [selectedProvidersArray]);
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      setPagenationState(Math.random());
    }
  };
  const [lookOrderInfo, setLookOrderInfo] = useState({
    reportStatuses: [],
  });
  async function fetchStatusesInfoDataOnLoad() {
    try {
      const [reportStatuses] = await Promise.all([
        await ApiGetCalls("GetQuickLookupsByName/reportstatus"),
      ]);
      setLookOrderInfo({
        reportStatuses: reportStatuses,
      });
    } catch (error) {}
  }
  return (
    <div>
      {showLoader && <CustomLoader />}
      <CustomNavBar
        patientDetails={patientDetails}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        selectedOrderPatientId={props.selectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        selectedOrderPatientId={props.selectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      {/* <div className="patients-list-pageheight"> */}
      <div className="pageheight resultsPage withOutbar">
        <Container className="mb-5">
          <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
            Results Inbox
            {/* <select className="form-select ml-auto w-auto">
                <option>EimHurst Care Center</option>
                <option>EimHurst Care Center</option>
                <option>EimHurst Care Center</option>
              </select> */}
          </h3>
          <div className="whitepartition">
            <Row>
              <Col md={{ span: 6 }} lg={{ span: 4 }}>
                <h5 className="label-style">Date Range</h5>
                <DatePicker
                  preventOpenOnFocus={true}
                  className="form-control"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM-dd-yyyy"
                  onChange={(update) => {
                    handleSearchFilterChange(
                      "startDate",
                      moment(new Date(update[0])).format("yyyy-MM-DD")
                    );
                    if (update[1] != null) {
                      handleSearchFilterChange(
                        "endDate",
                        moment(new Date(update[1])).format("yyyy-MM-DD")
                      );
                    }
                    setDateRange(update);
                  }}
                  //disabled={showDateRange}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                />
              </Col>
            </Row>
            <Container className="resultAdvancesearch">
              <Row>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Last Name</h5>
                  <input
                    name="LastName"
                    type="text"
                    className="input-style form-control mb-3"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeypress}
                  />
                </Col>

                <Col md={{ span: 4 }}>
                  <h5 className="label-style">First Name</h5>
                  <input
                    name="FirstName"
                    type="text"
                    className="input-style form-control mb-3"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Requisition #</h5>
                  <input
                    name="OrderNumber"
                    type="text"
                    className="input-style form-control mb-3"
                    value={requisition}
                    onChange={(e) => {
                      setRequisition(e.target.value);
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeypress}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={{ span: 3 }} lg={{ span: 4 }}>
                  <h5 className="label-style">Lab</h5>
                  <input
                    name="Lab"
                    type="text"
                    className="input-style form-control mb-3"
                    value={lab}
                    onChange={(e) => {
                      setLab(e.target.value);
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 3 }} lg={{ span: 4 }}>
                  <h5 className="label-style">Status</h5>
                  <Form.Select
                    className="input-style form-control"
                    name="resultStatus"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value={""}></option>
                    {lookOrderInfo?.reportStatuses.map((val, ind) => {
                      return (
                        <option value={val.optionCode}>{val.options}</option>
                      );
                    })}
                    {/* <option value="IP">In Process</option>
                    <option value="CM">Final</option> */}
                  </Form.Select>
                </Col>

                {/* <Col
                  md={{ span: 1 }}
                  className="d-flex flex-row align-items-end align-content-end "
                >
                  <Form.Check
                    type="checkbox"
                    label=" Viewed"
                    className="mb-3"
                    checked={viewed}
                    onChange={(e) => {
                      setViewed(e.target.checked);
                      if (e.target.checked) {
                        handleSearchFilterChange("viewed", "Y");
                      } else {
                        handleSearchFilterChange("viewed", "");
                      }
                    }}
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col
                  md={{ span: 1 }}
                  className="d-flex flex-row align-items-end align-content-end "
                >
                  <Form.Check
                    type="checkbox"
                    label=" Printed"
                    className="mb-3"
                    checked={printed}
                    onChange={(e) => {
                      setPrinted(e.target.checked);
                      if (e.target.checked) {
                        handleSearchFilterChange("printed", "Y");
                      } else {
                        handleSearchFilterChange("printed", "");
                      }
                    }}
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col
                  md={{ span: 1 }}
                  className="d-flex flex-row align-items-end align-content-end "
                >
                  <Form.Check
                    type="checkbox"
                    label=" Abnormal"
                    className="mb-3"
                    checked={abnormal}
                    onChange={(e) => {
                      setAbnormal(e.target.checked);
                      if (e.target.checked) {
                        handleSearchFilterChange("abnormal", "Y");
                      } else {
                        handleSearchFilterChange("abnormal", "");
                      }
                    }}
                    onKeyDown={handleKeypress}
                  />
                </Col> */}
                <Col md={{ span: 2 }}>
                  <h5 className="label-style">Abnormal</h5>
                  <Form.Select
                    className="input-style form-control"
                    name="abnormal"
                    value={abnormal}
                    onChange={(e) => {
                      setAbnormal(e.target.value);
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value={""}></option>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </Form.Select>
                </Col>
                <Col md={{ span: 1 }}>
                  <h5 className="label-style">Viewed</h5>
                  <Form.Select
                    className="input-style form-control"
                    name="viewed"
                    value={viewed}
                    onChange={(e) => {
                      setViewed(e.target.value);
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value={""}></option>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </Form.Select>
                </Col>
                <Col md={{ span: 1 }}>
                  <h5 className="label-style">Printed</h5>
                  <Form.Select
                    className="input-style form-control"
                    name="printed"
                    value={printed}
                    onChange={(e) => {
                      setPrinted(e.target.value);
                      handleSearchFilterChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value={""}></option>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row>
                <Col md={{ span: 6 }} className="resultFacilityTree">
                  <h5 className="label-style">Facility</h5>
                  {/* <DropdownTreeSelect
                  mode="multiSelect"
                  keepTreeOnSearch={true}
                  keepChildrenOnSearch={true}
                  keepOpenOnSelect={false}
                  data={locationsDataJson}
                  showPartiallySelected={true}
                  onChange={(e) => {
                    if (e.facilityCode != null) {
                      var test = selectedFacilityArray;
                      if (selectedFacilityArray.length == 0) {
                      }

                      if (test.indexOf(e.facilityCode) == -1) {
                        test.push(e.facilityCode);
                        handleSearchFilterChange("facilities", test);
                        setSelectedFacilityArray([...test]);
                      } else {
                        test.splice(test.indexOf(e.facilityCode), 1);
                        handleSearchFilterChange("facilities", test);

                        setSelectedFacilityArray([...test]);
                      }
                    } else {
                      const found = [];
                      found.push(
                        getChildObjStructure(
                          JSON.parse(localStorage.getItem("locations")),
                          e.id
                        )
                      );
                      var physicalFacilities = getAllPhysicalFacilitiesFromTree(
                        found,
                        []
                      );
                      physicalFacilities.map((val) => {
                        var test = selectedFacilityArray;
                        if (test.indexOf(val.code) == -1) {
                          test.push(val.code);
                          handleSearchFilterChange("facilities", test);
                        } else if (e.checked == false) {
                          test.splice(test.indexOf(val.code), 1);
                          handleSearchFilterChange("facilities", test);
                        }
                        setSelectedFacilityArray([...test]);
                      });
                    }
                    // handleSearchFilterChange("pageNumber", 1);
                    // setCurrentPage(0);
                    // setPagenationState(Math.random());
                  }}
                  onFocus={() => {
                    setTimeout(function () {
                      var searchButton = document.getElementsByClassName(
                        "facilityTreeOkButton"
                      );
                      if (searchButton.length == 0) {
                        var scrollDiv = document.getElementsByClassName(
                          "infinite-scroll-component"
                        );
                        var div = document.createElement("div");
                        div.className = "customSearchButton";
                        div.id = "customSearchButtonId";
                        var button = document.createElement("button");
                        button.className =
                          "primary btn button SearchBtnCustom facilityTreeOkButton";
                        button.innerHTML = "OK";
                        button.addEventListener("click", (event) => {
                          var facilityDiv =
                            document.getElementsByClassName("facilityTree");
                          var toggleButton =
                            facilityDiv[0].getElementsByClassName(
                              "dropdown-trigger"
                            );
                          toggleButton[0].click();
                          // divToMention.classList.remove("form-control");
                        });

                        div.appendChild(button);
                        scrollDiv[0].parentElement.appendChild(div);
                      }
                    }, 100);
                  }}
                  className="ml-auto w-auto bootstrap-demo facilityTree"
                /> */}
                  <DropdownTreeSelect
                    mode="multiSelect"
                    keepTreeOnSearch={true}
                    keepChildrenOnSearch={true}
                    keepOpenOnSelect={false}
                    data={locationsDataJson}
                    showPartiallySelected={true}
                    onChange={(e) => {
                      setSelectedProvidersArray([]);
                      setProvidersData([]);
                      setProvidersData([]);
                      if (e.className != "non-selective") {
                        var test = selectedFacilityArray;
                        var CodesArray = selectedFacilityCodesArray;
                        if (selectedFacilityArray.length == 0) {
                        }

                        if (test.indexOf(e.id) == -1) {
                          test.push(e.id);
                          handleSearchFilterChange("facilitiesIdsArray", test);
                          setSelectedFacilityArray([...test]);
                        } else {
                          test.splice(test.indexOf(e.id), 1);
                          handleSearchFilterChange("facilitiesIdsArray", test);

                          setSelectedFacilityArray([...test]);
                        }
                        if (CodesArray.indexOf(e.id) == -1) {
                          CodesArray.push(e.id);
                          // handleSearchFilterChange("facilities", test);
                          //setSelectedFacilityIdsArray([...idsArray]);
                        } else {
                          CodesArray.splice(CodesArray.indexOf(e.code), 1);
                        }
                        setSelectedFacilityCodesArray([...CodesArray]);
                        handleSearchFilterChange("facilities", CodesArray);
                      } else {
                        const found = [];
                        found.push(
                          getChildObjStructure(
                            JSON.parse(localStorage.getItem("locations")),
                            e.id
                          )
                        );
                        var physicalFacilities =
                          getAllPhysicalFacilitiesFromTree(found, []);
                        physicalFacilities.map((val) => {
                          var test = selectedFacilityArray;
                          var CodesArray = selectedFacilityCodesArray;
                          if (test.indexOf(val.id) == -1) {
                            test.push(val.id);
                            handleSearchFilterChange(
                              "facilitiesIdsArray",
                              test
                            );
                            setSelectedFacilityArray([...test]);
                          } else if (e.checked == false) {
                            test.splice(test.indexOf(val.id), 1);
                            handleSearchFilterChange(
                              "facilitiesIdsArray",
                              test
                            );
                            setSelectedFacilityArray([...test]);
                          }
                          if (CodesArray.indexOf(val.code) == -1) {
                            CodesArray.push(val.code);
                            // handleSearchFilterChange("facilities", test);
                            //setSelectedFacilityIdsArray([...idsArray]);
                          } else if (e.checked == false) {
                            CodesArray.splice(CodesArray.indexOf(val.id), 1);
                          }
                          setSelectedFacilityCodesArray([...CodesArray]);
                          handleSearchFilterChange("facilities", CodesArray);
                        });
                      }
                      // handleSearchFilterChange("pageNumber", 1);
                      // setCurrentPage(0);
                      // setPagenationState(Math.random());
                    }}
                    onFocus={() => {
                      setTimeout(function () {
                        var searchButton = document.getElementsByClassName(
                          "facilityTreeOkButton"
                        );
                        if (searchButton.length == 0) {
                          var scrollDiv = document.getElementsByClassName(
                            "infinite-scroll-component"
                          );
                          var div = document.createElement("div");
                          div.className = "customSearchButton";
                          div.id = "customSearchButtonId";
                          var button = document.createElement("button");
                          button.className =
                            "primary btn button SearchBtnCustom facilityTreeOkButton";
                          button.innerHTML = "OK";
                          button.addEventListener("click", (event) => {
                            var facilityDiv =
                              document.getElementsByClassName("facilityTree");
                            var toggleButton =
                              facilityDiv[0].getElementsByClassName(
                                "dropdown-trigger"
                              );
                            toggleButton[0].click();
                            // divToMention.classList.remove("form-control");
                          });

                          div.appendChild(button);
                          scrollDiv[0].parentElement.appendChild(div);
                        }
                      }, 100);
                    }}
                    className="ml-auto w-auto bootstrap-demo facilityTree"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 3 }} className="resultProviderTree">
                  <h5 className="label-style">Provider</h5>
                  <DropdownTreeSelect
                    mode="multiSelect"
                    keepTreeOnSearch={true}
                    keepChildrenOnSearch={true}
                    keepOpenOnSelect={false}
                    onFocus={() => {
                      setTimeout(function () {
                        var searchButton = document.getElementsByClassName(
                          "providerTreeOkButton"
                        );
                        if (searchButton.length == 0) {
                          var scrollDiv = document.getElementsByClassName(
                            "infinite-scroll-component"
                          );
                          var div = document.createElement("div");
                          div.className = "customSearchButton";
                          div.id = "customSearchButtonId";
                          var button = document.createElement("button");
                          button.className =
                            "primary btn button SearchBtnCustom providerTreeOkButton";
                          button.innerHTML = "OK";
                          button.addEventListener("click", (event) => {
                            var facilityDiv =
                              document.getElementsByClassName("providerTree");
                            var toggleButton =
                              facilityDiv[0].getElementsByClassName(
                                "dropdown-trigger"
                              );
                            toggleButton[0].click();
                            // divToMention.classList.remove("form-control");
                          });

                          div.appendChild(button);
                          scrollDiv[0].parentElement.appendChild(div);
                        }
                      }, 100);
                    }}
                    data={providersData}
                    onChange={(e) => {
                      if (e.id != 0) {
                        if (selectedProvidersArray.indexOf(e.id) == -1) {
                          selectedProvidersArray.push(e.id);
                        } else {
                          selectedProvidersArray.splice(
                            selectedProvidersArray.indexOf(e.id),
                            1
                          );
                        }
                        if (e.hide == undefined) {
                          handleSearchFilterChange(
                            "providers",
                            selectedProvidersArray
                          );
                          // handleTestAnalyteJsonFilter(
                          //   "providers",
                          //   selectedProvidersArray
                          // );
                          //setShowValueField([]);
                          setSelectedProvidersArray([
                            ...selectedProvidersArray,
                          ]);
                          setProviderSearchQuery("");
                        }
                      } else {
                        const found = [];
                        found.push(getChildObjStructure(providersData, e.id));
                        var physicalFacilities = getAllPhysicalFromTree(
                          found,
                          [],
                          providerSearchQuery
                        );
                        physicalFacilities.map((val) => {
                          if (selectedProvidersArray.indexOf(val.id) == -1) {
                            selectedProvidersArray.push(val.id);
                          } else if (e.checked == false) {
                            selectedProvidersArray.splice(
                              selectedProvidersArray.indexOf(val.id),
                              1
                            );
                          }
                        });
                      }
                    }}
                    onBlur={() => {
                      handleSearchFilterChange(
                        "providers",
                        selectedProvidersArray
                      );
                      // handleTestAnalyteJsonFilter(
                      //   "providers",
                      //   selectedProvidersArray
                      // );
                      //setShowValueField([]);
                      setSelectedProvidersArray([...selectedProvidersArray]);
                      setProviderSearchQuery("");
                    }}
                    className="bootstrap-demo providerTree"
                    onKeyDown={handleKeypress}
                    //onKeyDown={handleKeypress}
                  />
                  {/* <InputWithLoader
                    filter={providerFilter}
                    placeholder="Start typing..."
                    apiEndPoint=""
                    setData={setProviders}
                    setShowResults={setShowProviderResults}
                    setFilter={setProviderFilter}
                    name="insuranceCompanies"
                    aria_label="Insurance company name"
                    className="input-style form-control"
                    data_type="text"
                  />
                  {showProviderResults ? (
                    <SelectBody
                      rowClickHandler={rowClickHandlerProviders}
                      filter={providerFilter}
                      column={["Name"]}
                      dataArray={providersData}
                      setShowResults={setShowProviderResults}
                    />
                  ) : null} */}
                </Col>
              </Row>
            </Container>
            <hr></hr>
            <Row>
              <Col className="d-flex flex-row">
                <Button
                  variant="primary"
                  className="primary btn button btn-outline  ml-auto mr-1"
                  onClick={(e) => {
                    handleSearchFilterChange("pageNumber", 1);
                    setCurrentPage(0);
                    setAllCheckBoxChecked(false);
                    setAllCheckBoxCheckedArray([]);
                    setViewedArrayForCheck([]);
                    setPagenationState(Math.random());
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="primary"
                  className="outline button ml-2"
                  onClick={() => resetPage()}
                >
                  Reset
                </Button>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col className="d-flex flex-row">
                <Button
                  variant="primary"
                  disabled={allCheckBoxCheckedArray.length != 0 ? false : true}
                  onClick={() => {
                    if (viewedArrayForCheck.length > 0) {
                      snackBar(
                        "Error! Result not archived because it is not viewed yet.",
                        "danger"
                      );
                    } else {
                      handleUpdateResultChange(
                        "resultCommonOrderIds",
                        allCheckBoxCheckedArray
                      );
                      setCancelModalShow(true);
                    }
                  }}
                  className="primary btn button btn-outline  ml-auto mr-1"
                >
                  Archive
                </Button>
              </Col>
            </Row>
            <div
              className="patients-advance-search-results mt-3 widecolumns noHover"
              style={{ display: "block" }}
            >
              <Row className="DarkHeading sticky-top">
                <Col className="reultInboxCheckBox">
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAllCheckBoxChecked(true);
                        if (results.length > 0) {
                          var checkBoxCheckedArray = [];
                          var viewedCheckedArray = [];
                          results.map((value, index) => {
                            checkBoxCheckedArray.push(value.commonOrderId);
                            if (value.isViewed == null) {
                              viewedCheckedArray.push(value.commonOrderId);
                            }
                          });
                          setAllCheckBoxCheckedArray(checkBoxCheckedArray);
                          setViewedArrayForCheck(viewedCheckedArray);
                        }
                      } else {
                        setAllCheckBoxChecked(false);
                        setAllCheckBoxCheckedArray([]);
                        setViewedArrayForCheck([]);
                      }
                    }}
                    checked={allCheckBoxChecked}
                  />
                </Col>
                <Col md={{ span: 1 }} className="resultsname">
                  <b>Name</b>
                </Col>
                <Col
                  style={{ minWidth: "101px" }}
                  md={{ span: 1 }}
                  className="resultsdate"
                >
                  <b>Result Date</b>
                </Col>
                <Col md={{ span: 2 }}>
                  <b>Requisition #</b>
                </Col>
                <Col md={{ span: 1 }}>
                  <b>Lab</b>
                </Col>
                <Col md={{ span: 1 }}>
                  <b>Abnormal</b>
                </Col>
                <Col md={{ span: 1 }}>
                  <b>Status</b>
                </Col>
                <Col md={{ span: 2 }}>
                  <b>Provider</b>
                </Col>
                <Col>
                  <b>V</b>
                </Col>
                <Col>
                  <b>P</b>
                </Col>
                <Col style={{ minWidth: "100px" }}>
                  <b>Actions</b>{" "}
                </Col>
              </Row>
              {results.length > 0 ? (
                results.map((value, index) => (
                  <Row>
                    <Col className="reultInboxCheckBox">
                      <Form.Check
                        type="checkbox"
                        checked={
                          allCheckBoxCheckedArray.indexOf(
                            value.commonOrderId
                          ) != -1
                        }
                        onChange={(e) => {
                          var idsArray = allCheckBoxCheckedArray;
                          var checkViewedArray = viewedArrayForCheck;
                          if (e.target.checked) {
                            if (idsArray.indexOf(value.commonOrderId) == -1) {
                              idsArray.push(value.commonOrderId);
                            }
                          } else {
                            idsArray.splice(
                              idsArray.indexOf(value.commonOrderId),
                              1
                            );
                            setAllCheckBoxChecked(false);
                          }
                          //if (e.target.checked) {
                          if (value.isViewed == null) {
                            if (
                              checkViewedArray.indexOf(value.commonOrderId) ==
                              -1
                            ) {
                              checkViewedArray.push(value.commonOrderId);
                            } else {
                              checkViewedArray.splice(
                                checkViewedArray.indexOf(value.commonOrderId),
                                1
                              );
                            }
                          }
                          // }
                          setAllCheckBoxCheckedArray([...idsArray]);
                          setViewedArrayForCheck([...checkViewedArray]);
                        }}
                      />
                    </Col>
                    <Col
                      md={{ span: 1 }}
                      className={
                        value.isAbnormal == "Y"
                          ? "text-danger font-weight-bold resultsname"
                          : "resultsname"
                      }
                    >
                      {value.patientName}
                    </Col>
                    <Col
                      style={{ minWidth: "101px" }}
                      md={{ span: 1 }}
                      className={
                        value.isAbnormal == "Y"
                          ? "text-danger font-weight-bold resultsdate"
                          : "resultsdate"
                      }
                    >
                      {value.dateTimeOfTransaction == null
                        ? "-"
                        : dateFormat(value.dateTimeOfTransaction, "mm-dd-yyyy")}
                    </Col>
                    <Col
                      className={
                        value.isAbnormal == "Y"
                          ? "text-danger font-weight-bold"
                          : ""
                      }
                      md={{ span: 2 }}
                    >
                      {value.orderNumber}
                    </Col>
                    <Col
                      className={
                        value.isAbnormal == "Y"
                          ? "text-danger font-weight-bold"
                          : ""
                      }
                      md={{ span: 1 }}
                    >
                      {value.sendingFacility}
                    </Col>
                    <Col
                      className={
                        value.isAbnormal == "Y"
                          ? "text-danger font-weight-bold"
                          : ""
                      }
                      md={{ span: 1 }}
                    >
                      {value.isAbnormal}
                    </Col>
                    <Col
                      className={
                        value.isAbnormal == "Y"
                          ? "text-danger font-weight-bold"
                          : ""
                      }
                      md={{ span: 1 }}
                    >
                      {value.orderStatus == "IP"
                        ? "In Process"
                        : value.orderStatus == "CM"
                        ? "Final"
                        : value.orderStatus == null
                        ? "-"
                        : value.orderStatus}
                    </Col>
                    <Col
                      className={
                        value.isAbnormal == "Y"
                          ? "text-danger font-weight-bold"
                          : ""
                      }
                      md={{ span: 2 }}
                    >
                      {value.orderingProviderName == ", "
                        ? ""
                        : value.orderingProviderName}
                    </Col>
                    <Col
                      className={
                        value.isAbnormal == "Y"
                          ? "text-danger font-weight-bold"
                          : ""
                      }
                    >
                      {value.isViewed == null ? "N" : value.isViewed}
                    </Col>
                    <Col
                      className={
                        value.isAbnormal == "Y"
                          ? "text-danger font-weight-bold"
                          : ""
                      }
                    >
                      {value.isPrinted == null ? "N" : value.isPrinted}
                    </Col>
                    <Col style={{ minWidth: "100px" }}>
                      <CgNotes
                        disabled={true}
                        title="N/A"
                        className="mr-1"
                        size={20}
                        onClick={() => {
                          //  navigate("/resultDetail/" + value.commonOrderId);
                        }}
                      />{" "}
                      <BsPrinter
                        title={
                          value.embeddedPdf != null ? "Print" : "File not found"
                        }
                        className="mr-1"
                        size={20}
                        color={value.embeddedPdf != null ? "black" : "#6c757d"}
                        onClick={() => {
                          if (value.embeddedPdf != null) {
                            setLoader(true);
                            downloadFile(
                              "downloadFile/" + value.commonOrderId
                            ).then((val) => setLoader(false));
                            ApiPostCalls(
                              JSON.stringify({
                                updateField: "IsPrinted",
                                updateFieldValue: "Y",
                                resultCommonOrderIds: [value.commonOrderId],
                              }),

                              "updateResultValues"
                            ).then((response) => {
                              if (response.status == "Success") {
                                getSearchResult();
                              }
                            });
                          }
                          //updateResultValue();
                        }}
                      />{" "}
                      <BsArchive
                        title="Archive"
                        size={20}
                        onClick={() => {
                          if (value.isViewed == null) {
                            snackBar(
                              "Error! Result not archived because it is not viewed yet.",
                              "danger"
                            );
                          } else {
                            handleUpdateResultChange("resultCommonOrderIds", [
                              value.commonOrderId,
                            ]);
                            setCancelModalShow(true);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                ))
              ) : (
                <Row>
                  <Col>No record found</Col>
                </Row>
              )}
            </div>
            <ReactPaginate
              forcePage={currentPage}
              className="pagination"
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalRecords}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              activeClassName="active"
              pageClassName="link"
            />

            <>
              <span>showing</span>
              <Form.Select
                value={totalRecordPerPage}
                className={"form-select w-auto d-inline ml-1 mr-1 form-control"}
                onChange={(e) => {
                  setTotalRecordPerPage(e.target.value);
                  handleSearchFilterChange(
                    "totalRecordPerPage",
                    e.target.value
                  );
                  handleSearchFilterChange("pageNumber", 1);
                  setCurrentPage(0);
                  setAllCheckBoxChecked(false);
                  setAllCheckBoxCheckedArray([]);
                  setViewedArrayForCheck([]);
                  setPagenationState(Math.random());
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </Form.Select>
              <span>per page</span>
            </>
          </div>
        </Container>
      </div>
      <WarningModel
        show={cancelModalShow}
        heading="Confirmation"
        bodytext="Are you sure you want to archive the selected result(s)?"
        onHide={() => setCancelModalShow(false)}
        onyes={async () => {
          updateResultValue();
          setAllCheckBoxCheckedArray([]);
          setViewedArrayForCheck([]);
          setCancelModalShow(false);
        }}
      />
      <CustomFooter showOrderFooterBar={false} />
      <div
        class="modal fade"
        id="ResultPreviewModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content" style={{ height: "600px" }}>
            <div class="modal-header">
              <h5 class="modal-title " id="exampleModalLabel">
                {"Result"}
              </h5>
              <button
                onClick={() => {}}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Container>
                <iframe
                  id="resultPreviewBlob"
                  frameBorder="0"
                  scrolling="auto"
                  height="400px"
                  width="100%"
                ></iframe>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="outline button btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {}}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResultList;
