import React, { useState, useEffect, useContext, setContext } from "react";
import { Container } from "react-bootstrap";
import CustomFooter from "../GlobalComponents/Footer";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import CustomNavBar from "../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../GlobalComponents/mobileNavBar";
import { ApiPostCalls, authenticate } from "../services/Api";
import { json, useNavigate } from "react-router-dom";
import { LoginInfoContext, LoginUserLocationContext } from "./../Context";
import { snackBar } from "../services/Alert";
import { handleValidation } from "../GlobalComponents/ValidatorAndSubmitForm";

function LogIn() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "ATVIVO";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") != null &&
      localStorage.getItem("userInfo") != null &&
      userInfoJson?.userFirstName != undefined &&
      userInfoJson?.userLastName != undefined &&
      localStorage.getItem("selectedLocation") != null
    ) {
      if(localStorage.getItem("firstLogin") == "No"){
        navigate("/changePassword");
      }else{
        navigate("/patients");
      }
    } else {
      // localStorage.clear();
      localStorage.removeItem("locations");
      localStorage.removeItem("token");
      localStorage.removeItem("selectedLocation");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userPermissions");
      localStorage.removeItem("selectableLocations");
      localStorage.removeItem("userId");
      localStorage.removeItem("firstLogin");

      if (localStorage.getItem("locations") == null) {
        setLocations([]);
        setSelectableLocations([]);
      }
      if (localStorage.getItem("selectedLocation") == null) {
        setSelectedLocation("");
      }
    }
  }, []);
  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
  });
  const [locations, setLocations] = useState([]);
  const [selectableLocations, setSelectableLocations] = useState([]);
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const [userId, setUserId] = useState("");
  const [context, setContext] = useContext(LoginInfoContext);
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    var validation = handleValidation("login");
    if (validation) {
      const responseData = await authenticate(JSON.stringify(formValues), "login");
      if (responseData) {
        if(responseData.status == 'Error')
        {
          snackBar(responseData.message, "danger");
          return false;
        }
        const response = responseData.data;
        if ("token" in response) {
          localStorage.removeItem("locations");
          localStorage.removeItem("token");
          localStorage.removeItem("selectedLocation");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userPermissions");
          localStorage.removeItem("selectableLocations");
          localStorage.removeItem("userId");
          localStorage.removeItem("firstLogin");
          localStorage.removeItem("expiryPasswordDate");
          setUserId(response.userId);
          setContext({
            userInfo: {
              userId: response.userId,
              userFirstName: response.userFirstName,
              userLastName: response.userLastName,
              userEmail: response.userEmail,
              phone: response.userPhone,
              userOrg: response.userOrganization,
              defaultFacility: response.defaultFacilityId,
            },
            token: response["token"],
            locations: response.selectableFacilities,
          });
          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              userId: response.userId,
              userFirstName: response.userFirstName,
              userLastName: response.userLastName,
              userEmail: response.userEmail,
              phone: response.userPhone,
              userOrg: response.userOrganization,
              defaultFacility: response.defaultFacilityId,
            })
          );
          localStorage.setItem(
            "userPermissions",
            JSON.stringify(response.permissions)
          );
          localStorage.setItem("token", response["token"]);
          localStorage.setItem("refreshToken", response["refreshToken"]);
          localStorage.setItem(
            "locations",
            JSON.stringify(response.facilities)
          );
          localStorage.setItem("userId", response.userId);
          localStorage.setItem("expiryPasswordDate", response.expiryPasswordDate);
          localStorage.setItem("firstLogin", response.firstLogin);
          localStorage.setItem(
            "selectableLocations",
            JSON.stringify(response.selectableFacilities)
          );
          if (
            response.defaultFacilityId != null &&
            response.defaultFacilityId != 0
          ) {
            localStorage.setItem(
              "selectedLocation",
              response.defaultFacilityId
            );
            setSelectedLocation(response.defaultFacilityId);
            if(response.firstLogin == "No"){
              navigate("/changePassword");
            }else{
            if (localStorage.getItem("Url") != null) {
              navigate(localStorage.getItem("Url"));
            } else {
              navigate("/patients");
            }
          }
          }
          setLocations(response.facilities);
          setSelectableLocations(response.selectableFacilities);
          if (response.selectableFacilities.length == 1 && response.defaultFacilityId == null &&
            response.defaultFacilityId == 0 ) {
            // navigate("/patients");
            localStorage.setItem("selectedLocation", response.facilities[0].id);
            setSelectedLocation(response.facilities[0].facilityId);
            if(response.firstLogin == "No"){
              navigate("/changePassword");
            }
            else{
            if (localStorage.getItem("Url") != null) {
              navigate(localStorage.getItem("Url"));
            } else {
              navigate("/patients");
            }
          }
        }
          // navigate("/patients");
        }
      } else {
        snackBar("Error! Login failed", "danger");
      }
    } else {
      snackBar("Error! Please provide login details", "danger");
    }
  };
  const onChangeHandle = async (e) => {
    e.preventDefault();
    setSelectedLocation(e.target.value);
  };
  if (locations?.length == 0) {
    if (localStorage.getItem("locations") != null) {
      setLocations(JSON.parse(localStorage.getItem("locations")));

      if (selectableLocations?.length == 0) {
        setSelectableLocations(
          JSON.parse(localStorage.getItem("selectableLocations"))
        );
      }
    }
  }
  const handleProceed = async () => {
    const defaultLocationValues = {
      userId: userId,
      facilityId: selectedLocation,
      firstName: context.userInfo.userFirstName,
      lastName: context.userInfo.userLastName,
    };
    const response = await ApiPostCalls(
      JSON.stringify(defaultLocationValues),
      "UpdateProfile"
    );
    if (response) {
      if (response.status == "Success") {
        var userInfo = context.userInfo;
        userInfo.defaultFacility = selectedLocation;
        setContext({ ...context, userInfo: userInfo });
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        if (localStorage.getItem("Url") != null) {
          navigate(localStorage.getItem("Url"));
        } else {
          navigate("/patients");
        }
      }
    } else {
      snackBar("Error! Login failed", "danger");
    }
  };
  return (
    <div>
      <CustomNavBar
        screenLogin={true}
        patientDetails={{ patientName: "-" }}
        sexLookup=""
      />
      <CollapsibleMobileNav
        screenLogin={true}
        patientDetails={{ patientName: "-" }}
        sexLookup=""
      />
      <div id="login" className="pageheight login">
        <Container className="logincontainer">
          <div className="partition">
            <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
              Sign in with your Account
            </h3>

            <hr></hr>

            <Row className="mb-4 mt-4">
              {
                <Col md={{ span: 12 }}>
                  <h5 className="label-style">Username</h5>
                  <input
                    required
                    name="username"
                    aria-label="Username"
                    type="text"
                    className="input-style form-control mb-3"
                    value={formValues.username}
                    onChange={handleChange}
                    disabled={locations.length == 0 ? false : true}
                    onKeyDown={handleKeypress}
                  />
                </Col>
              }

              {
                <Col md={{ span: 12 }}>
                  <h5 className="label-style">Password</h5>
                  <div>
                    <InputGroup className="input-style">
                      <Form.Control
                        required
                        aria-label="Password"
                        name="password"
                        className="mb-3"
                        onChange={handleChange}
                        value={formValues.password}
                        type={passwordShown ? "text" : "password"}
                        disabled={locations.length == 0 ? false : true}
                        onKeyDown={handleKeypress}
                      />
                      <Button
                        onClick={togglePasswordVisiblity}
                        variant="outline-secondary"
                        className="mb-3"
                        id="button-addon2"
                      >
                        {passwordShown ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </InputGroup>
                  </div>
                </Col>
              }
              {locations.length > 0 && (
                <>
                  <Col md={{ span: 12 }}>
                    <h5 className="label-style">Facility</h5>
                    <Form.Select
                      onChange={onChangeHandle}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (selectedLocation != "") {
                            localStorage.setItem(
                              "selectedLocation",
                              selectedLocation
                            );
                            if (checkBoxValue) {
                              handleProceed();
                            } else {
                              if (localStorage.getItem("Url") != null) {
                                navigate(localStorage.getItem("Url"));
                              } else {
                                navigate("/patients");
                              }
                            }
                          }
                        }
                      }}
                    >
                      <option></option>
                      {selectableLocations.map((value, index) => {
                        return (
                          <option
                            key={index}
                            // selected={
                            //   selectedLocation == value.locationNumber
                            //     ? true
                            //     : false
                            // }
                            value={value.id}
                          >
                            {value.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                  <Col md={{ span: 12 }} className="mt-2">
                    <Form.Check
                      label="Set this as my default facility"
                      key="defaultLocationCheckBox"
                      name="defaultLocationCheckBox"
                      onChange={(event) => {
                        if (event.target.checked) {
                          setCheckBoxValue(true);
                        } else {
                          setCheckBoxValue(false);
                        }
                      }}
                    />
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col
                md={{ span: 12 }}
                className="d-flex flex-row align-items-end align-content-end "
              >
                {locations.length > 0 && (
                  <Button
                    variant="outline "
                    className="outline button  ml-auto"
                    onClick={() => {
                      localStorage.clear();
                      setLocations([]);
                      setSelectableLocations([]);
                      setFormValues({
                        username: "",
                        password: "",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {locations.length > 0 ? (
                  <Button
                    variant="primary"
                    className="primary btn button ml-2"
                    disabled={selectedLocation != "" ? false : true}
                    onClick={() => {
                      if (selectedLocation != "") {
                        localStorage.setItem(
                          "selectedLocation",
                          selectedLocation
                        );
                        if (checkBoxValue) {
                          handleProceed();
                        } else {
                          if (localStorage.getItem("Url") != null) {
                            navigate(localStorage.getItem("Url"));
                          } else {
                            navigate("/patients");
                          }
                        }
                      }
                    }}
                  >
                    Proceed
                  </Button>
                ) : (
                  <Button
                    onClick={handleSubmit}
                    variant="primary"
                    className="primary btn button ml-auto"
                  >
                    Sign In
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <CustomFooter showOrderFooterBar={false} />
    </div>
  );
}
export default LogIn;
