import React, { useState, useEffect, Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Form, Row, Col, Container } from "react-bootstrap";
import SearchAndSelectTest from "./SearchAndSelectTest";
import { ApiGetCalls } from "../../../services/Api";
import InputField from "../../../GlobalComponents/FormInputField";
import Textarea from "../../../GlobalComponents/FormTextarea";
import FormSelect from "../../../GlobalComponents/FromSelect";
import FormRadio from "../../../GlobalComponents/FormRadio";
import DatePickerComponent from "../../../GlobalComponents/DatePicker";

function AccControls(props) {
  const [testList, setTestList] = useState([]);
  const [updateArray, setUpdateArray] = useState("");
  const [orderLevelQuestionsArray, setOrderLevelQuestionsArray] = useState({});
  const [aoePossibleAnswersState, setAoePossibleAnswersState] = useState(
    Object.keys(props.orderData).length > 0 ? props.orderData.aoeAnswers : []
  );
  const [infoDetailState, setInfoDetailState] = useState([]);
  const [infoPopupDetailState, setInfoPopupDetailState] = useState([]);
  const [infoPopupIndexState, setInfoPopupIndexState] = useState();
  const favouritesTestInfoPopupArray = [];
  async function handleClick(testId) {
    await ApiGetCalls("GetTestById/" + testId).then((data) => {
      setTestList((oldTestsList) => [...oldTestsList, { list: data }]);
      var infoDelimitedDetail = "";

      if (data.specimenRequirement != "") {
        infoDelimitedDetail = data.specimenRequirement;
      }
      // if (data.container != "") {
      //   infoDelimitedDetail =
      //     infoDelimitedDetail + "CONTAINER|" + data.container + "|";
      // }
      // if (data.minimumVolume != "") {
      //   infoDelimitedDetail =
      //     infoDelimitedDetail + "MINIMUN VOLUME|" + data.minimumVolume + "|";
      // }
      // if (data.specimenTransport != "") {
      //   infoDelimitedDetail =
      //     infoDelimitedDetail +
      //     "SPECIMEN TRANSPORT|" +
      //     data.specimenTransport +
      //     "|";
      // }
      // if (data.stability != "") {
      //   infoDelimitedDetail =
      //     infoDelimitedDetail + "STABILITY|" + data.stability;
      // }
      setInfoPopupDetailState((oldArray) => [...oldArray, infoDelimitedDetail]);
      // setInfoPopupDetailState(...infoPopupDetailState, [infoDelimitedDetail]);
    });
  }

  useEffect(() => {
    props.handleClinicalFormChange("aoeAnswers", aoePossibleAnswersState);
  }, [aoePossibleAnswersState]);
  useEffect(() => {
    var testListArray = [];
    if (props.orderData.orderTests != undefined) {
      props.orderData.orderTests.map((item, index) => {
        testListArray.push({ list: item.tests });
        //setTestList([...testList, { list: item.tests }]);
      });
      setTestList(testListArray);
    } else {
      setTestList(testListArray);
      setOrderLevelQuestionsArray([]);
    }
  }, [props.orderData]);
  function handleDelete(testId) {
    commonQuestionsArray = [];
    setOrderLevelQuestionsArray({});
    testList
      .filter((value) => testId != value.list.id)
      .map((alist, key) => {
        alist.list.aoeQuestions?.map((value, index) => {
          if (value.isOrderLevel == "Y") {
            if (!commonQuestionsArray.some((item) => value.id == item.id)) {
              commonQuestionsArray.push(value);
            }
          }
        });
      });
    setOrderLevelQuestionsArray(commonQuestionsArray);
    setTestList(testList.filter((value) => testId != value.list.id));
  }
  var commonQuestionsArray = [];
  const [selectedDataArray, setSelectedDataArray] = useState({
    formValues:
      Object.keys(props.orderData).length > 0 ? props.orderData.aoeAnswers : [],
  });
  return (
    <>
      <Accordion defaultActiveKey="0" id="testsAccordions">
        <Accordion.Item eventKey="0">
          <Accordion.Header>TESTS</Accordion.Header>
          <Accordion.Body>
            <SearchAndSelectTest
              propsOnChange={handleClick}
              handleDelete={handleDelete}
              locationBasedData={props.locationBasedData}
              handleClinicalFormChange={props.handleClinicalFormChange}
              orderData={props.orderData}
              infoDetailState={infoDetailState}
              setInfoDetailState={setInfoDetailState}
              infoPopupDetailState={infoPopupDetailState}
              setInfoPopupDetailState={setInfoPopupDetailState}
              setInfoPopupIndexState={setInfoPopupIndexState}
              infoPopupIndexState={infoPopupIndexState}
              favouritesTestInfoPopupArray={favouritesTestInfoPopupArray}
              userLocationId={props.userLocationId}
              orderIdAsParam={props.orderIdAsParam}
              savedOrderId={props.savedOrderId}
              favIconHandler={props.favIconHandler}
              setFavIconHandler={props.setFavIconHandler}
              setHighLightTests={props.setHighLightTests}
              highLightTests={props.highLightTests}
              medicalNecessityResponse={props.medicalNecessityResponse}
              listOptionsDiagnosis={props.listOptionsDiagnosis}
              setListOptionsDiagnosis={props.setListOptionsDiagnosis}
              selectedDiagnosesId={props.selectedDiagnosesId}
              setSelectedDiagnosesId={props.setSelectedDiagnosesId}
              setLocationBasedData={props.setLocationBasedData}
              clinicalInputs={props.clinicalInputs}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion id="clinicalAccordion" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>CLINICAL HISTORY</Accordion.Header>
          <Accordion.Body>
            {orderLevelQuestionsArray.length > 0 ? (
              <BuildCommonTestQuestions
                testAnswers={orderLevelQuestionsArray}
                commonQuestionsArray={commonQuestionsArray}
                setOrderLevelQuestionsArray={setOrderLevelQuestionsArray}
                setAoePossibleAnswersState={setAoePossibleAnswersState}
                aoePossibleAnswersState={aoePossibleAnswersState}
                setUpdateArray={setUpdateArray}
                updateArray={updateArray}
                orderData={props.orderData}
                selectedDataArray={selectedDataArray}
                setSelectedDataArray={setSelectedDataArray}
              />
            ) : null}
            {testList.map((value, key) => {
              return (
                <BuildTest
                  key={key}
                  testAnswers={value.list}
                  commonQuestionsArray={commonQuestionsArray}
                  setOrderLevelQuestionsArray={setOrderLevelQuestionsArray}
                  setAoePossibleAnswersState={setAoePossibleAnswersState}
                  aoePossibleAnswersState={aoePossibleAnswersState}
                  setUpdateArray={setUpdateArray}
                  updateArray={updateArray}
                  orderData={props.orderData}
                  selectedDataArray={selectedDataArray}
                  setSelectedDataArray={setSelectedDataArray}
                />
              );
            })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default AccControls;

function BuildCommonTestQuestions(props) {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Common Questions</Accordion.Header>
        <Accordion.Body>
          <Container>
            <GenrateForm
              key={"commonQuestion"}
              testValues={props.testAnswers}
              orderLevel="Y"
              commonQuestionsArray={props.commonQuestionsArray}
              setOrderLevelQuestionsArray={props.setOrderLevelQuestionsArray}
              setAoePossibleAnswersState={props.setAoePossibleAnswersState}
              aoePossibleAnswersState={props.aoePossibleAnswersState}
              setUpdateArray={props.setUpdateArray}
              updateArray={props.updateArray}
              orderData={props.orderData}
              selectedDataArray={props.selectedDataArray}
              setSelectedDataArray={props.setSelectedDataArray}
            />
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
function BuildTest(props) {
  return props.testAnswers.aoeQuestions.length > 0 ? (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{props.testAnswers.testName}</Accordion.Header>
        <Accordion.Body>
          <Container>
            <GenrateForm
              key={"questions" + props.testAnswers.testName}
              testValues={props.testAnswers.aoeQuestions}
              orderLevel="N"
              commonQuestionsArray={props.commonQuestionsArray}
              setOrderLevelQuestionsArray={props.setOrderLevelQuestionsArray}
              setAoePossibleAnswersState={props.setAoePossibleAnswersState}
              aoePossibleAnswersState={props.aoePossibleAnswersState}
              setUpdateArray={props.setUpdateArray}
              updateArray={props.updateArray}
              orderData={props.orderData}
              selectedDataArray={props.selectedDataArray}
              setSelectedDataArray={props.setSelectedDataArray}
            />
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  ) : (
    <></>
  );
}
function GenrateForm(props) {
  var arrayIndex = -1;
  const [orderId, setOrderId] = useState(
    Object.keys(props.orderData).length > 0 ? props.orderData.id : ""
  );
  if (props.orderLevel == "N") {
    props.testValues?.map((value, index) => {
      if (value.isOrderLevel == "Y") {
        if (!props.commonQuestionsArray.some((item) => value.id == item.id)) {
          props.commonQuestionsArray.push(value);
          arrayIndex = index;
        }
      }
    });
  }

  const onChangeHandle = async (obj) => {
    updateAlerts(props.selectedDataArray, obj);
  };
  function updateAlerts(input, update) {
    let foundAlert = input.formValues.find(
      (item) => item.questionId === update.questionId
    );
    if (foundAlert) {
      if (update.answerId != null) {
        foundAlert.answerId = update.answerId;
      } else {
        foundAlert.answerValue = update.answerValue;
      }
    } else {
      input.formValues.push(update);
    }
    props.setSelectedDataArray(input);
  }
  useEffect(() => {
    props.setSelectedDataArray({
      formValues:
        Object.keys(props.orderData).length > 0
          ? props.orderData.aoeAnswers
          : [],
    });
    props.setUpdateArray(arrayIndex);
  }, [orderId]);
  useEffect(() => {
    props.setOrderLevelQuestionsArray(props.commonQuestionsArray);
  }, [props.updateArray]);
  useEffect(() => {
    props.setAoePossibleAnswersState(props.selectedDataArray.formValues);
  }, [props.selectedDataArray]);
  return (
    <Row key={"rowGenerateForm"}>
      {props.testValues
        ?.filter((filterValue) => filterValue.isOrderLevel == props.orderLevel)
        .map((value, index) => {
          return (
            <Fragment key={index + "keySpan"}>
              {value.anwserType == "RADIO" ||
              value.anwserType == "CHECKBOX" ||
              value.anwserType == "SELECT" ? (
                value.aoePossibleAnswers.length > 0 ? (
                  <Col
                    key={"col" + index}
                    md={{ span: 4 }}
                    className={value.required == "Y" ? "mandatory" : ""}
                  >
                    {value.anwserType == "SELECT" ? (
                      <FormSelect
                        key={"select" + index}
                        disabled={
                          props.orderData.status == "Draft" ||
                          props.orderData.status == undefined
                            ? false
                            : true
                        }
                        required={value.required == "Y" ? true : false}
                        testId={value.testId}
                        questionId={value.id}
                        formValue={value.question}
                        optionValues={value.aoePossibleAnswers}
                        orderId={orderId}
                        onChange={onChangeHandle}
                        defaultValue={props.orderData?.aoeAnswers?.find(
                          (item, index) =>
                            item.questionId == value.id ? item.answerId : 0
                        )}
                      />
                    ) : value.anwserType == "RADIO" ||
                      value.anwserType == "CHECKBOX" ? (
                      <FormRadio
                        key={"radio" + index}
                        disabled={
                          props.orderData.status == "Draft" ||
                          props.orderData.status == undefined
                            ? false
                            : true
                        }
                        required={value.required == "Y" ? true : false}
                        testId={value.testId}
                        questionId={value.id}
                        orderId={orderId}
                        formValue={value.question}
                        anwserType={value.anwserType}
                        name={
                          value.anwserType == "CHECKBOX"
                            ? value.anwserType + value.id + "[]"
                            : value.question
                        }
                        radioValues={value.aoePossibleAnswers}
                        type={value.anwserType}
                        className="clinicalRadio"
                        defaultValue={
                          value.anwserType == "CHECKBOX"
                            ? props.orderData?.aoeAnswers?.find((item, index) =>
                                item.questionId == value.id
                                  ? item.answerValue
                                  : 0
                              )
                            : props.orderData?.aoeAnswers?.find((item, index) =>
                                item.questionId == value.id ? item.answerId : 0
                              )
                        }
                        defaultCheckedValue={
                          value.aoePossibleAnswers.length > 0
                            ? value.aoePossibleAnswers[0].id
                            : 0
                        }
                        onChange={onChangeHandle}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                ) : (
                  <></>
                )
              ) : (
                <Col
                  key={"col" + index}
                  md={{ span: 4 }}
                  className={value.required == "Y" ? "mandatory" : ""}
                >
                  {value.anwserType == "INPUT" ? (
                    <InputField
                      key={"input" + index}
                      disabled={
                        props.orderData.status == "Draft" ||
                        props.orderData.status == undefined
                          ? false
                          : true
                      }
                      testId={value.testId}
                      questionId={value.id}
                      orderId={orderId}
                      required={value.required == "Y" ? true : false}
                      formValue={value.question}
                      onChangeFunction={onChangeHandle}
                      defaultValue={props.orderData?.aoeAnswers?.find(
                        (item, index) =>
                          item.questionId == value.id ? item.answerValue : ""
                      )}
                    />
                  ) : value.anwserType == "TEXTAREA" ? (
                    <Textarea
                      key={"textArea" + index}
                      disabled={
                        props.orderData.status == "Draft" ||
                        props.orderData.status == undefined
                          ? false
                          : true
                      }
                      testId={value.testId}
                      questionId={value.id}
                      formValue={value.question}
                      orderId={orderId}
                      required={value.required == "Y" ? true : false}
                      onChange={onChangeHandle}
                      defaultValue={props.orderData?.aoeAnswers?.find(
                        (item, index) =>
                          item.questionId == value.id ? item.answerValue : ""
                      )}
                    />
                  ) : value.anwserType == "DATE" ? (
                    <DatePickerComponent
                      key={"date" + index}
                      disabled={
                        props.orderData.status == "Draft" ||
                        props.orderData.status == undefined
                          ? false
                          : true
                      }
                      required={value.required == "Y" ? true : false}
                      testId={value.testId}
                      questionId={value.id}
                      orderId={orderId}
                      className="input-style form-control"
                      formValue={value.question}
                      onChange={() => {}}
                      dateFormat="MM-dd-yyyy"
                      showTimeSelect={false}
                      onChangeFunction={onChangeHandle}
                      defaultValue2={props.orderData?.aoeAnswers?.find(
                        (item, index) =>
                          item.questionId == value.id ? item.answerValue : ""
                      )}
                    />
                  ) : (
                    <div key={index + "div1"}></div>
                  )}
                </Col>
              )}
            </Fragment>
          );
          // }
        })}
    </Row>
  );
}
