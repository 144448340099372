import React, { useState, useEffect, useContext } from "react";
import { Container, FormCheck } from "react-bootstrap";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents//mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";
import "./OrderPage.css";
import moment from "moment/moment";
import dateFormat from "dateformat";
import { ApiGetCalls, ApiPostCalls, downloadFile } from "../../services/Api";
import { useParams } from "react-router-dom";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { LoginInfoContext, LoginUserLocationContext } from "../../Context";
import { numberOfInsurancesInJSON } from "../../GlobalComponents/ValidatorAndSubmitForm";
import { apiUrl } from "../../config/config";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ReactPaginate from "react-paginate";
import MaskedInput from "react-text-mask";
import { BsPrinter } from "react-icons/bs";
import { snackBar } from "../../services/Alert";
import CustomLoader from "../../GlobalComponents/Loader";
import { FaPencilAlt, FaSortUp, FaSortDown, FaSort } from "react-icons/fa";

function Orders(props) {
  // const [selectedLocation, setSelectedLocation] = useContext(
  //   LoginUserLocationContext
  // );
  const [selectedLocation, setSelectedLocation] = useState(
    localStorage.getItem("selectedLocation")
  );
  const navigate = useNavigate();
  const [addNewOrderState, setAddNewOrderState] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [orderNumberState, setOrderNumberState] = useState("");
  const [printing, setPrintng] = useState(false);
  const [labelCopies, setLabelCopies] = useState(1);
  const [pdflabel, setPdflabel] = useState("");
  const [lablesCountInPdf, setLablesCountInPdf] = useState(null);
  const [pdfBlob, setPdfBlob] = useState("");
  const [orderPdfDetail, setOrderPdfDetail] = useState("");
  const [lookOrderInfo, setLookOrderInfo] = useState({
    reportStatuses: [],
    orderStatuses: [],
  });
  async function fetchStatusesInfoDataOnLoad() {
    try {
      const [reportStatuses, orderStatuses] = await Promise.all([
        await ApiGetCalls("GetQuickLookupsByName/reportstatus"),
        await ApiGetCalls("GetQuickLookupsByName/orderstatus"),
      ]);
      setLookOrderInfo({
        reportStatuses: reportStatuses,
        orderStatuses: orderStatuses,
      });
    } catch (error) {}
  }
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Orders";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    } else {
      if (id != undefined) {
        // getPatientOrders(id);
        props.setSelectedOrderPatientId(id);
      }
      if (localStorage.getItem("userPermissions") != null) {
        JSON.parse(localStorage.getItem("userPermissions")).map(
          (value, index) => {
            if (value.facilityId == selectedLocation) {
              if (value.featureName == "Orders") {
                if (value.createFeature == "N") {
                  setAddNewOrderState(true);
                }
              }
            }
          }
        );
      }
      fetchStatusesInfoDataOnLoad();
      setSearchFilter({
        orderNumber: null,
        status: null,
        orderDate: null,
        pageNumber: 1,
        facilityId: selectedLocation,
        id: id,
        totalRecordPerPage: totalRecordPerPage,
        sortingField: "CreatedAt",
        sortingType: "DESC",
      });
      setPagenationState(Math.random());
    }
  }, []);
  const [orderList, setOrderList] = useState({ list: [] });
  const [fetchData, setfetchData] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecordPerPage, setTotalRecordPerPage] = useState(10);
  const [pagenationState, setPagenationState] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchFilter, setSearchFilter] = useState({});
  useEffect(() => {}, [orderList]);

  let { id } = useParams();

  const handleSearchFilterChange = (name, value) => {
    setSearchFilter((values) => ({
      ...values,
      [name]: value == "" ? null : value,
    }));
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * 1) % 2;
    handleSearchFilterChange("pageNumber", event.selected + 1);
    setPagenationState(Math.random());
  };
  async function getPatientOrders(id) {
    try {
      var body = JSON.stringify(searchFilter);
      await ApiPostCalls(body, "GetOrderByPatientId").then((data) => {
        setOrderList({ ...orderList, list: data });
        if (data.length > 0) {
          setTotalRecords(Math.ceil(data[0].totalRecords / totalRecordPerPage));
          setfetchData(true);
        } else {
          setTotalRecords(0);
        }
      });
      await ApiGetCalls("GetQuickLookupsByName/Sex").then((data) => {
        setSexLookup({ sex: data });
      });
      await ApiGetCalls("GetPatientById/" + id).then((data) => {
        let primaryInsuranceIndex = 0;
        for (var i = 0; i < numberOfInsurancesInJSON(data); i++) {
          if (data.patientInsurances[i].primaryInsurance == "Y") {
            primaryInsuranceIndex = i;
            break;
          }
        }

        setPatientDetails({
          patientName: data.persons.lastName + ", " + data.persons.firstName,
          patientDob: dateFormat(data.persons.dob, "mm/dd/yyyy"),
          patientInsurance:
            data.patientInsurances.length > 0
              ? data.patientInsurances[primaryInsuranceIndex].insuranceCompanies
                  .name
              : "-",
          patientPhone: data.persons.phone,
          patientSex: data.persons.sex,
        });
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }
  // useEffect(() => {
  //   if (selectedLocation != "") getPatientOrders(id);
  // }, [selectedLocation]);

  const [showResults, setShowResults] = useState(false);
  const [sexLookup, setSexLookup] = useState({});

  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  const clickHandle = (e) => {
    navigate("/order/new");
  };
  useEffect(() => {
    if (pagenationState != -1) {
      setLoader(true);
      getPatientOrders(id);
    }
  }, [pagenationState]);
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      setCurrentPage(0);
      setPagenationState(Math.random());
    }
  };
  async function printOrderReport(orderNumber) {
    if (printing) {
      return;
    }
    setPrintng(true);
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Response-Type": "blob",
      },
      // body: JSON.stringify({
      //   orderNumber: "A000071",
      // }),
    };
    const response = await fetch(
      apiUrl + "GenerateOrderLabel/" + orderNumber,
      requestOptions
    )
      .then(async (response) => {
        if (response.status.toString() == "200") {
          return response.blob();
        } else {
          if (response.status.toString() == "401") {
            const accessToken = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            const refreshPayload = JSON.stringify({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });

            var refreshTokenResponse = await ApiPostCalls(
              refreshPayload,
              "refresh-token"
            );
            localStorage.setItem("token", refreshTokenResponse["accessToken"]);
            localStorage.setItem(
              "refreshToken",
              refreshTokenResponse["refreshToken"]
            );
            printOrderReport(orderNumber);
            setLabelCopies(1);
          }
          return false;
        }
      })
      .then(async (blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          setPdflabel(base64data);
        };
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Response-Type": "blob",
          },
          body: JSON.stringify({
            orderNumbers: [orderNumber],
            lablesCount: lablesCountInPdf != null ? lablesCountInPdf : 8,
          }),
        };
        const response = await fetch(
          apiUrl + "GenerateOrderAndForeignOrdersPdf",
          requestOptions
        )
          .then((response) => response.blob())
          .then((blob) => {
            // var blob = response.pdf.blob();
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              setPdfBlob(base64data);
              setOrderPdfDetail(orderNumber + "- Requisition Report");
              var a = document.createElement("a");
              a.setAttribute("data-bs-toggle", "modal");
              a.setAttribute(
                "data-bs-target",
                "#PrintPreviewDialogInOrderHistory"
              );
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();
              setPrintng(false);
              setLoader(false);
            };
          });
      });
  }
  useEffect(() => {
    if (lablesCountInPdf != null) {
      setPrintng(true);
      setLoader(true);
      async function printOrderReportOnLablesCount(orderNumber) {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Response-Type": "blob",
          },
          body: JSON.stringify({
            orderNumbers: [orderNumber],
            lablesCount: lablesCountInPdf != null ? lablesCountInPdf : 8,
          }),
        };

        var response = fetch(
          apiUrl + "GenerateOrderAndForeignOrdersPdf",
          requestOptions
        )
          .then(async (response) => {
            if (response.status.toString() == "200") {
              return response.blob();
            } else {
              if (response.status.toString() == "401") {
                const accessToken = localStorage.getItem("token");
                const refreshToken = localStorage.getItem("refreshToken");
                const refreshPayload = JSON.stringify({
                  accessToken: accessToken,
                  refreshToken: refreshToken,
                });

                var refreshTokenResponse = await ApiPostCalls(
                  refreshPayload,
                  "refresh-token"
                );
                localStorage.setItem(
                  "token",
                  refreshTokenResponse["accessToken"]
                );
                localStorage.setItem(
                  "refreshToken",
                  refreshTokenResponse["refreshToken"]
                );
                printOrderReportOnLablesCount(orderNumber);
              }
              return false;
            }
          })
          .then((blob) => {
            // var blob = response.pdf.blob();
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              setPdfBlob(base64data);
              setOrderPdfDetail(orderNumber + "- Requisition Report");
              setPrintng(false);
              setLoader(false);
            };
          });
      }
      printOrderReportOnLablesCount(orderNumberState);
    }
  }, [lablesCountInPdf]);
  return (
    <div onClick={() => setShowResults(false)}>
      {showLoader && <CustomLoader />}
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="pageheight-orderPage">
        <Container className="mb-5">
          <div className="partition">
            <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
              Order History
              <Button
                onClick={clickHandle}
                disabled={addNewOrderState}
                variant="primary"
                className="primary btn button ml-auto"
              >
                Add New Order
              </Button>
            </h3>
            <h5>Search</h5>
            <hr></hr>

            <Row className="orders-filters">
              <Col>
                <h5 className="label-style">Requisition #</h5>
                <input
                  value={
                    searchFilter["requisitionNo"] == null
                      ? ""
                      : searchFilter["requisitionNo"]
                  }
                  name="requisitionNo"
                  type="text"
                  className="input-style form-control mb-3"
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                  onKeyDown={handleKeypress}
                />
              </Col>
              <Col>
                <h5 className="label-style">Order Date</h5>
                <DatePicker
                  preventOpenOnFocus={true}
                  name="orderDate"
                  ariaLabelledBy="Order Date"
                  className="input-style form-control"
                  dateFormat="MM-dd-yyyy"
                  autoComplete="off"
                  maxDate={new Date()}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                  selected={
                    searchFilter["orderDate"] != undefined ||
                    searchFilter["orderDate"] == ""
                      ? new Date(searchFilter["orderDate"])
                      : null
                  }
                  onChange={(date) => {
                    handleSearchFilterChange(
                      "orderDate",
                      date != null
                        ? moment(date).format("yyyy-MM-DDT00:00:00")
                        : null
                    );
                  }}
                  customInput={
                    <MaskedInput
                      mask={[
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      placeholder={"MM/dd/yyyy"}
                      keepCharPositions={true}
                      guide={true}
                    />
                  }
                  onKeyDown={handleKeypress}
                />
              </Col>
              <Col>
                <h5 className="label-style">Order Status</h5>
                <Form.Select
                  className="input-style form-control"
                  name="status"
                  value={
                    searchFilter["status"] == null ? "" : searchFilter["status"]
                  }
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                  onKeyDown={handleKeypress}
                >
                  <option value={""}></option>
                  {lookOrderInfo?.orderStatuses.map((val, ind) => {
                    return (
                      <option value={val.optionCode}>{val.options}</option>
                    );
                  })}
                  {/* <option value={"Draft"}>Draft</option>
                  <option value={"Submitted"}>Available For Editing</option>
                  <option value={"Sent"}>Transmitted</option>
                  <option value={"Unsolicited"}>Unsolicited</option> */}
                </Form.Select>
              </Col>
              <Col>
                <h5 className="label-style">Report Date</h5>
                <DatePicker
                  preventOpenOnFocus={true}
                  name="reportDate"
                  ariaLabelledBy="report Date"
                  className="input-style form-control"
                  dateFormat="MM-dd-yyyy"
                  autoComplete="off"
                  maxDate={new Date()}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                  selected={
                    searchFilter["reportDate"] != undefined ||
                    searchFilter["reportDate"] == ""
                      ? new Date(searchFilter["reportDate"])
                      : null
                  }
                  onChange={(date) => {
                    handleSearchFilterChange(
                      "reportDate",
                      date != null
                        ? moment(date).format("yyyy-MM-DDT00:00:00")
                        : null
                    );
                  }}
                  customInput={
                    <MaskedInput
                      mask={[
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      placeholder={"MM/dd/yyyy"}
                      keepCharPositions={true}
                      guide={true}
                    />
                  }
                  onKeyDown={handleKeypress}
                />
                {/* <input
                  name="advancedSearchPhone"
                  type="text"
                  className="input-style form-control mb-3"
                  onKeyDown={handleKeypress}
                /> */}
              </Col>
              <Col>
                <h5 className="label-style">Report Status</h5>
                <Form.Select
                  className="input-style form-control"
                  name="reportStatus"
                  value={
                    searchFilter["reportStatus"] == null
                      ? ""
                      : searchFilter["reportStatus"]
                  }
                  onChange={(e) => {
                    handleSearchFilterChange(e.target.name, e.target.value);
                  }}
                  onKeyDown={handleKeypress}
                >
                  <option value={""}></option>
                  {lookOrderInfo?.reportStatuses.map((val, ind) => {
                    return (
                      <option value={val.optionCode}>{val.options}</option>
                    );
                  })}
                  {/* <option value="IP">In Process</option>
                  <option value="CM">Final</option> */}
                </Form.Select>
              </Col>
              {/* <Col className="d-flex flex-row align-items-end align-content-end ">
                <Form.Check
                  type="checkbox"
                  label=" Viewed"
                  className="mb-3"
                  onKeyDown={handleKeypress}
                />
              </Col> */}
            </Row>
            <Row>
              <Col className="d-flex flex-row align-items-end align-content-end ">
                <Button
                  variant="primary"
                  className="primary btn ml-auto button  mb-3"
                  onClick={() => {
                    getPatientOrders(id);
                    setCurrentPage(0);
                  }}
                >
                  Filter
                </Button>
                <Button
                  variant="outline "
                  className="outline button mb-3 ml-2"
                  onClick={() => {
                    setSearchFilter({
                      orderNumber: null,
                      status: null,
                      orderDate: null,
                      pageNumber: 1,
                      facilityId: selectedLocation,
                      id: id,
                      totalRecordPerPage: totalRecordPerPage,
                      sortingField: "CreatedAt",
                      sortingType: "DESC",
                    });
                    setCurrentPage(0);
                    setPagenationState(Math.random());
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
            <Table className="mt-4" responsive="md" bordered hover>
              <thead>
                <tr className="DarkHeading">
                  <th
                    className="cursorPointer"
                    onClick={() => {
                      handleSearchFilterChange("sortingField", "OrderDate");
                      if (
                        searchFilter.sortingType == "" ||
                        searchFilter.sortingField != "OrderDate"
                      ) {
                        handleSearchFilterChange("sortingType", "ASC");
                      } else if (searchFilter.sortingType == "ASC") {
                        handleSearchFilterChange("sortingType", "DESC");
                      } else {
                        handleSearchFilterChange("sortingType", "ASC");
                      }

                      setPagenationState(Math.random());
                    }}
                  >
                    Order Date{" "}
                    {searchFilter.sortingField == "OrderDate" &&
                    searchFilter.sortingType == "ASC" ? (
                      <FaSortUp />
                    ) : searchFilter.sortingField == "OrderDate" &&
                      searchFilter.sortingType == "DESC" ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  <th
                    className="cursorPointer"
                    onClick={() => {
                      handleSearchFilterChange("sortingField", "OrderNumber");
                      if (
                        searchFilter.sortingType == "" ||
                        searchFilter.sortingField != "OrderNumber"
                      ) {
                        handleSearchFilterChange("sortingType", "ASC");
                      } else if (searchFilter.sortingType == "ASC") {
                        handleSearchFilterChange("sortingType", "DESC");
                      } else {
                        handleSearchFilterChange("sortingType", "ASC");
                      }

                      setPagenationState(Math.random());
                    }}
                  >
                    Requisition #{" "}
                    {searchFilter.sortingField == "OrderNumber" &&
                    searchFilter.sortingType == "ASC" ? (
                      <FaSortUp />
                    ) : searchFilter.sortingField == "OrderNumber" &&
                      searchFilter.sortingType == "DESC" ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  <th
                    className="cursorPointer"
                    onClick={() => {
                      handleSearchFilterChange("sortingField", "TemplateId");
                      if (
                        searchFilter.sortingType == "" ||
                        searchFilter.sortingField != "TemplateId"
                      ) {
                        handleSearchFilterChange("sortingType", "ASC");
                      } else if (searchFilter.sortingType == "ASC") {
                        handleSearchFilterChange("sortingType", "DESC");
                      } else {
                        handleSearchFilterChange("sortingType", "ASC");
                      }

                      setPagenationState(Math.random());
                    }}
                  >
                    Template{" "}
                    {searchFilter.sortingField == "TemplateId" &&
                    searchFilter.sortingType == "ASC" ? (
                      <FaSortUp />
                    ) : searchFilter.sortingField == "TemplateId" &&
                      searchFilter.sortingType == "DESC" ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  <th
                    className="cursorPointer"
                    onClick={() => {
                      handleSearchFilterChange("sortingField", "Status");
                      if (
                        searchFilter.sortingType == "" ||
                        searchFilter.sortingField != "Status"
                      ) {
                        handleSearchFilterChange("sortingType", "ASC");
                      } else if (searchFilter.sortingType == "ASC") {
                        handleSearchFilterChange("sortingType", "DESC");
                      } else {
                        handleSearchFilterChange("sortingType", "ASC");
                      }

                      setPagenationState(Math.random());
                    }}
                  >
                    Order Status{" "}
                    {searchFilter.sortingField == "Status" &&
                    searchFilter.sortingType == "ASC" ? (
                      <FaSortUp />
                    ) : searchFilter.sortingField == "Status" &&
                      searchFilter.sortingType == "DESC" ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  <th
                    className="cursorPointer"
                    onClick={() => {
                      handleSearchFilterChange("sortingField", "ReportDate");
                      if (
                        searchFilter.sortingType == "" ||
                        searchFilter.sortingField != "ReportDate"
                      ) {
                        handleSearchFilterChange("sortingType", "ASC");
                      } else if (searchFilter.sortingType == "ASC") {
                        handleSearchFilterChange("sortingType", "DESC");
                      } else {
                        handleSearchFilterChange("sortingType", "ASC");
                      }

                      setPagenationState(Math.random());
                    }}
                  >
                    Report Date{" "}
                    {searchFilter.sortingField == "ReportDate" &&
                    searchFilter.sortingType == "ASC" ? (
                      <FaSortUp />
                    ) : searchFilter.sortingField == "ReportDate" &&
                      searchFilter.sortingType == "DESC" ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  <th
                    className="cursorPointer"
                    onClick={() => {
                      handleSearchFilterChange("sortingField", "ReportStatus");
                      if (
                        searchFilter.sortingType == "" ||
                        searchFilter.sortingField != "ReportStatus"
                      ) {
                        handleSearchFilterChange("sortingType", "ASC");
                      } else if (searchFilter.sortingType == "ASC") {
                        handleSearchFilterChange("sortingType", "DESC");
                      } else {
                        handleSearchFilterChange("sortingType", "ASC");
                      }

                      setPagenationState(Math.random());
                    }}
                  >
                    Report Status{" "}
                    {searchFilter.sortingField == "ReportStatus" &&
                    searchFilter.sortingType == "ASC" ? (
                      <FaSortUp />
                    ) : searchFilter.sortingField == "ReportStatus" &&
                      searchFilter.sortingType == "DESC" ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </th>
                  {/* <th>Report</th>
                  <th>Viewed</th> */}
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {fetchData ? (
                  orderList.list.map((value, index) => {
                    return (
                      <tr
                        className="cursorPointer"
                        key={index}
                        onClick={() => {
                          if (value.orderType == "Order") {
                            navigate("/order/" + value.id);
                          }
                        }}
                      >
                        <td>
                          {moment(new Date(value.orderDate)).format(
                            "MM/DD/yyyy"
                          )}
                        </td>
                        <td>{value.orderNumber}</td>
                        <td
                          onClick={(event) => {
                            event.stopPropagation();
                            navigate(
                              "/ordertemplate/" + value.ordersTemplateId
                            );
                          }}
                        >
                          <span className="orderTemplateNumber">
                            {value.ordersTemplateId}
                          </span>
                        </td>
                        <td>{value.orderStatusForFrontend}</td>
                        <td>
                          {value.dateTimeOfTransaction != null
                            ? moment(
                                new Date(value.dateTimeOfTransaction)
                              ).format("MM/DD/yyyy")
                            : "N/A"}
                        </td>
                        {/* <td>{"-"}</td> */}
                        <td>
                          {value.reportStatus != null
                            ? value.reportStatus == "IP"
                              ? "In Process"
                              : value.reportStatus == "CM"
                              ? "Final"
                              : value.reportStatus
                            : value.laboratory != null
                            ? value.laboratory
                            : ""}
                        </td>
                        <td width={"100px"}>
                          {(value.orderStatus == "Sent" ||
                            value.orderStatus == "Printed") && (
                            <BsPrinter
                              title="View Requisition"
                              color="#398fda"
                              className="pl-l"
                              size={20}
                              onClick={(e) => {
                                e.stopPropagation();
                                setLoader(true);
                                setOrderNumberState(value.orderNumber);
                                printOrderReport(value.orderNumber);
                                setLabelCopies(1);
                                // setLoader(true);
                                // downloadFile(
                                //   "downloadFile/" +
                                //     value.results[0].resultCommonOrderId
                                // ).then((val) => setLoader(false));
                              }}
                            />
                          )}
                          {value.embeddedPdf != null && (
                            <BsPrinter
                              title="View Result"
                              size={20}
                              className="pl-r"
                              onClick={(e) => {
                                e.stopPropagation();
                                setLoader(true);
                                downloadFile(
                                  "downloadFile/" + value.resultCommonOrderId
                                ).then((val) => setLoader(false));
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>{"No Orders Found"}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <ReactPaginate
              forcePage={currentPage}
              className="pagination"
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={totalRecords}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              activeClassName="active"
              pageClassName="link"
            />

            <>
              <span>showing</span>
              <Form.Select
                value={totalRecordPerPage}
                className={"form-select w-auto d-inline ml-1 mr-1 form-control"}
                onChange={(e) => {
                  setTotalRecordPerPage(e.target.value);
                  handleSearchFilterChange(
                    "totalRecordPerPage",
                    e.target.value
                  );
                  handleSearchFilterChange("pageNumber", 1);
                  setCurrentPage(0);
                  setPagenationState(Math.random());
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </Form.Select>
              <span>per page</span>
            </>
          </div>
        </Container>
      </div>

      <CustomFooter showOrderFooterBar={false} />
      <div
        class="modal fade requisitionPreview"
        id="PrintPreviewDialogInOrderHistory"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="PrintPreviewDialogInOrderHistory"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title " id="PrintPreviewDialogInOrderHistory">
                {orderPdfDetail}
              </h5>
              <button
                onClick={() => {
                  // if (props.orderData.status != "Sent") {
                  //   navigate("/orders/" + props.clinicalInputs.patientId);
                  // }
                }}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Container>
                <Row>
                  <Col md={{ span: 9 }}>
                    <iframe
                      className="requisitionPreviewIframe"
                      src={pdfBlob}
                      frameBorder="0"
                      scrolling="auto"
                      width="100%"
                    ></iframe>
                    <Row>
                      <Col className="mt-2 d-flex justify-content-end  align-items-center">
                        Labels to Print on Requisition:
                        <Form.Select
                          disabled={printing}
                          className="w-auto ml-1"
                          name="status"
                          onChange={async (e) => {
                            setLablesCountInPdf(e.target.value);
                          }}
                          value={lablesCountInPdf}
                        >
                          <option value={8}>8</option>
                          <option value={16}>16</option>
                          <option value={24}>24</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={{ span: 3 }}>
                    <iframe
                      id="label1"
                      className="labelDiv"
                      title="Label"
                      allowtransparency={true}
                      src={
                        pdflabel + "#zoom=120%&scrollbar=0&toolbar=0&navpanes=0"
                      }
                      height={"159px"}
                      width={"88%"}
                      type="application/pdf"
                    />
                    <div className="d-flex justify-content-start  align-items-center pr-3">
                      <span>
                        Label Copies:
                        <input
                          min={1}
                          onKeyDown={(e) => {
                            if (e.target.value < 1) {
                              setLabelCopies(1);
                            }
                          }}
                          onKeyUp={(e) => {
                            if (e.target.value < 1) {
                              setLabelCopies(1);
                            }
                          }}
                          value={labelCopies}
                          type="number"
                          className="labelCopies ml-1 form-control labelCopiesInput"
                          onChange={(e) => {
                            setLabelCopies(e.target.value);
                          }}
                        ></input>
                      </span>
                      <a
                        style={{ color: "white" }}
                        className="primary button btn btn-primary mr-4"
                        disabled={labelCopies > 0 ? false : true}
                        onClick={() => {
                          ApiGetCalls(
                            "GenerateLabelString/" +
                              orderNumberState +
                              "/" +
                              labelCopies
                          ).then((value) => {
                            if (value["status"] == "Success") {
                              window.open(value["data"], "_self");
                            } else {
                              snackBar("Error! " + value["message"], "danger");
                            }
                          });
                        }}
                        // href={
                        //   props.patientDetails != undefined
                        //     ? "Atvivo:" +
                        //       props.patientDetails["patientName"] +
                        //       "&" +
                        //       props.tmpOrderNumber +
                        //       "&" +
                        //       props.patientDetails["patientDob"].replaceAll(
                        //         "-",
                        //         "/"
                        //       ) +
                        //       "&" +
                        //       moment(
                        //         new Date(props.orderData["collectionDate"])
                        //       ).format("MM/DD/yyyy hh:mm A") +
                        //       "&" +
                        //       labelCopies
                        //     : ""
                        // }
                      >
                        {"Print"}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="outline button btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  // if (props.orderData.status != "Sent") {
                  //   navigate("/orders/" + props.clinicalInputs.patientId);
                  // }
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="ResultPreviewModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content" style={{ height: "600px" }}>
            <div class="modal-header">
              <h5 class="modal-title " id="exampleModalLabel">
                {"Result"}
              </h5>
              <button
                onClick={() => {}}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Container>
                <iframe
                  id="resultPreviewBlob"
                  frameBorder="0"
                  scrolling="auto"
                  height="400px"
                  width="100%"
                ></iframe>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="outline button btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {}}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Orders;
