import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { snackBar } from "../services/Alert";
import $ from "jquery";
import { handleValidation, removeErrorForDivs } from "./ValidatorAndSubmitForm";
import { ApiGetCalls, ApiPostCalls, downloadFile } from "./../services/Api";
import WarningModel from "./WarningModel";
import { apiUrl, standingOrderIdConfig } from "../config/config";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { BsChevronLeft } from "react-icons/bs";
import { BsChevronRight } from "react-icons/bs";
import dateFormat from "dateformat";
import moment from "moment";
import { Document, Page } from "react-pdf";
import CustomLoader from "./Loader";

function CustomFooter(props) {
  const [showLoader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [cancelTemplateModalShow, setCancelTemplateModalShow] = useState(false);
  const [printing, setPrintng] = useState(false);
  const [MNdiagnosis, setMNdiagnosis] = useState([]);
  const [pdfBlob, setPdfBlob] = useState("");
  const [pdflabel, setPdflabel] = useState("");
  const [orderPdfDetail, setOrderPdfDetail] = useState("");
  const [labelCopies, setLabelCopies] = useState(1);
  const [lablesCountInPdf, setLablesCountInPdf] = useState(null);
  const [orderNumbersArray, setOrderNumbersArray] = useState([]);
  $(document).ready(function () {
    $(document).on("scroll", function () {
      var insurance_co_position = $(".InsuranceCompanies").position();
      var scroll_position = $(window).scrollTop();
      var v_port_height = $(window).height();
      if (insurance_co_position) {
        if (insurance_co_position.top - scroll_position >= v_port_height / 2) {
          $(".InsuranceCompanies").addClass("close_to_bottom");
        } else {
          $(".InsuranceCompanies").removeClass("close_to_bottom");
        }
      }
    });
  });
  var footerValue = "Draw Now";
  var footerBillType = "";
  const navigate = useNavigate();

  async function printOrderReport(orderNumbersArrayLocal) {
    if (printing) {
      return;
    }
    setPrintng(true);
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Response-Type": "blob",
      },
      // body: JSON.stringify({
      //   orderNumber: "A000071",
      // }),
    };
    const response = await fetch(
      apiUrl + "GenerateOrderLabel/" + orderNumbersArrayLocal[0],
      requestOptions
    )
      .then(async (response) => {
        if (response.status.toString() == "200") {
          return response.blob();
        } else {
          if (response.status.toString() == "401") {
            const accessToken = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            const refreshPayload = JSON.stringify({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });

            var refreshTokenResponse = await ApiPostCalls(
              refreshPayload,
              "refresh-token"
            );
            localStorage.setItem("token", refreshTokenResponse["accessToken"]);
            localStorage.setItem(
              "refreshToken",
              refreshTokenResponse["refreshToken"]
            );
            printOrderReport(orderNumbersArrayLocal);
            setLabelCopies(1);
          }
          return false;
        }
      })
      .then(async (blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          setPdflabel(base64data);
        };
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Response-Type": "blob",
          },
          body: JSON.stringify({
            orderNumbers: orderNumbersArrayLocal,
            lablesCount: lablesCountInPdf != null ? lablesCountInPdf : 8,
          }),
        };
        const response = await fetch(
          apiUrl + "GenerateOrderAndForeignOrdersPdf",
          requestOptions
        )
          .then((response) => response.blob())
          .then((blob) => {
            // var blob = response.pdf.blob();
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              setPdfBlob(base64data);
              setOrderPdfDetail(
                orderNumbersArrayLocal[0] + "- Requisition Report"
              );
              var a = document.createElement("a");
              a.setAttribute("data-bs-toggle", "modal");
              a.setAttribute("data-bs-target", "#PrintPreviewDialog");
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();
              setPrintng(false);
              setLoader(false);
            };
          });
      });
  }
  useEffect(() => {
    if (lablesCountInPdf != null) {
      setPrintng(true);
      setLoader(true);
      async function printOrderReportOnLablesCount(orderNumbersArrayLocal) {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Response-Type": "blob",
          },
          body: JSON.stringify({
            orderNumbers: orderNumbersArrayLocal,
            lablesCount: lablesCountInPdf != null ? lablesCountInPdf : 8,
          }),
        };

        var response = fetch(
          apiUrl + "GenerateOrderAndForeignOrdersPdf",
          requestOptions
        )
          .then(async (response) => {
            if (response.status.toString() == "200") {
              return response.blob();
            } else {
              if (response.status.toString() == "401") {
                const accessToken = localStorage.getItem("token");
                const refreshToken = localStorage.getItem("refreshToken");
                const refreshPayload = JSON.stringify({
                  accessToken: accessToken,
                  refreshToken: refreshToken,
                });

                var refreshTokenResponse = await ApiPostCalls(
                  refreshPayload,
                  "refresh-token"
                );
                localStorage.setItem(
                  "token",
                  refreshTokenResponse["accessToken"]
                );
                localStorage.setItem(
                  "refreshToken",
                  refreshTokenResponse["refreshToken"]
                );
                printOrderReportOnLablesCount(orderNumbersArray);
              }
              return false;
            }
          })
          .then((blob) => {
            // var blob = response.pdf.blob();
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              setPdfBlob(base64data);
              setOrderPdfDetail(
                orderNumbersArrayLocal[0] + "- Requisition Report"
              );
              setPrintng(false);
              setLoader(false);
            };
          });
      }
      printOrderReportOnLablesCount(orderNumbersArray);
    }
  }, [lablesCountInPdf]);
  useEffect(() => {
    setOrderNumbersArray([
      props.showOrderFooterBar
        ? props.orderData.orderType != standingOrderIdConfig
          ? props.orderData.id != undefined
            ? props.orderData.orderNumber
            : props.tmpOrderNumber
          : 0
        : 0,
    ]);
  }, [props.orderData]);
  return (
    <>
      {showLoader && <CustomLoader />}
      {props.showOrderFooterBar ? (
        <div className="draftbar">
          <Container>
            <span>
              {props.orderData.id != undefined ? (
                <b>Order</b>
              ) : (
                <b>New Order</b>
              )}{" "}
              {props.orderData.orderType != standingOrderIdConfig
                ? props.orderData.id != undefined
                  ? props.orderData.orderNumber
                  : props.tmpOrderNumber
                : ""}{" "}
              -{" "}
              {props.lookOrderInfo.orderingType?.map((value, index) => {
                if (props.clinicalInputs.orderType != undefined) {
                  if (value.id == props.clinicalInputs.orderType) {
                    footerValue = value.options;
                  }
                }
              })}
              {props.lookOrderInfo.billType?.map((value, index) => {
                if (props.clinicalInputs.billType != undefined) {
                  if (value.id == props.clinicalInputs.billType) {
                    footerBillType = " - " + value.options;
                  }
                }
              })}
              {footerValue} {footerBillType}
            </span>
            {(props.orderData.status == "Draft" ||
              props.orderData.status == undefined) && (
              <div>
                {props.clinicalInputs.orderType == "38" ? (
                  <button
                    onClick={async () => {
                      setLoader(true);
                      removeErrorForDivs("OrderInformation");
                      removeErrorForDivs("clinicalAccordion");
                      var validationOrderInfo =
                        handleValidation("OrderInformation");
                      var validation = handleValidation("clinicalAccordion");

                      if (validation && validationOrderInfo) {
                        props.clinicalInputs["orderNumber"] =
                          props.tmpOrderNumber;
                        props.clinicalInputs["facilityId"] = props.facilityId;
                        props.clinicalInputs["status"] = "Sent";
                        props.clinicalInputs["orderDateTimeZone"] =
                          Intl.DateTimeFormat().resolvedOptions().timeZone;
                        props.clinicalInputs["collectionDateTimeZone"] =
                          Intl.DateTimeFormat().resolvedOptions().timeZone;
                        props.clinicalInputs?.orderCopyToProviders?.map(
                          (value, index) => {
                            delete value.name;
                            delete value.facilityCopyToProviders;
                            delete value.createdAt;
                            delete value.updatedAt;
                            value.facilityCopyToId = value.facilityCopyToId * 1;
                            if (props.clinicalInputs.id != undefined) {
                              value.orderId = props.clinicalInputs.id;
                            }
                          }
                        );
                        var jsonParsed = {
                          orders: props.clinicalInputs,
                          mnCheck: true,
                        };

                        const response = await ApiPostCalls(
                          JSON.stringify(jsonParsed),
                          props.clinicalInputs.id != undefined
                            ? "updateOrder"
                            : "createOrder"
                        );
                        if (response["status"] === "Success") {
                          var orderNumbersArrayFromResponse = [];
                          if (response.data.order != null) {
                            if (response.data.foreignOrder != null) {
                              snackBar(
                                "Success! Foreign Order and Regular Order data saved successfully",
                                "success"
                              );
                              orderNumbersArrayFromResponse = [
                                response.data.order.orderNumber,
                                response.data.foreignOrder.orderNumber,
                              ];
                              // await setOrderNumbersArray([
                              //   response.data.order.orderNumber,
                              //   response.data.foreignOrder.orderNumber,
                              // ]);
                            } else {
                              snackBar(
                                "Success! Order data saved successfully",
                                "success"
                              );
                              orderNumbersArrayFromResponse = [
                                response.data.order.orderNumber,
                              ];
                              // await setOrderNumbersArray([
                              //   response.data.order.orderNumber,
                              // ]);
                            }
                            setLoader(true);
                            //printOrderReport(props.tmpOrderNumber);
                            // props.setSavedOrderId(response.data.id);
                            // setLatestOrderNumberForRequisition(
                            //   response.data.order.orderNumber
                            // );
                            setOrderNumbersArray(orderNumbersArrayFromResponse);
                            printOrderReport(orderNumbersArrayFromResponse);
                            setLabelCopies(1);
                          } else {
                            setLoader(true);
                            // navigate(
                            //   "/orders/" + response.data.foreignOrder.patientId
                            // );
                            snackBar(
                              "Success! Foreign Order data saved successfully",
                              "success"
                            );
                            orderNumbersArrayFromResponse = [
                              response.data.foreignOrder.orderNumber,
                            ];
                            // await setOrderNumbersArray([
                            //   response.data.foreignOrder.orderNumber,
                            // ]);
                            setOrderNumbersArray(orderNumbersArrayFromResponse);
                            printOrderReport(orderNumbersArrayFromResponse);
                            setLabelCopies(1);
                          }
                          // navigate(0);
                        } else {
                          if (
                            response["message"] == "Medical necessity not met!"
                          ) {
                            setLoader(false);
                            if (response["data"]) {
                              const diagnosisArray = [];
                              const testsCodeArray = [];

                              response["data"].map((data, index) => {
                                testsCodeArray.push(data.testCode);
                              });
                              // Object.keys(response["data"]).map(
                              //   (value, index) => {
                              //     testsCodeArray.push(value.split(":")[0]);
                              //   }
                              // );
                              props.setHighLightTests(testsCodeArray);
                              props.setMedicalNecessityResponse([
                                response["data"],
                              ]);
                              // setMNdiagnosis(Object.values(response["data"]));
                              setMNdiagnosis(response["data"]);
                              setModalShow(true);
                            }
                          } else {
                            snackBar(
                              "Error! Could not save order data",
                              "danger"
                            );
                            setLoader(false);
                          }
                        }
                      } else {
                        snackBar(
                          "Error! Could not save order, please fix the indicated errors.",
                          "danger"
                        );
                        setLoader(false);
                      }
                    }}
                    className="primary btn button"
                    disabled={
                      !props.patientInUpdateMode &&
                      props.clinicalInputs["patientId"] != undefined &&
                      props.clinicalInputs["patientId"] != "" &&
                      props.clinicalInputs.orderTests?.length > 0 &&
                      props.clinicalInputs.orderTests?.length != undefined
                        ? printing
                          ? true
                          : false
                        : true
                    }
                  >
                    Place Order
                  </button>
                ) : props.clinicalInputs.orderType ==
                  standingOrderIdConfig.toString() ? (
                  <button
                    onClick={async () => {
                      // props.clinicalInputs["orderNumber"] =
                      //   props.tmpOrderNumber;
                      props.clinicalInputs["facilityId"] = props.facilityId;
                      props.clinicalInputs["status"] = "Draft";
                      props.clinicalInputs["orderDateTimeZone"] =
                        Intl.DateTimeFormat().resolvedOptions().timeZone;
                      props.clinicalInputs["collectionDateTimeZone"] =
                        Intl.DateTimeFormat().resolvedOptions().timeZone;
                      props.clinicalInputs["collectionDate"] = null;
                      props.clinicalInputs?.orderCopyToProviders?.map(
                        (value, index) => {
                          delete value.name;
                          delete value.facilityCopyToProviders;
                          delete value.createdAt;
                          delete value.updatedAt;
                          value.facilityCopyToId = value.facilityCopyToId * 1;
                          if (props.clinicalInputs.id != undefined) {
                            value.orderId = props.clinicalInputs.id;
                          }
                        }
                      );
                      props.standingOrderSchedule.orderDates =
                        props.standingOrderDates;
                      var url = window.location.href.split("#")[1];
                      var urlArray = url.split("/");
                      if (urlArray[1] == "ordertemplate") {
                        var jsonParsed = {
                          orders: props.clinicalInputs,
                          schedule: props.standingOrderSchedule,
                          templateId: urlArray[2],
                        };
                      } else {
                        var jsonParsed = {
                          orders: props.clinicalInputs,
                          schedule: props.standingOrderSchedule,
                        };
                      }

                      const response = await ApiPostCalls(
                        JSON.stringify(jsonParsed),
                        urlArray[1] != "ordertemplate"
                          ? "createStandingOrder"
                          : "updateStandingOrder"
                      );
                      if (response["status"] === "Success") {
                        snackBar(
                          "Success! Order data saved successfully",
                          "success"
                        );
                        navigate(
                          "/orders/" + props.clinicalInputs["patientId"]
                        );

                        // navigate(0);
                      } else {
                        if (
                          response["message"] == "Medical necessity not met!"
                        ) {
                          setModalShow(true);
                        } else {
                          snackBar(
                            "Error! Could not save order data",
                            "danger"
                          );
                        }
                      }
                    }}
                    className="primary btn button"
                    disabled={
                      !props.patientInUpdateMode &&
                      props.clinicalInputs["patientId"] != undefined &&
                      props.clinicalInputs["patientId"] != "" &&
                      props.clinicalInputs.orderTests?.length > 0 &&
                      props.clinicalInputs.orderTests?.length != undefined &&
                      props.standingOrderDates.length > 0
                        ? false
                        : true
                    }
                  >
                    Save Template
                  </button>
                ) : (
                  <button
                    onClick={async () => {
                      props.clinicalInputs["orderNumber"] =
                        props.tmpOrderNumber;
                      props.clinicalInputs["facilityId"] = props.facilityId;
                      props.clinicalInputs["status"] = "Draft";
                      props.clinicalInputs["orderDateTimeZone"] =
                        Intl.DateTimeFormat().resolvedOptions().timeZone;
                      props.clinicalInputs["collectionDateTimeZone"] =
                        Intl.DateTimeFormat().resolvedOptions().timeZone;
                      props.clinicalInputs?.orderCopyToProviders?.map(
                        (value, index) => {
                          delete value.name;
                          delete value.facilityCopyToProviders;
                          delete value.createdAt;
                          delete value.updatedAt;
                          value.facilityCopyToId = value.facilityCopyToId * 1;
                          if (props.clinicalInputs.id != undefined) {
                            value.orderId = props.clinicalInputs.id;
                          }
                        }
                      );
                      var jsonParsed = {
                        orders: props.clinicalInputs,
                        mnCheck: false,
                      };
                      const response = await ApiPostCalls(
                        JSON.stringify(jsonParsed),
                        props.clinicalInputs.id != undefined
                          ? "updateOrder"
                          : "createOrder"
                      );
                      if (response["status"] === "Success") {
                        snackBar(
                          "Success! Order data saved successfully",
                          "success"
                        );
                        navigate("/order/" + response.data.order.id);
                        props.setSavedOrderId(response.data.order.id);
                        // navigate(0);
                      } else {
                        if (
                          response["message"] == "Medical necessity not met!"
                        ) {
                          setModalShow(true);
                        } else {
                          snackBar(
                            "Error! Could not save order data",
                            "danger"
                          );
                        }
                      }
                    }}
                    className="primary btn button"
                    disabled={
                      !props.patientInUpdateMode &&
                      props.clinicalInputs["patientId"] != undefined &&
                      props.clinicalInputs["patientId"] != "" &&
                      props.clinicalInputs.orderTests?.length > 0 &&
                      props.clinicalInputs.orderTests?.length != undefined
                        ? false
                        : true
                    }
                  >
                    Save Draft
                  </button>
                )}
                {props.clinicalInputs.orderType ==
                standingOrderIdConfig.toString() ? (
                  <button
                    className="outline btn button"
                    disabled={printing}
                    onClick={async () => {
                      setCancelTemplateModalShow(true);
                    }}
                  >
                    Delete Template
                  </button>
                ) : (
                  <button
                    className="outline btn button"
                    disabled={printing}
                    onClick={async () => {
                      setCancelModalShow(true);
                    }}
                  >
                    Delete Order
                  </button>
                )}
              </div>
            )}
            {props.orderData.status == "Sent" && (
              <button
                className="primary btn button ml-2"
                id="printOrderReport"
                disabled={printing}
                onClick={async () => {
                  setLoader(true);
                  printOrderReport(orderNumbersArray);
                  setLabelCopies(1);
                }}
              >
                {printing ? "Printing" : "Print"}
              </button>
            )}
            <button
              className="outline btn button ml-2"
              disabled={printing}
              onClick={async () => {
                if (
                  props.selectedOrderPatientId == "" ||
                  props.selectedOrderPatientId == 0
                ) {
                  navigate("/patients");
                } else {
                  navigate("/orders/" + props.selectedOrderPatientId);
                }
                // navigate(window.location.pathname);
                // setCancelModalShow(true);
              }}
            >
              Close
            </button>
          </Container>
          <Modal
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setTimeout(() => {
                document
                  .getElementById("selectedTestsAccordions")
                  .scrollIntoView({ behavior: "smooth", block: "center" });
              }, 300);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Confirmation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="suggestedDiagnosisModal">
              <p>
                The following tests do not meet the medical necessity
                requirements.
                {/* Order does not match medical necessity requirements. Do you want
                to proceed ? */}
              </p>
              {/* {MNdiagnosis.length != 0 && <h5>Suggested ICD Codes</h5>} */}
              {MNdiagnosis.length != 0 && (
                <table className="MNDialogBox table">
                  <tr>
                    <th>Test Codes</th>
                    <th>CPT Codes</th>
                  </tr>
                  {MNdiagnosis.map((data, index) => (
                    <tr key={index}>
                      <td>
                        {data.testCode +
                          ": " +
                          data.testsWithStatus[0].testName}
                      </td>
                      <td>
                        {data.testsWithStatus.map(
                          (test, index) =>
                            test.cptCode +
                            (index < data.testsWithStatus.length - 1
                              ? ", "
                              : "")
                        )}
                      </td>
                    </tr>
                  ))}
                  {/* {Object.keys(MNdiagnosis).map((val2, ind) => (
                    <tr>
                      {Object.keys(MNdiagnosis[val2]).length != 1 ? (
                        <td>{val2}</td>
                      ) : (
                        <td>{val2}</td>
                      )}

                      {
                        
                        Object.keys(MNdiagnosis[val2]).length != 1 ? (
                          <>
                            
                            <td>
                              {" "}
                              {Object.keys(MNdiagnosis[val2]).map(
                                (code, index) => (
                                  <Row className="MNDialogBoxRows">
                                    <Col>{code}</Col>
                                  </Row>
                                )
                              )}
                            </td>
                            
                          </>
                        ) : (
                          Object.keys(MNdiagnosis[val2]).map((val, ind) => (
                            <>
                              <td>{Object.keys(MNdiagnosis[val2])[ind]}</td>
                              
                            </>
                          ))
                        )
                      }
                    </tr>
                  ))} */}
                </table>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="outline btn button"
                onClick={() => {
                  setModalShow(false);
                  setTimeout(() => {
                    document
                      .getElementById("selectedTestsAccordions")
                      .scrollIntoView({ behavior: "smooth", block: "center" });
                  }, 300);
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <WarningModel
            show={cancelModalShow}
            heading="Confirmation"
            bodytext="Are you sure you want to cancel this order?"
            onHide={() => setCancelModalShow(false)}
            onyes={async () => {
              if (props.clinicalInputs.id != undefined) {
                const response = await ApiGetCalls(
                  "ChangeOrderStatusToCancel/" +
                    props.clinicalInputs.id +
                    "/" +
                    props.facilityId
                );
                if (response["status"] === "Success") {
                  navigate("/patients");
                  snackBar("Success! Order cancelled successfully", "success");
                } else {
                  snackBar("Error! Could not cancel order", "danger");
                }
              } else {
                navigate("/patients");
              }
              setCancelModalShow(false);
            }}
          />
          <WarningModel
            show={cancelTemplateModalShow}
            heading="Confirmation"
            bodytext="This will cancel the template and all of its orders which have not been sent yet. Are you sure you want to continue?"
            onHide={() => setCancelTemplateModalShow(false)}
            onyes={async () => {
              var url = window.location.href.split("#")[1];
              var urlArray = url.split("/");
              if (urlArray[1] == "ordertemplate") {
                const response = await ApiGetCalls(
                  "ChangeOrdersTemplateStatusToCancel/" +
                    urlArray[2] +
                    "/" +
                    props.facilityId
                );
                if (response["status"] === "Success") {
                  navigate("/patients");
                  snackBar(
                    "Success! Orders Template cancelled successfully",
                    "success"
                  );
                } else {
                  snackBar("Error! Could not cancel orders", "danger");
                }
              } else {
                navigate("/patients");
              }
              setCancelTemplateModalShow(false);
            }}
          />
        </div>
      ) : (
        <></>
      )}

      <div
        class="modal fade requisitionPreview"
        id="PrintPreviewDialog"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title " id="exampleModalLabel">
                {orderPdfDetail}
              </h5>
              <button
                onClick={() => {
                  if (props.orderData.status != "Sent") {
                    navigate("/orders/" + props.clinicalInputs.patientId);
                  }
                }}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Container>
                <Row>
                  <Col md={{ span: 9 }}>
                    <iframe
                      className="requisitionPreviewIframe"
                      src={pdfBlob}
                      frameBorder="0"
                      scrolling="auto"
                      width="100%"
                    ></iframe>
                    <Row>
                      <Col className="mt-2 d-flex justify-content-end  align-items-center">
                        Labels to Print on Requisition:
                        <Form.Select
                          disabled={printing}
                          className="w-auto ml-1"
                          name="status"
                          onChange={async (e) => {
                            setLablesCountInPdf(e.target.value);
                          }}
                          value={lablesCountInPdf}
                        >
                          <option value={8}>8</option>
                          <option value={16}>16</option>
                          <option value={24}>24</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={{ span: 3 }}>
                    <iframe
                      id="label"
                      className="labelDiv"
                      title="Label"
                      allowtransparency={true}
                      src={
                        pdflabel + "#zoom=120%&scrollbar=0&toolbar=0&navpanes=0"
                      }
                      height={"159px"}
                      width={"88%"}
                      type="application/pdf"
                    />
                    <div className="d-flex justify-content-start  align-items-center pr-3">
                      <span>
                        Label Copies:
                        <input
                          min={1}
                          onKeyDown={(e) => {
                            if (e.target.value < 1) {
                              setLabelCopies(1);
                            }
                          }}
                          onKeyUp={(e) => {
                            if (e.target.value < 1) {
                              setLabelCopies(1);
                            }
                          }}
                          value={labelCopies}
                          type="number"
                          className="labelCopies ml-1 form-control labelCopiesInput"
                          onChange={(e) => {
                            setLabelCopies(e.target.value);
                          }}
                        ></input>
                      </span>
                      <a
                        style={{ color: "white" }}
                        className="primary button btn btn-primary mr-4"
                        disabled={labelCopies > 0 ? false : true}
                        onClick={() => {
                          ApiGetCalls(
                            "GenerateLabelString/" +
                              props.tmpOrderNumber +
                              "/" +
                              labelCopies
                          ).then((value) => {
                            if (value["status"] == "Success") {
                              window.open(value["data"], "_self");
                            } else {
                              snackBar("Error! " + value["message"], "danger");
                            }
                          });
                        }}
                        // href={
                        //   props.patientDetails != undefined
                        //     ? "Atvivo:" +
                        //       props.patientDetails["patientName"] +
                        //       "&" +
                        //       props.tmpOrderNumber +
                        //       "&" +
                        //       props.patientDetails["patientDob"].replaceAll(
                        //         "-",
                        //         "/"
                        //       ) +
                        //       "&" +
                        //       moment(
                        //         new Date(props.orderData["collectionDate"])
                        //       ).format("MM/DD/yyyy hh:mm A") +
                        //       "&" +
                        //       labelCopies
                        //     : ""
                        // }
                      >
                        {"Print"}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="outline button btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  if (props.orderData.status != "Sent") {
                    navigate("/orders/" + props.clinicalInputs.patientId);
                  }
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {props.showResultInboxFooterBar && (
        <div className="draftbar">
          <Container>
            <div className="actionsbar d-flex justify-content-between">
              <div className="leftcontrolls">
                <Button
                  variant="outline"
                  className="outline btn button ml-auto"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              </div>

              <div className="centercontrolls">
                {props.resultId > 1 && (
                  <Button
                    onClick={() => {
                      props.setResultId(props.resultId - 1);
                      props.setCheckResultId(Math.random());
                    }}
                    variant="outline"
                    className="outline btn button mr-1"
                  >
                    <BsChevronLeft />
                  </Button>
                )}
                {"Result " + props.resultId + " of " + props.resultIds.length}
                {props.resultId < props.resultIds.length && (
                  <Button
                    onClick={() => {
                      props.setResultId(props.resultId + 1);
                      props.setCheckResultId(Math.random());
                    }}
                    variant="outline"
                    className="outline btn button ml-1"
                  >
                    <BsChevronRight />
                  </Button>
                )}
              </div>

              <div className="rightcontrolls">
                <Button
                  variant="primary"
                  className="primary btn button mr-1 ml-1"
                  onClick={() => {
                    setLoader(true);
                    downloadFile(
                      "downloadFile/" + props.resultIds[props.resultId - 1]
                    ).then((val) => setLoader(false));
                    ApiPostCalls(
                      JSON.stringify({
                        updateField: "IsPrinted",
                        updateFieldValue: "Y",
                        resultCommonOrderIds: [
                          props.resultIds[props.resultId - 1],
                        ],
                      }),

                      "updateResultValues"
                    );
                  }}
                  disabled={props.disabledPrintButton}
                >
                  Print
                </Button>
                <Form.Check
                  className="archiveCheckBox"
                  type="switch"
                  label="Archived"
                  name="archived"
                  checked={props.archiveCheck}
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.handleUpdateResultChange("resultCommonOrderIds", [
                        props.resultIds[props.resultId - 1],
                      ]);
                      props.handleUpdateResultChange("updateFieldValue", "Y");
                      props.handleUpdateResultChange(
                        "updateField",
                        "IsArchived"
                      );
                    } else {
                      props.handleUpdateResultChange("resultCommonOrderIds", [
                        props.resultIds[props.resultId - 1],
                      ]);
                      props.handleUpdateResultChange("updateFieldValue", "N");
                      props.handleUpdateResultChange(
                        "updateField",
                        "IsArchived"
                      );
                    }
                    props.setCancelModalShow(true);
                    props.setArchiveCheck(e.target.checked);
                  }}
                />
              </div>
            </div>
          </Container>
        </div>
      )}
      <footer>
        <Container>
          <Row>
            <Col>
              © {new Date().getFullYear()}, ATVIVO Laboratory. All Rights
              Reserved.
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default CustomFooter;
