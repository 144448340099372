import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { parseJSON } from "jquery";

function FormRadio(props) {
  const [selectedCheckBoxArray, setSelectedCheckBoxArray] = useState([]);
  const checkBoxesArray = [];
  var radioValue = "";
  if (props.anwserType == "CHECKBOX") {
    var array = props.orderData?.aoeAnswers?.find((item, index) =>
      item.questionId == props.questionId.id ? item.answerId : 0
    );
  } else {
    radioValue = props.orderData?.aoeAnswers?.find((item, index) =>
      item.questionId == props.questionId.id ? item.answerId : 0
    );
  }
  useEffect(() => {
    if (props.anwserType == "CHECKBOX" && props.defaultValue != undefined) {
      var parseData = JSON.parse(props.defaultValue?.answerValue);
      parseData.map((value, index) => {
        checkBoxesArray.push(value);
      });
    }
    setSelectedCheckBoxArray(checkBoxesArray);
  }, [props.defaultValue]);
  return (
    // <Form.Group className="mb-3" controlId="formBasicEmail">
    //   <Form.Label>{props.formValue}</Form.Label>
    //   <Form.Select aria-label="Default select example">
    //     <option>Open this select menu</option>
    //     {props.optionValues?.map((ansValue, ansIndex) => (
    //       <option value={ansValue.Id}>{ansValue.Answer}</option>
    //     ))}
    //   </Form.Select>
    // </Form.Group>

    <Form.Group className="mb-3" controlId="formBasicEmail">
      {/* <Form.Label className={props.className + "Label"}>
        {props.formValue}
      </Form.Label> */}

      <h5 className={props.className + "Label label-style"}>
        {props.formValue}
      </h5>
      {props.radioValues?.map((ansValue, ansIndex) => (
        <Form.Check
          disabled={props.disabled}
          key={ansIndex}
          required={props.required}
          className={props.className}
          name={props.name}
          type={props.type}
          label={ansValue.answer}
          id={ansValue.id}
          defaultChecked={
            props.anwserType == "CHECKBOX"
              ? props.defaultValue?.answerValue != undefined
                ? JSON.parse(props.defaultValue?.answerValue).some(
                    (value) => value == ansValue.id
                  )
                : false
              : props.defaultValue?.answerId != undefined
              ? ansValue.id == props.defaultValue?.answerId
              : ansValue.id == props.defaultCheckedValue
          }
          value={ansValue.id}
          onChange={(e) => {
            if (e.target.checked) {
              // if (
              //   props.anwserType == "CHECKBOX" &&
              //   props.defaultValue != undefined
              // ) {
              // //   var parseData = JSON.parse(props.defaultValue?.answerValue);
              // //   parseData.map((value, index) => {
              // //     checkBoxesArray.push(value);
              // //   });
              // }
              setSelectedCheckBoxArray(
                selectedCheckBoxArray.push(e.target.value)
              );
              props.onChange(
                props.anwserType == "CHECKBOX"
                  ? props.orderId != ""
                    ? {
                        orderId: props.orderId,
                        testId: props.testId,
                        questionId: props.questionId,
                        answerId: null,
                        answerValue: JSON.stringify(selectedCheckBoxArray),
                      }
                    : {
                        testId: props.testId,
                        questionId: props.questionId,
                        answerId: null,
                        answerValue: JSON.stringify(selectedCheckBoxArray),
                      }
                  : props.orderId != ""
                  ? {
                      orderId: props.orderId,
                      testId: props.testId,
                      questionId: props.questionId,
                      answerId: e.target.value * 1,
                      answerValue: null,
                    }
                  : {
                      testId: props.testId,
                      questionId: props.questionId,
                      answerId: e.target.id * 1,
                      answerValue: null,
                    }
              );
            } else {
              setSelectedCheckBoxArray(
                selectedCheckBoxArray.filter((x) => e.target.value != x)
              );
              if (props.anwserType == "CHECKBOX") {
                props.onChange(
                  props.orderId != ""
                    ? {
                        orderId: props.orderId,
                        testId: props.testId,
                        questionId: props.questionId,
                        answerId: null,
                        answerValue: JSON.stringify(
                          selectedCheckBoxArray.filter(
                            (x) => e.target.value != x
                          )
                        ),
                      }
                    : {
                        testId: props.testId,
                        questionId: props.questionId,
                        answerId: null,
                        answerValue: JSON.stringify(
                          selectedCheckBoxArray.filter(
                            (x) => e.target.value != x
                          )
                        ),
                      }
                );
              }
            }
          }}
        />
      ))}
    </Form.Group>
  );
}

export default FormRadio;
