import moment from "moment/moment";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-text-mask";

function DatePickerComponent(props) {
  const [startDate, setStartDate] = useState("");
  useEffect(() => {
    if (props.defaultValue != undefined) {
      setStartDate(new Date(props.defaultValue));
      props.onChange(
        props.name,
        moment(new Date(props.defaultValue)).format("yyyy/MM/DD HH:mm:ss")
      );
    } else {
      setStartDate(new Date());
      props.onChange(
        props.name,
        moment(new Date()).format("yyyy/MM/DD HH:mm:ss")
      );
    }
  }, [props.defaultValue]);
  useEffect(() => {
    if (props.orderStatus != undefined || props.orderStatus == "Draft") {
      if (
        props.name == "collectionDate" &&
        props.defaultValue == undefined &&
        props.orderStatus != 38
      ) {
        setStartDate(null);
        props.onChange(props.name, null);
      } else {
        if (props.name == "collectionDate" && props.defaultValue == undefined) {
          setStartDate(new Date());
          props.onChange(
            props.name,
            moment(new Date()).format("yyyy/MM/DD HH:mm:ss")
          );
        }
      }
    }
  }, [props.orderStatus]);
  useEffect(() => {
    if (props.defaultValue2 != "orderInfo") {
      if (props.defaultValue2?.answerValue != undefined) {
        setStartDate(new Date(props.defaultValue2?.answerValue));
      } else {
        setStartDate("");
      }
    }
  }, [props.defaultValue2]);
  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      {/* <Form.Label>{props.formValue}</Form.Label> */}
      <h5 className="label-style">{props.formValue}</h5>
      <DatePicker
        id={props.id}
        preventOpenOnFocus={true}
        // name={props.name}
        disabled={props.disabled}
        ariaLabelledBy={props.formValue}
        name={props.formValue}
        className={props.className}
        dateFormat={props.dateFormat}
        minDate={new Date()}
        selected={startDate}
        showTimeSelect={props.showTimeSelect}
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        onChange={(date) => {
          setStartDate(date);
          props.onChange(
            props.name,
            moment(date).format("yyyy-MM-DD HH:mm:ss")
          );

          props.onChangeFunction(
            props.orderId != ""
              ? {
                  orderId: props.orderId,
                  testId: props.testId,
                  questionId: props.questionId,
                  answerId: null,
                  answerValue: moment(date).format("yyyy-MM-DD HH:mm"),
                }
              : {
                  testId: props.testId,
                  questionId: props.questionId,
                  answerId: null,
                  answerValue: moment(date).format("yyyy-MM-DD HH:mm"),
                }
          );
        }}
        showMonthDropdown={true}
        showYearDropdown={true}
        dropdownMode="select"
        autoComplete="off"
        required={props.required}
        customInput={
          <MaskedInput
            mask={[
              /\d/,
              /\d/,
              "/",
              /\d/,
              /\d/,
              "/",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              ":",
              /\d/,
              /\d/,
            ]}
            placeholder={"mm/dd/yyyy HH:mm"}
            keepCharPositions={true}
            guide={true}
          />
        }
      />
    </Form.Group>
  );
}

export default DatePickerComponent;
