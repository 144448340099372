import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents/mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";
import {
  displayInDateFormat,
  LookupText,
} from "../../GlobalComponents/ValidatorAndSubmitForm";
import { ApiPostCalls, ApiGetCalls } from "../../services/Api";
import moment from "moment/moment";
import "../OrderPage/OrderPage.css";
import { LoginInfoContext, LoginUserLocationContext } from "../../Context";
import { Row, Col, Table, Form, Button, Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FaPencilAlt, FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import { MdOutlineNoteAdd } from "react-icons/md";
import ReactPaginate from "react-paginate";
import PasswordWarningModel from "../../GlobalComponents/PasswordWarningModel";
import { BsFileEarmarkMedical, BsPencil } from "react-icons/bs";
import MaskedInput from "react-text-mask";
import CustomLoader from "../../GlobalComponents/Loader";

function Patients(props) {
  const [showLoader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Patients";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    } else {
      if(localStorage.getItem("expiryPasswordDate") > 0)
      {
        let msg = '';
        if(localStorage.getItem("expiryPasswordDate") == 1)
        {
          msg = 'Your password will expire in 1 day. Would you like to update it now?';
        } else {
          msg = 'Your password will expire in '+localStorage.getItem("expiryPasswordDate")+' days. Would you like to update it now?';
        }
        
        setModalMessage(msg);
        setModalShow(true);
      }
      getSexData();
      // getAllPatients();
      props.setSelectedOrderPatientId(0);
    }
  }, []);
  const [context, setContext] = useContext(LoginInfoContext);

  const [showResults, setShowResults] = useState(false);
  const [sexLookup, setSexLookup] = useState({});
  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  const [totalRecordPerPage, setTotalRecordPerPage] = useState(10);
  const [advanceSeach, setAdvanceSeach] = useState(false);
  const [callAdvanceSeach, setCallAdvanceSeach] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagenationState, setPagenationState] = useState(-1);
  const handlePageClick = (event) => {
    setLoader(true);
    setCurrentPage(event.selected);
    const newOffset = (event.selected * 1) % 2;
    handlePatientJsonChange("pageNumber", event.selected + 1);
    setPagenationState(!pagenationState);
  };
  const handlePatientJsonChange = (name, value) => {
    setPatientsJson((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    if (pagenationState != -1) {
      if (advanceSeach) {
        fetchData();
      } else {
        getAllPatients();
      }
    }
  }, [pagenationState]);
  const clickHandle = (e) => {
    navigate("/orders");
  };

  const [searchGridVisible, setsearchGridVisible] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const [advancedSearchInputs, setadvancedSearchInputs] = useState({});
  const [advancedSearchGridData, setadvancedSearchGridData] = useState({});
  const [patientsJson, setPatientsJson] = useState({});
  const [advancedSearchSelectedObj, setadvancedSearchSelectedObj] = useState(
    {}
  );
  const [advancedSearchSelectedObjId, setadvancedSearchSelectedObjId] =
    useState("");

  const rowClickAdvancedSearch = (selectedPatientObj, id) => {
    setadvancedSearchSelectedObj(selectedPatientObj);
    setadvancedSearchSelectedObjId(id);
    navigate("/orders/" + id);
  };

  const handleAdvancedSearchInputsChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setadvancedSearchInputs((values) => ({ ...values, [name]: value }));
    setCurrentPage(0);
    handlePatientJsonChange("sortingField", "CreatedAt");
    handlePatientJsonChange("sortingType", "DESC");
    setCallAdvanceSeach(Math.random());
    setAdvanceSeach(true);
  };

  const handleDateAdvancedSearchChange = (date, DateFieldName) => {
    const name = DateFieldName;
    const value = date;
    setadvancedSearchInputs((values) => ({ ...values, [name]: value }));
    setCurrentPage(0);
    handlePatientJsonChange("sortingField", "CreatedAt");
    handlePatientJsonChange("sortingType", "DESC");
    setCallAdvanceSeach(Math.random());
    setAdvanceSeach(true);
  };

  function handleAdvancedSearchButtonClick() {
    fetchData();
  }

  async function handleAdvancedResetButtonClick() {
    setadvancedSearchInputs({});
    setadvancedSearchSelectedObjId("");
    setadvancedSearchGridData({});
    setsearchGridVisible(false);
    setPatientsJson({
      pageNumber: 1,
      totalRecordPerPage: totalRecordPerPage,
      facilityId: selectedLocation,
      sortingField: "CreatedAt",
      sortingType: "DESC",
    });
    setCurrentPage(0);
    setPagenationState(Math.random());
    // getAllPatients();
  }
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      if (
        !Object.values(advancedSearchInputs).every(
          (o) => o === "" || o === null
        )
      ) {
        setCurrentPage(0);
        handlePatientJsonChange("sortingField", "CreatedAt");
        handlePatientJsonChange("sortingType", "DESC");
        setCallAdvanceSeach(Math.random());
        setAdvanceSeach(true);
      }
    }
  };

  function AdvancedSearchDataGrid() {
    let ObjectHasSomeData = Object.keys(advancedSearchGridData);
    const advancedSearchDataArray = [];

    if (ObjectHasSomeData.length > 0) {
      for (let i = 0; i < ObjectHasSomeData.length; i++)
        advancedSearchDataArray.push(
          <>
            <Row
              //title="View Orders"
              key={i}
              className={
                advancedSearchGridData[i].id == advancedSearchSelectedObjId
                  ? "selected"
                  : ""
              }
              // onClick={(event) => {
              //   event.stopPropagation();
              //   rowClickAdvancedSearch(
              //     advancedSearchGridData[i],
              //     advancedSearchGridData[i].id
              //   );
              //   // navigate("/patient/" + advancedSearchGridData[i].id);
              // }}
            >
              <Col
                key={i + "col1"}
                //md={{ span: 3 }}
                // onClick={() => {
                //   rowClickAdvancedSearch(
                //     advancedSearchGridData[i],
                //     advancedSearchGridData[i].id
                //   );
                // }}
              >
                {advancedSearchGridData[i].lastName},{" "}
                {advancedSearchGridData[i].firstName}
              </Col>

              <Col
                key={i + "col2"}
                className="text-center"
                md={{ span: 2 }}
                // onClick={() => {
                //   rowClickAdvancedSearch(
                //     advancedSearchGridData[i],
                //     advancedSearchGridData[i].id
                //   );
                // }}
              >
                {advancedSearchGridData[i].phone != null
                  ? advancedSearchGridData[i].phone
                  : null}
              </Col>
              {/* <Col
                key={i + "col3"}
                className="text-center"
                // onClick={() => {
                //   rowClickAdvancedSearch(
                //     advancedSearchGridData[i],
                //     advancedSearchGridData[i].id
                //   );
                // }}
              >
                {advancedSearchGridData[i].order.length > 0
                  ? advancedSearchGridData[i].order[0].orderNumber
                  : null}
              </Col> */}
              <Col
                key={i + "col4"}
                // md={{ span: 1 }}
                className="text-center"
                // onClick={() => {
                //   rowClickAdvancedSearch(
                //     advancedSearchGridData[i],
                //     advancedSearchGridData[i].id
                //   );
                // }}
              >
                {advancedSearchGridData[i].mrn != null
                  ? advancedSearchGridData[i].mrn
                  : null}
              </Col>
              <Col
                key={i + "col5"}
                className="text-center"
                // onClick={() => {
                //   rowClickAdvancedSearch(
                //     advancedSearchGridData[i],
                //     advancedSearchGridData[i].id
                //   );
                // }}
              >
                {advancedSearchGridData[i].dob != null
                  ? displayInDateFormat(advancedSearchGridData[i].dob)
                  : null}
              </Col>
              <Col
                key={i + "col7"} //md={{ span: 1 }}
                className="text-center"
              >
                {advancedSearchGridData[i].facilityName != null
                  ? advancedSearchGridData[i].facilityName
                  : null}
              </Col>
              <Col
                key={i + "col6"}
                md={{ span: 1 }}
                className="text-center"
                // onClick={() => {
                //   rowClickAdvancedSearch(
                //     advancedSearchGridData[i],
                //     advancedSearchGridData[i].id
                //   );
                // }}
              >
                {advancedSearchGridData[i].sex != null
                  ? LookupText(lookupSexData, advancedSearchGridData[i].sex)
                  : null}
              </Col>

              <Col key={i + "col8"} className="text-center">
                <CgNotes
                  title="Order History"
                  className="mb-2 mr-1"
                  size={20}
                  onClick={(event) => {
                    event.stopPropagation();
                    rowClickAdvancedSearch(
                      advancedSearchGridData[i],
                      advancedSearchGridData[i].id
                    );
                    // navigate("/patient/" + advancedSearchGridData[i].id);
                  }}
                />
                <MdOutlineNoteAdd
                  title="Add Order"
                  className="mb-2 ml-2 mr-1"
                  size={24}
                  onClick={(event) => {
                    event.stopPropagation();
                    setadvancedSearchSelectedObj(advancedSearchGridData[i]);
                    setadvancedSearchSelectedObjId(
                      advancedSearchGridData[i].id
                    );
                    props.setSelectedOrderPatientId(
                      advancedSearchGridData[i].id
                    );
                    navigate("/order/new");
                    // rowClickAdvancedSearch(
                    //   advancedSearchGridData[i],
                    //   advancedSearchGridData[i].id
                    // );
                  }}
                />
                <BsPencil
                  title="Edit Patient"
                  className="mb-2 ml-2"
                  size={20}
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate("/patient/" + advancedSearchGridData[i].id);
                  }}
                />
              </Col>
            </Row>
          </>
        );
    } else
      advancedSearchDataArray.push(
        <Row key={"noRecord"}>
          <Col key={"noRecordCol"}>
            There are no records matching your criteria
          </Col>
        </Row>
      );
    return advancedSearchDataArray;
  }

  const fetchData = async () => {
    let jsonData = {
      lastName:
        advancedSearchInputs["advancedSearchLastName"] == null ||
        advancedSearchInputs["advancedSearchLastName"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchLastName"],
      firstName:
        advancedSearchInputs["advancedSearchFirstName"] == null ||
        advancedSearchInputs["advancedSearchFirstName"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchFirstName"],
      phone:
        advancedSearchInputs["advancedSearchPhone"] == null ||
        advancedSearchInputs["advancedSearchPhone"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchPhone"],
      orderNumber:
        advancedSearchInputs["advancedSearchOrderNumber"] == null ||
        advancedSearchInputs["advancedSearchOrderNumber"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchOrderNumber"],
      mrn:
        advancedSearchInputs["advancedSearchMrn"] == null ||
        advancedSearchInputs["advancedSearchMrn"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchMrn"],
      dob:
        advancedSearchInputs["advancedSearchDob"] == null ||
        advancedSearchInputs["advancedSearchDob"] == ""
          ? undefined
          : advancedSearchInputs["advancedSearchDob"],
      facilityId: selectedLocation,
      pageNumber: currentPage + 1,
      totalRecordPerPage: totalRecordPerPage,
      sortingField: patientsJson.sortingField,
      sortingType: patientsJson.sortingType,
    };
    setPagenationState(-1);
    // setCurrentPage(0);
    const data = await ApiPostCalls(
      JSON.stringify(jsonData),
      "AdvancePatientSearch"
    );
    if (data.patients.length > 0) {
      setTotalRecords(Math.ceil(data.totalRecords / totalRecordPerPage));
    } else {
      setTotalRecords(0);
    }
    setadvancedSearchGridData(data.patients);
    setsearchGridVisible(true);
    setLoader(false);
  };

  const [lookupSexData, setlookupSexData] = useState({});

  function getSexData() {
    ApiGetCalls("GetQuickLookupsByName/Sex").then((data) => {
      setlookupSexData(data);
    });
  }

  async function getAllPatients() {
    const response = await ApiPostCalls(
      JSON.stringify(patientsJson),

      "GetAllPatients"
    );
    if (response.length > 0) {
      setTotalRecords(Math.ceil(response[0].totalRecords / totalRecordPerPage));
    } else {
      setTotalRecords(0);
    }
    setadvancedSearchGridData(response);
    setsearchGridVisible(true);
    setLoader(false);
  }

  // getAllPatients();
  useEffect(() => {
    if (selectedLocation != "") {
      setCurrentPage(0);
      setPatientsJson({
        pageNumber: 1,
        totalRecordPerPage: totalRecordPerPage,
        facilityId: selectedLocation,
        sortingField: "CreatedAt",
        sortingType: "DESC",
      });
      setPagenationState(selectedLocation);
    }
  }, [selectedLocation]);
  useEffect(() => {
    if (callAdvanceSeach != "") {
      handleAdvancedSearchButtonClick();
    }
  }, [callAdvanceSeach]);

  const clickHandleAddNew = (e) => {
    navigate("/patient/new");
  };
  async function onConfirmationYes() {
    setModalShow(false);
    localStorage.setItem("expiryPasswordDate", 0);
    navigate("/changePassword");
  }
  async function onConfirmationNo() {
    setModalShow(false);
    localStorage.setItem("expiryPasswordDate", 0);
    //navigate("/changePassword");
  }
  return (
    <>
    <PasswordWarningModel
        show={modalShow}
        heading="Notification"
        bodytext={modalMessage}
        onHide={onConfirmationNo}
        onyes={onConfirmationYes}
      />
    <div onClick={() => setShowResults(false)}>
      {showLoader && <CustomLoader />}
      <CustomNavBar
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        sexLookup={sexLookup}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="patients-list-pageheight">
        <Container className="mb-5">
          <div className="partition">
            <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
              Patients
              <Button
                onClick={() => {
                  navigate("/orderManifest");
                }}
                variant="primary"
                className="primary btn button ml-auto"
              >
                Manifest
              </Button>
              <Button
                onClick={clickHandleAddNew}
                variant="primary"
                className="primary btn button ml-2"
              >
                Add New
              </Button>
            </h3>
            <h5>Search</h5>
            <hr></hr>

            <Container>
              <Row>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Last Name</h5>
                  <input
                    name="advancedSearchLastName"
                    type="text"
                    className="input-style form-control mb-3"
                    onChange={handleAdvancedSearchInputsChange}
                    value={
                      advancedSearchInputs["advancedSearchLastName"] !=
                      undefined
                        ? advancedSearchInputs["advancedSearchLastName"]
                        : ""
                    }
                    onKeyDown={handleKeypress}
                  />
                </Col>

                <Col md={{ span: 4 }}>
                  <h5 className="label-style">First Name</h5>
                  <input
                    name="advancedSearchFirstName"
                    type="text"
                    className="input-style form-control mb-3"
                    onChange={handleAdvancedSearchInputsChange}
                    value={
                      advancedSearchInputs["advancedSearchFirstName"] !=
                      undefined
                        ? advancedSearchInputs["advancedSearchFirstName"]
                        : ""
                    }
                    onKeyDown={handleKeypress}
                  />
                </Col>

                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Phone</h5>
                  <input
                    name="advancedSearchPhone"
                    type="text"
                    className="input-style form-control mb-3"
                    onChange={handleAdvancedSearchInputsChange}
                    value={
                      advancedSearchInputs["advancedSearchPhone"] != undefined
                        ? advancedSearchInputs["advancedSearchPhone"]
                        : ""
                    }
                    onKeyDown={handleKeypress}
                  />
                </Col>
              </Row>

              <Row>
                {/* <Col md={{ span: 4 }}>
                  <h5 className="label-style">Requisition #</h5>
                  <input
                    name="advancedSearchOrderNumber"
                    type="text"
                    className="input-style form-control mb-3"
                    onChange={handleAdvancedSearchInputsChange}
                    value={
                      advancedSearchInputs["advancedSearchOrderNumber"] !=
                      undefined
                        ? advancedSearchInputs["advancedSearchOrderNumber"]
                        : ""
                    }
                    onKeyDown={handleKeypress}
                  />
                </Col> */}

                <Col md={{ span: 4 }}>
                  <h5 className="label-style">MRN</h5>
                  <input
                    name="advancedSearchMrn"
                    type="text"
                    className="input-style form-control mb-3"
                    onChange={handleAdvancedSearchInputsChange}
                    value={
                      advancedSearchInputs["advancedSearchMrn"] != undefined
                        ? advancedSearchInputs["advancedSearchMrn"]
                        : ""
                    }
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Date of Birth</h5>
                  <DatePicker
                    preventOpenOnFocus={true}
                    name="advancedSearchDob"
                    ariaLabelledBy="Date of birth"
                    className="input-style form-control"
                    selected={
                      advancedSearchInputs["advancedSearchDob"] != undefined &&
                      advancedSearchInputs["advancedSearchDob"] != null
                        ? new Date(advancedSearchInputs["advancedSearchDob"])
                        : ""
                    }
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    onChange={(date) => {
                      if (date != null) {
                        handleDateAdvancedSearchChange(
                          moment(date).format("yyyy-MM-DDT00:00:00"),
                          "advancedSearchDob"
                        );
                      } else {
                        handleDateAdvancedSearchChange(
                          date,
                          "advancedSearchDob"
                        );
                      }
                    }}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dropdownMode="select"
                    autoComplete="off"
                    customInput={
                      <MaskedInput
                        mask={[
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        placeholder={"MM/dd/yyyy"}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onKeyDown={handleKeypress}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-row">
                  <Button
                    variant="primary"
                    disabled={Object.values(advancedSearchInputs).every(
                      (o) => o === "" || o === null
                    )}
                    onClick={() => {
                      setLoader(true);
                      setCurrentPage(0);
                      handlePatientJsonChange("sortingField", "CreatedAt");
                      handlePatientJsonChange("sortingType", "DESC");
                      setCallAdvanceSeach(Math.random());
                      setAdvanceSeach(true);
                    }}
                    className="primary btn button btn-outline  ml-auto mr-1"
                  >
                    Search
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setAdvanceSeach(false);
                      setCallAdvanceSeach("");
                      handleAdvancedResetButtonClick();
                    }}
                    className="outline button ml-2"
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
              <div
                className="patients-advance-search-results mt-3 widecolumns"
                style={{ display: searchGridVisible ? "block" : "none" }}
              >
                <Row className="DarkHeading sticky-top">
                  <Col
                    className="cursorPointer"
                    onClick={() => {
                      handlePatientJsonChange("sortingField", "Name");
                      if (
                        patientsJson.sortingType == "" ||
                        patientsJson.sortingField != "Name"
                      ) {
                        handlePatientJsonChange("sortingType", "ASC");
                      } else if (patientsJson.sortingType == "ASC") {
                        handlePatientJsonChange("sortingType", "DESC");
                      } else {
                        handlePatientJsonChange("sortingType", "ASC");
                      }

                      setPagenationState(Math.random());
                    }}
                  >
                    <b>Name</b>{" "}
                    {patientsJson.sortingField == "Name" &&
                    patientsJson.sortingType == "ASC" ? (
                      <FaSortUp />
                    ) : patientsJson.sortingField == "Name" &&
                      patientsJson.sortingType == "DESC" ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </Col>
                  <Col className="text-center" md={{ span: 2 }}>
                    <b>Phone</b>
                  </Col>
                  {/* <Col className="text-center">
                    <b>Requisition #</b>
                  </Col> */}
                  <Col
                    onClick={() => {
                      handlePatientJsonChange("sortingField", "MRN");
                      if (
                        patientsJson.sortingType == "" ||
                        patientsJson.sortingField != "MRN"
                      ) {
                        handlePatientJsonChange("sortingType", "ASC");
                      } else if (patientsJson.sortingType == "ASC") {
                        handlePatientJsonChange("sortingType", "DESC");
                      } else {
                        handlePatientJsonChange("sortingType", "ASC");
                      }
                      setPagenationState(Math.random());
                    }}
                    // md={{ span: 1 }}
                    className="cursorPointer text-center"
                  >
                    <b>MRN</b>{" "}
                    {patientsJson.sortingField == "MRN" &&
                    patientsJson.sortingType == "ASC" ? (
                      <FaSortUp />
                    ) : patientsJson.sortingField == "MRN" &&
                      patientsJson.sortingType == "DESC" ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </Col>
                  <Col
                    onClick={() => {
                      handlePatientJsonChange("sortingField", "DOB");
                      if (
                        patientsJson.sortingType == "" ||
                        patientsJson.sortingField != "DOB"
                      ) {
                        handlePatientJsonChange("sortingType", "ASC");
                      } else if (patientsJson.sortingType == "ASC") {
                        handlePatientJsonChange("sortingType", "DESC");
                      } else {
                        handlePatientJsonChange("sortingType", "ASC");
                      }
                      setPagenationState(Math.random());
                    }}
                    className="cursorPointer text-center"
                  >
                    <b>DOB</b>{" "}
                    {patientsJson.sortingField == "DOB" &&
                    patientsJson.sortingType == "ASC" ? (
                      <FaSortUp />
                    ) : patientsJson.sortingField == "DOB" &&
                      patientsJson.sortingType == "DESC" ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </Col>
                  <Col className="text-center">
                    <b>Facility Name</b>
                  </Col>
                  <Col md={{ span: 1 }} className="text-center">
                    <b>Gender</b>
                  </Col>
                  <Col className="text-center">
                    <b>Actions</b>
                  </Col>
                </Row>
                <AdvancedSearchDataGrid />
              </div>
              <ReactPaginate
                forcePage={currentPage}
                className="pagination mt-3"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={totalRecords}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                activeClassName="active"
                pageClassName="link"
              />
              {totalRecords > 0 && (
                <>
                  <span>showing</span>
                  <Form.Select
                    value={totalRecordPerPage}
                    className={
                      "form-select w-auto d-inline ml-1 mr-1 form-control"
                    }
                    onChange={(e) => {
                      setLoader(true);
                      setTotalRecordPerPage(e.target.value);
                      handlePatientJsonChange(
                        "totalRecordPerPage",
                        e.target.value
                      );
                      handlePatientJsonChange("pageNumber", 1);
                      setCurrentPage(0);
                      setPagenationState(Math.random());
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </Form.Select>
                  <span>per page</span>
                </>
              )}
            </Container>
          </div>
        </Container>
      </div>
      <CustomFooter showOrderFooterBar={false} />
    </div>
    </>
  );
}
export default Patients;
