import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";

function FormSelect(props) {
  const [formSelectDropDown, setFormSelectDropDown] = useState("");
  useEffect(() => {
    setFormSelectDropDown(props.defaultValue?.answerId);
  }, []);
  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      {/* <Form.Label>{props.formValue}</Form.Label> */}
      <h5 className="label-style">{props.formValue}</h5>
      <Form.Select
        aria-label={props.formValue}
        className="input-style form-control"
        disabled={props.disabled}
        required={props.required}
        value={formSelectDropDown}
        onChange={(e) => {
          setFormSelectDropDown(e.target.value);
          props.onChange(
            props.orderId != ""
              ? {
                  orderId: props.orderId,
                  testId: props.testId,
                  questionId: props.questionId,
                  answerId: e.target.value * 1,
                  answerValue: null,
                }
              : {
                  testId: props.testId,
                  questionId: props.questionId,
                  answerId: e.target.value * 1,
                  answerValue: null,
                }
          );
        }}
      >
        <option></option>
        {props.optionValues?.map((ansValue, ansIndex) => (
          <option
            key={ansIndex}
            // selected={
            //   props.defaultValue?.answerId == ansValue.id ? true : false
            // }
            value={ansValue.id}
          >
            {ansValue.answer}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}

export default FormSelect;
