import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaHome, FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LoginInfoContext, LoginUserLocationContext } from "../Context";
import WarningModel from "./WarningModel";
import SearchFacility from "../Pages/OrderPage/Components/SearchFacilityModel";
import { facilitiesListLimit } from "../config/config";
import dateFormat from "dateformat";
import { Row, Col, Table, Form, Button, Accordion } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import moment from "moment/moment";

function CustomNavBar({
  patientDetails,
  sexLookup,
  setSelectedOrderPatientId,
  selectedOrderPatientId,
  setSavedOrderId,
  screenLogin,
  showNavBar,
  detailData,
  lookupSexData,
  LookupText,
  age,
  open,
  setOpen,
  savedOrderId,
}) {
  var sexString = "";
  const navigate = useNavigate();
  const [addNewOrderState, setAddNewOrderState] = useState(false);
  const clickHandle = (e) => {
    navigate("/patients");
  };
  const [context, setContext] = useContext(LoginInfoContext);
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [searchFacilityModel, setSearchFacilityModel] = useState(false);
  const [selectedFacilityId, setSelectedFacilityId] = useState(null);
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  var nameAbbr = "";
  if (context.userInfo != undefined) {
    nameAbbr =
      context.userInfo.userLastName[0] + context.userInfo.userFirstName[0];
  }

  const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

  useEffect(() => {
    if (localStorage.getItem("userPermissions") != null) {
      JSON.parse(localStorage.getItem("userPermissions")).map(
        (value, index) => {
          if (value.facilityId == selectedLocation) {
            if (value.featureName == "Orders") {
              if (value.createFeature == "N") {
                setAddNewOrderState(true);
              }
            }
          }
        }
      );
    }
  }, []);
  useEffect(() => {
    setAddNewOrderState(false);
    if (localStorage.getItem("userPermissions") != null) {
      JSON.parse(localStorage.getItem("userPermissions")).map(
        (value, index) => {
          if (value.facilityId == selectedLocation) {
            if (value.featureName == "Orders") {
              if (value.createFeature == "N") {
                setAddNewOrderState(true);
              }
            }
          }
        }
      );
    }
  }, [selectedLocation]);
  return (
    <>
      <header className="hidemobile">
        <Navbar
          variant="light"
          className="navbar navbar-light bg-secondary sticky-top"
        >
          <Container>
            <Navbar.Brand>
              <img src="/atvivo-logo.png" />
            </Navbar.Brand>
            {!screenLogin && (
              <div className="navbar-nav-holder">
                {localStorage.getItem("firstLogin") == "Yes" ? 
                <div className="toplinks">
                  {context.locations != undefined &&
                  context.locations.length >= 2 ? (
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title={context.locations?.map((x) => {
                        if (x.id == selectedLocation) {
                          return x.label;
                        }
                      })}
                      menuVariant="dark"
                    >
                      {context.locations
                        ?.filter((x) => x.id != selectedLocation)
                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                        .slice(0, facilitiesListLimit)
                        .map((value, index) => (
                          <NavDropdown.Item
                            // className={ ((index % 2)==1)?"navlink":""}
                            key={index}
                            onClick={() => {
                              setSelectedFacilityId(value.id);
                              setCancelModalShow(true);
                            }}
                          >
                            {value.label}
                          </NavDropdown.Item>
                        ))}
                      <NavDropdown.Item
                        key={"searchFacility"}
                        onClick={() => {
                          setSearchFacilityModel(true);
                          // setSelectedFacilityId(value.facilityId);
                          // setCancelModalShow(true);
                        }}
                      >
                        {"Search Facility"}
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    context.locations?.map((x) => {
                      if (x.id == selectedLocation) {
                        return x.label;
                      }
                    })
                  )}
                  {/* <a href="#" title="Change Location">
                    Elmhurst Care Center
                  </a> */}{" "}
                  <span className="profileicon">
                    <NavDropdown
                      id="profileicondropdown"
                      title={nameAbbr}
                      menuVariant="dark"
                      drop="down-start"
                    >
                      {userPermissions[0].roleName == "Administrator" && (
                        <NavDropdown.Item
                          onClick={() => {
                            var url = window.location.href.split("#")[0];
                            window.location.assign(url + "admin/#/dashboard");
                          }}
                        >
                          Admin Panel
                        </NavDropdown.Item>
                      )}
                      <NavDropdown.Item
                        onClick={() => {
                          navigate("/profile");
                        }}
                      >
                        My Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          navigate("/downloads");
                        }}
                      >
                        Downloads
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item
                        onClick={() => {
                          navigate("/changePassword");
                        }}
                      >
                        Change Password
                      </NavDropdown.Item> */}

                      <NavDropdown.Item
                        onClick={() => {
                          localStorage.clear();
                          navigate("/");
                        }}
                      >
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </span>
                </div>
                :
                <div className="toplinks">

                  <span className="profileicon">
                    <NavDropdown
                      id="profileicondropdown"
                      title={nameAbbr}
                      menuVariant="dark"
                      drop="down-start"
                    >
                      <NavDropdown.Item
                        onClick={() => {
                          localStorage.clear();
                          navigate("/");
                        }}
                      >
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </span>
                </div>
                }
                {localStorage.getItem("firstLogin") == "Yes" &&
                <Nav>
                  <Nav.Link onClick={clickHandle} className="ml-auto">
                    <FaHome />
                  </Nav.Link>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Results"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        navigate("/results");
                      }}
                    >
                      Facility Reports
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        navigate("/resultList");
                      }}
                    >
                      Result Inbox
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        navigate("/hl7ResultsStatus");
                      }}
                    >
                      Hl7 Results
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Orders"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        setSelectedOrderPatientId("");
                        if (savedOrderId == undefined) {
                          setSavedOrderId(-1);
                        } else {
                          setSavedOrderId(savedOrderId - 1);
                        }

                        navigate("/order/new");

                        // navigate(0);
                      }}
                    >
                      New Order
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        navigate("/upload/orders");

                        // navigate(0);
                      }}
                    >
                      Upload Orders
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newOrder"}
                      onClick={() => {
                        navigate("/orderManifest");

                        // navigate(0);
                      }}
                    >
                      Order Manifest
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Patients"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"Patients"}
                      onClick={clickHandle}
                    >
                      All Patients
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      disabled={addNewOrderState}
                      key={"newPatient"}
                      onClick={() => {
                        setSelectedOrderPatientId(0);
                        navigate("/patient/new");
                      }}
                    >
                      Add Patient
                    </NavDropdown.Item>
                  </NavDropdown>
                  {userPermissions.find(
                    (item) =>
                      item.facilityId == selectedLocation &&
                      item.featureName == "Phlebotomy Orders" &&
                      item.viewFeature == "Y"
                  ) && (
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="PSC"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item
                        //disabled={addNewOrderState}
                        key={"PhlebotomyOrders"}
                        onClick={() => {
                          navigate("/PhlebotomyOrders");
                        }}
                      >
                        Phlebotomy Orders
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                </Nav>
}
              </div>
            )}
          </Container>
          {patientDetails.patientName != "-" || showNavBar ? (
            <Container>
              <div className="topinfo">
                <img
                  className="position-icon"
                  src="https://ewsatvivo-dev.s3.amazonaws.com/paper-pen.svg"
                />
                <span>
                  Name: <b>{patientDetails.patientName}</b>
                </span>
                <span>
                  Phone: <b>{patientDetails.patientPhone}</b>
                </span>
                <span>
                  DOB:{" "}
                  <b>
                    {patientDetails.patientDob != "-"
                      ? patientDetails.patientDob
                      : "-"}
                  </b>
                </span>
                <span>
                  Gender:{" "}
                  <b>
                    {patientDetails.patientSex != "-"
                      ? Object.keys(sexLookup).length > 0
                        ? sexLookup.sex.map((x) => {
                            if (
                              x.id.toString() ==
                              patientDetails.patientSex.toString()
                            ) {
                              sexString = x.options;
                            }
                          })
                        : "-"
                      : "-"}
                    {sexString}
                  </b>
                </span>
                <span>
                  Primary Insurance: <b>{patientDetails.patientInsurance}</b>
                </span>
              </div>
            </Container>
          ) : (
            <></>
          )}
          {detailData != undefined ? (
            <div class="topinfo resultsdetailpage">
              <div className="infodiv">
                <img
                  class="position-icon"
                  src="https://ewsatvivo-dev.s3.amazonaws.com/paper-pen.svg"
                ></img>
                <Row className="w-100">
                  <Col md={{ span: 2 }}>
                    Name:{" "}
                    <b>
                      {detailData.length > 0 ? detailData[0].patientName : ""}
                    </b>
                  </Col>
                  <Col md={{ span: 3 }}>
                    Phone:{" "}
                    <b>{detailData.length > 0 ? detailData[0].phone : ""}</b>
                  </Col>
                  <Col md={{ span: 2 }}>
                    DOB:{" "}
                    <b>
                      {detailData.length > 0 && detailData[0].patientDOB != ""
                        ? dateFormat(detailData[0].patientDOB, "mm/dd/yyyy")
                        : ""}
                    </b>
                  </Col>
                  <Col md={{ span: 2 }}>
                    Gender:{" "}
                    <b>
                      {detailData.length > 0
                        ? LookupText(lookupSexData, detailData[0].sex)
                        : ""}
                    </b>
                  </Col>
                  <Col md={{ span: 3 }}>
                    Requsition:{" "}
                    <b>
                      {detailData.length > 0 ? detailData[0].orderNumber : ""}
                    </b>
                  </Col>
                  {/* <Col md={{ span: 1 }}>
                    <Button
                      onClick={() => setOpen(!open)}
                      aria-controls="moreinfop"
                      aria-expanded={open}
                    >
                      More +
                    </Button>
                  </Col> */}
                </Row>
                {!open ? (
                  <FaPlus
                    onClick={() => setOpen(!open)}
                    aria-controls="moreinfop"
                    aria-expanded={open}
                  />
                ) : (
                  <FaMinus
                    onClick={() => setOpen(!open)}
                    aria-controls="moreinfop"
                    aria-expanded={open}
                  />
                )}
              </div>
              <Collapse in={open}>
                <div id="moreinfop">
                  <Row>
                    <Col md={{ span: 2 }}>
                      MRN:{" "}
                      <b>{detailData.length > 0 ? detailData[0].mrn : ""}</b>
                    </Col>
                    <Col md={{ span: 3 }}>
                      LAB ID:{" "}
                      <b>{detailData.length > 0 ? detailData[0].labId : ""}</b>
                    </Col>

                    {/* <Col>
                  <div className="smallheading">BILLING ACCOUNT</div>
                  <div className="infoValue">-</div>
                </Col> */}
                    {/* <Col>
                  <div className="smallheading">COLLECTED DATE</div>
                  <div className="infoValue">-</div>
                </Col>
                <Col>
                  <div className="smallheading">RECIEVED DATE</div>
                  <div className="infoValue">-</div>
                </Col>
                <Col>
                  <div className="smallheading">RESULTED DATE</div>
                  <div className="infoValue">
                    {detailData.length > 0
                      ? dateFormat(
                          detailData[0].dateTimeOfTransaction,
                          "mm-dd-yyyy h:mm:ss"
                        )
                      : ""}
                  </div>
                </Col> */}
                    <Col md={{ span: 2 }}>
                      Provider:{" "}
                      <b>
                        {detailData.length > 0
                          ? detailData[0].orderingProviderName != ", "
                            ? detailData[0].orderingProviderName
                            : ""
                          : ""}
                      </b>
                    </Col>

                    <Col md={{ span: 2 }}>
                      Age: <b>{age > 0 ? age + " Yrs" : "-"}</b>
                    </Col>
                    <Col md={{ span: 3 }}>
                      Primary Insurance:{" "}
                      <b>
                        {" "}
                        {detailData.length > 0
                          ? detailData[0].patientInsurance.length > 0
                            ? detailData[0].patientInsurance[0].name
                            : ""
                          : ""}
                      </b>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </div>
          ) : null}
        </Navbar>
        <WarningModel
          show={cancelModalShow}
          heading="Warning"
          bodytext="You are trying to change the facility. Any changes that you have made will be lost. Are you sure you want to change the facility?"
          onHide={() => setCancelModalShow(false)}
          onyes={async () => {
            localStorage.setItem("selectedLocation", selectedFacilityId);
            setSelectedLocation(selectedFacilityId);
            setCancelModalShow(false);
            navigate("/patients");
          }}
        />
        <SearchFacility
          searchFacilityState={searchFacilityModel}
          setSearchFacilityState={setSearchFacilityModel}
          selectFacility={() => {}}
          setFacility={false}
        />
      </header>
    </>
  );
}

export default CustomNavBar;
