import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import CustomNavBar from "../../GlobalComponents/NavBar";
import CollapsibleMobileNav from "../../GlobalComponents/mobileNavBar";
import CustomFooter from "../../GlobalComponents/Footer";
import { useNavigate } from "react-router-dom";
import "../OrderPage/OrderPage.css";
import { ApiGetCalls, ApiPostCalls, downloadFile } from "../../services/Api";

import dateFormat from "dateformat";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";

import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import SaveSearchCriteriaModel from "../ResultPage/Components/SaveSearchCriteriaModel";
import WarningModel from "../../GlobalComponents/WarningModel";
import MsgModel from "../../GlobalComponents/MsgModel";
import { LoginUserLocationContext } from "../../Context";
import axios from "axios";
import fileDownload from "react-file-download";
import { apiUrl } from "../../config/config";
import DropdownTreeSelect from "react-dropdown-tree-select";
import {
  getAllPhysicalFacilitiesFromTree,
  getChildObjStructure,
  getTreeOptionsSelected,
  getAllPhysicalFromTree,
} from "../OrderPage/Components/SearchFacilityModel";
import { BsPrinter, BsTrash, BsInfoCircle } from "react-icons/bs";
import { snackBar } from "../../services/Alert";
import CustomLoader from "../../GlobalComponents/Loader";
import Modal from "react-bootstrap/Modal";

function OrderManifest(props) {
  const [insuranceSearchQuery, setInsuranceSearchQuery] = useState("");
  const [providerSearchQuery, setProviderSearchQuery] = useState("");
  const [facilitySearchQuery, setFacilitySearchQuery] = useState("");
  const [showLoader, setLoader] = useState(false);
  const concernedElement = document.querySelector(".search");
  const [infoModalShow, setInfoModalShow] = useState(false);
  const [infoModalMsgShow, setInfoModalMsgShow] = useState("");
  const [labs, setlabs] = useState([]);
  const [noteModalShow, setNoteModalShow] = useState(false);
  const [noteId, setNoteId] = useState(0);

  const GetLabs = async () => {
    const response = await ApiGetCalls("GetLaboratories");
    if (response.data.length > 0) {
      setlabs(response.data);
    }
  };

  document.addEventListener("keyup", (event) => {
    if (
      event.target.classList.contains("search") &&
      event.target.closest(".facilityTree")
    ) {
      setFacilitySearchQuery(event.target.value);
    }
  });
  document.addEventListener("keyup", (event) => {
    if (
      event.target.classList.contains("search") &&
      event.target.closest(".providerTree")
    ) {
      setProviderSearchQuery(event.target.value);
    }
  });
  useEffect(() => {
    GetLabs();
    setTimeout(function () {
      var divId = document.getElementById("customSearchButtonId");
      if (divId) {
        divId.remove();
      }
      var searchButton = document.getElementsByClassName(
        "providerTreeOkButton"
      );
      if (searchButton.length == 0) {
        var scrollDiv = document.getElementsByClassName(
          "infinite-scroll-component"
        );
        var scrollDiv1 = document.getElementsByClassName("dropdown-content");
        var noMatch = document.getElementsByClassName("no-matches");
        var div = document.createElement("div");
        div.className = "customSearchButton";
        div.id = "customSearchButtonId";
        var button = document.createElement("button");
        button.className =
          "primary btn button SearchBtnCustom providerTreeOkButton";
        button.innerHTML = "OK";
        button.addEventListener("click", (event) => {
          var facilityDiv = document.getElementsByClassName("providerTree");
          var toggleButton =
            facilityDiv[0].getElementsByClassName("dropdown-trigger");
          toggleButton[0].click();
          // divToMention.classList.remove("form-control");
        });

        div.appendChild(button);
        if (noMatch.length != 0) {
          scrollDiv1[0].appendChild(div);
        } else {
          if (scrollDiv.length > 0) {
            scrollDiv[0].parentElement.appendChild(div);
          }
        }
      }
    }, 100);
  }, [providerSearchQuery]);
  useEffect(() => {
    setTimeout(function () {
      var divId = document.getElementById("customSearchButtonId");
      if (divId) {
        divId.remove();
      }
      var searchButton = document.getElementsByClassName(
        "facilityTreeOkButton"
      );
      if (searchButton.length == 0) {
        var scrollDiv = document.getElementsByClassName(
          "infinite-scroll-component"
        );
        var scrollDiv1 = document.getElementsByClassName("dropdown-content");
        var noMatch = document.getElementsByClassName("no-matches");
        var div = document.createElement("div");
        div.className = "customSearchButton";
        div.id = "customSearchButtonId";
        var button = document.createElement("button");
        button.className =
          "primary btn button SearchBtnCustom facilityTreeOkButton";
        button.innerHTML = "OK";
        button.addEventListener("click", (event) => {
          var facilityDiv = document.getElementsByClassName("facilityTree");
          var toggleButton =
            facilityDiv[0].getElementsByClassName("dropdown-trigger");
          toggleButton[0].click();
          // divToMention.classList.remove("form-control");
        });
        div.appendChild(button);
        if (noMatch.length != 0) {
          scrollDiv1[0].appendChild(div);
        } else {
          if (scrollDiv.length > 0) {
            scrollDiv[0].parentElement.appendChild(div);
          }
        }
      }
    }, 100);
  }, [facilitySearchQuery]);

  const navigate = useNavigate();
  var url = window.location.href.split("#")[1];
  useEffect(() => {
    document.title = "ATVIVO - Order Manifest";
    var userInfoJson = JSON.parse(localStorage.getItem("userInfo"));
    if (
      localStorage.getItem("token") == null ||
      localStorage.getItem("userInfo") == null ||
      userInfoJson?.userFirstName == undefined ||
      userInfoJson?.userLastName == undefined
    ) {
      localStorage.setItem("Url", url);
      navigate("/");
    } else {
      var curr = new Date();
      var lastMonday = getPreviousMonday(curr);

      var curr = new Date();
      const currentDate = new Date();
      const next2DaysDate = new Date(currentDate);
      setSearchFilter({
        startDate: moment(
          new Date(currentDate.setDate(currentDate.getDate() - 7))
        ).format("yyyy-MM-DD"),
        endDate: moment(new Date(curr)).format("yyyy-MM-DD"),
        dateType: "Order",
        pageNumber: 1,
        totalRecordPerPage: totalRecordPerPage,
        mainOperationType: "Any",
        tableView: "Horizontal",
        resultValues: [],
        facilities: [],
        facilitiesIdsArray: [],
        insurances: [],
        providers: [],
        paginate: true,
        orderStatus: null,
        labName: null,
      });
      // setTestAnalyteJson({
      //   startDate: moment(new Date(lastMonday)).format("yyyy-MM-DD"),
      //   endDate: moment(new Date(curr)).format("yyyy-MM-DD"),
      //   dateType: "Order",
      // });
      setPagenationState(Math.random());
      fetchStatusesInfoDataOnLoad();
      // GetUserRolesPermissions();
    }
  }, []);
  const [selectedLocation, setSelectedLocation] = useContext(
    LoginUserLocationContext
  );
  const [totalRecordPerPage, setTotalRecordPerPage] = useState(10);
  const [searchFilter, setSearchFilter] = useState({});
  var curr = new Date();
  const currentDate = new Date();
  const next2DaysDate = new Date(currentDate);
  var lastMonday = getPreviousMonday(curr);
  //  const [dateRange, setDateRange] = useState([lastMonday, curr]);
  const [dateRange, setDateRange] = useState([
    currentDate.setDate(currentDate.getDate() - 7),
    curr,
  ]);
  const [startDate, endDate] = dateRange;
  const [showDateRange, setShowDateRange] = useState(true);
  const [tableView, setTableView] = useState("Horizontal");
  const [tableViewValue, setTableViewValue] = useState("Horizontal");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showValueField, setShowValueField] = useState([]);
  const [selectedFacilityArray, setSelectedFacilityArray] = useState([]);
  const [selectedFacilityCodesArray, setSelectedFacilityCodesArray] = useState(
    []
  );
  const [selectedInsuranceArray, setSelectedInsuranceArray] = useState([]);
  const [selectedProvidersArray, setSelectedProvidersArray] = useState([]);
  const [patientDetails, setPatientDetails] = useState({
    patientName: "-",
    patientDob: "-",
    patientInsurance: "-",
    patientOfficeId: "-",
    patientSex: "-",
  });
  const uniqueTags = [];
  const [results, setResults] = useState([]);
  const [selectedresults, setSelectedResults] = useState([]);
  const [allSaveSearchCriteria, setAllSaveSearchCriteria] = useState([]);
  const [locationsDataJson, setLocationsDataJson] = useState(
    JSON.parse(localStorage.getItem("locations"))
  );
  async function onchangeHandler(e) {
    if (e.target.value != "") {
      setShowDateRange(true);
      if (e.target.value == "cWeek") {
        var lastMonday = getPreviousMonday(curr);

        setDateRange([lastMonday, curr]);
        handleSearchFilterChange(
          "startDate",
          moment(new Date(lastMonday)).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
      } else if (e.target.value == "today") {
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([curr, curr]);
      } else if (e.target.value == "yesterday") {
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr.setDate(curr.getDate() - 1))).format(
            "yyyy-MM-DD"
          )
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([curr, curr]);
      } else if (e.target.value == "cMonth") {
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr.getFullYear(), curr.getMonth(), 1)).format(
            "yyyy-MM-DD"
          )
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([new Date(curr.getFullYear(), curr.getMonth(), 1), curr]);
      } else if (e.target.value == "cMonth") {
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr.getFullYear(), curr.getMonth(), 1)).format(
            "yyyy-MM-DD"
          )
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([new Date(curr.getFullYear(), curr.getMonth(), 1), curr]);
      } else if (e.target.value == "cQuarter") {
        var quarter = getQuarter(curr);
        var month;
        if (quarter == 1) {
          month = 0;
        } else if (quarter == 2) {
          month = 3;
        } else if (quarter == 3) {
          month = 6;
        } else if (quarter == 4) {
          month = 9;
        }
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr.getFullYear(), month, "01")).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([new Date(curr.getFullYear(), month, "01"), curr]);
      } else if (e.target.value == "cYear") {
        var quarter = getQuarter(curr);
        var month = 0;
        handleSearchFilterChange(
          "startDate",
          moment(new Date(curr.getFullYear(), month, "01")).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(curr)).format("yyyy-MM-DD")
        );
        setDateRange([new Date(curr.getFullYear(), month, "01"), curr]);
      } else if (e.target.value == "lWeek") {
        var lastSunday = getPreviousSunday(curr);

        var lastMonday = getPreviousMonday(lastSunday);
        handleSearchFilterChange(
          "startDate",
          moment(new Date(lastMonday)).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(lastSunday)).format("yyyy-MM-DD")
        );
        setDateRange([lastMonday, lastSunday]);
      } else if (e.target.value == "lMonth") {
        var prevMonthLastDate = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          0
        );
        var prevMonthFirstDate = new Date(
          curr.getFullYear() - (curr.getMonth() > 0 ? 0 : 1),
          (curr.getMonth() - 1 + 12) % 12,
          1
        );
        handleSearchFilterChange(
          "startDate",
          moment(new Date(prevMonthFirstDate)).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(prevMonthLastDate)).format("yyyy-MM-DD")
        );
        setDateRange([prevMonthFirstDate, prevMonthLastDate]);
      } else if (e.target.value == "lQuarter") {
        // curr = new Date();
        var quarterWithYear = getLastQuarter(curr.getMonth(), new Date()).split(
          ","
        );

        var quarter = quarterWithYear[0];
        var month;
        var date = 31;
        if (quarter == 1) {
          month = 0;
        } else if (quarter == 2) {
          month = 3;
          date = 30;
        } else if (quarter == 3) {
          month = 6;
          date = 30;
        } else if (quarter == 4) {
          month = 9;
        }
        handleSearchFilterChange(
          "startDate",
          moment(new Date(quarterWithYear[1], month, "01")).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(quarterWithYear[1], month + 2, date)).format(
            "yyyy-MM-DD"
          )
        );
        setDateRange([
          new Date(quarterWithYear[1], month, "01"),
          new Date(quarterWithYear[1], month + 2, date),
        ]);
      } else if (e.target.value == "lYear") {
        var prevYearLastDate = new Date(curr.getFullYear() - 1, 11, 31);
        var prevYearFirstDate = new Date(curr.getFullYear() - 1, 0, 1);
        handleSearchFilterChange(
          "startDate",
          moment(new Date(prevYearFirstDate)).format("yyyy-MM-DD")
        );
        handleSearchFilterChange(
          "endDate",
          moment(new Date(prevYearLastDate)).format("yyyy-MM-DD")
        );
        setDateRange([prevYearFirstDate, prevYearLastDate]);
      } else if (e.target.value == "dateRange") {
        setDateRange([]);
        setShowDateRange(false);
      }
    } else {
      //setResults([]);
    }
    setSelectedDate(e.target.value);
    handleSearchFilterChange("selectedDate", e.target.value);
  }
  const handleSearchFilterChange = (name, value) => {
    setSearchFilter((values) => ({ ...values, [name]: value }));
    if (name == "startDate" || name == "endDate") {
      handleTestAnalyteJsonFilter(name, value);
      setShowValueField([]);
    }
  };
  const GetAllSaveSearchCriteria = async () => {
    const response = await ApiGetCalls(
      "GetAllSaveSearchCriteria/" + localStorage.getItem("userId")
    );
    if (response.length > 0) {
      setAllSaveSearchCriteria(response);
    }
  };
  const [userPermissions, setUserPermissions] = useState([]);
  const GetUserRolesPermissions = async () => {
    const response = await ApiGetCalls(
      "GetUserRoleFreatures/" + localStorage.getItem("userId")
    );
    if (response.status == "Success") {
      setUserPermissions(response.data.filter((x) => x.featureId == 6));
    }
  };

  const [filter, setFilter] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [noResultFound, setNoResultFound] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [showText, setShowText] = useState("");
  const [oldValue, setOldValue] = useState("");
  const rowClickHandler = (selectedFacilityCodeObj) => {
    setSelectedResults(selectedFacilityCodeObj);
    selectedFacilityCodeObj.map((value, index) => {
      resultTestDataType.map((resultType, key) => {
        if (resultType.id == value.id) {
          const showValueFieldObj = {
            id: value.id.toString(),
            resultCode: value.valueArray[1],
            resultName: value.valueArray[2],
            operationType: "All",
            value: null,
            valueText: null,
            dataType: resultType.valueType,
            valueArray: [value.id, value.valueArray[1], value.valueArray[2]],
          };
          if (!showValueField.some((item) => value.id === item.id)) {
            showValueField.push(showValueFieldObj);
          } else {
            showValueField.map((item, key) => {
              if (item.id != value.id) {
                var arrayIndex = showValueField.indexOf(item);
                showValueField.splice(arrayIndex, 1);
              }
            });
          }
        }
      });
    });
    setShowValueField(showValueField);
    setShowResults(false);
    setFilter("");
  };
  const [showSavedSearchModel, setShowSavedSearchModel] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteSavedSearchModel, setDeleteSavedSearchModel] = useState(true);
  const [selectedDate, setSelectedDate] = useState("cWeek");
  const [savedSearchCriteriaId, setSavedSearchCriteriaId] = useState(0);
  const [dateType, setDateType] = useState("Order");
  const [savedSearchsDropdown, setSavedSearchsDropdown] = useState(0);
  const [savedSearchCriteriaUserId, setSavedSearchCriteriaUserId] = useState(0);
  const [commonOrderIds, setCommonOrderIds] = useState([]);
  const [
    saveSearchCriteriaPermissionCheck,
    setSaveSearchCriteriaPermissionCheck,
  ] = useState(true);
  async function saveSearchClickHandler() {
    if (savedSearchCriteriaId != 0) {
      allSaveSearchCriteria.map(async (val, ind) => {
        if (val.id == savedSearchCriteriaId) {
          setSavedSearchCriteriaUserId(val.userId);
          if (val.userId == localStorage.getItem("userId")) {
            setSaveSearchCriteriaPermissionCheck(true);
          } else {
            if (userPermissions[0].rolePermissions.updateFeature == "Y") {
              setSaveSearchCriteriaPermissionCheck(true);
            } else {
              setSaveSearchCriteriaPermissionCheck(false);
              setSearchName("");
            }
          }
        }
      });
    }
    setShowSavedSearchModel(true);
  }
  async function getSearchResult() {
    if (selectedFacilityArray.length == 0) {
      searchFilter.facilities = allFacilities;
      searchFilter.facilitiesIdsArray = allFacilitiesIds;
    }
    const response = await ApiPostCalls(
      JSON.stringify(searchFilter),

      "AdvanceOrderManiFestSearch"
    );
    setLoader(false);
    if (selectedFacilityArray.length == 0) {
      handleSearchFilterChange("facilities", []);
      handleSearchFilterChange("facilitiesIdsArray", []);
    }

    if (response != null && response.length > 0) {
      setTotalRecords(Math.ceil(response[0].totalRecords / totalRecordPerPage));
      setNoResultFound(false);
    } else {
      setNoResultFound(true);
    }
    if (response != null) {
      setSearchResult(response);
      var idsArray = [];
      response.map((val, ind) => {
        if (val.orderType == "Result") idsArray.push(val.id);
      });
      props.setResultIds(idsArray);
      localStorage.setItem("resultIds", JSON.stringify(idsArray));
    } else {
      setSearchResult([]);
    }
  }
  async function exportSearchResult() {
    if (selectedFacilityArray.length == 0) {
      searchFilter.facilities = allFacilities;
      searchFilter.facilitiesIdsArray = allFacilitiesIds;
    }

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Response-Type": "blob",
      },

      body: JSON.stringify({ ...searchFilter, ["paginate"]: false }),
    };
    const response = await fetch(
      apiUrl + "AdvanceOrderManifestSearchExport",
      requestOptions
    )
      .then(async (response) => {
        if (response.status.toString() == "200") {
          return response.blob();
        } else {
          if (response.status.toString() == "401") {
            const accessToken = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            const refreshPayload = JSON.stringify({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });

            var refreshTokenResponse = await ApiPostCalls(
              refreshPayload,
              "refresh-token"
            );
            localStorage.setItem("token", refreshTokenResponse["accessToken"]);
            localStorage.setItem(
              "refreshToken",
              refreshTokenResponse["refreshToken"]
            );
            exportSearchResult();
          }
          return false;
        }
      })
      .then((blob) => {
        setLoader(false);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        var today = new Date();
        var fileNameWithDateString =
          today.getFullYear().toString() +
          (today.getMonth() + 1).toString().padStart(2, "0") +
          today.getDate().toString() +
          today.getHours().toString() +
          today.getMinutes().toString() +
          today.getSeconds().toString();
        a.download = "OrderManifestExport_" + fileNameWithDateString + ".xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }
  async function downloadSearchResults() {
    if (selectedFacilityArray.length == 0) {
      searchFilter.facilities = allFacilities;
      searchFilter.facilitiesIdsArray = allFacilitiesIds;
    }
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Response-Type": "blob",
      },
      body: JSON.stringify(searchFilter),
    };
    const response = await fetch(
      apiUrl + "AdvanceResultsDownload",
      requestOptions
    )
      .then(async (response) => {
        if (response.status.toString() == "200") {
          return response.blob();
        } else {
          if (response.status.toString() == "401") {
            const accessToken = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            const refreshPayload = JSON.stringify({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });

            var refreshTokenResponse = await ApiPostCalls(
              refreshPayload,
              "refresh-token"
            );
            localStorage.setItem("token", refreshTokenResponse["accessToken"]);
            localStorage.setItem(
              "refreshToken",
              refreshTokenResponse["refreshToken"]
            );
            downloadSearchResults();
          }
          return false;
        }
      })
      .then((blob) => {
        setLoader(false);
        if (blob.size > 0) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          var today = new Date();
          var fileNameWithDateString =
            today.getFullYear().toString() +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            today.getDate().toString() +
            today.getHours().toString() +
            today.getMinutes().toString() +
            today.getSeconds().toString();
          a.download = "Results_" + fileNameWithDateString + ".pdf";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        } else {
          snackBar("No result found", "info");
        }
      });
  }
  const resultTestData = [];
  const resultTestDataType = [];

  for (let i = 0; i < results.length; i++) {
    if (
      !showValueField.some(
        (item) => results[i].resultCode.toString() === item.id
      )
    ) {
      const resultTestDataObj = [
        results[i].resultCode.toString(),
        results[i].resultCode.toString(),
        results[i].resultName,
      ];
      const resultTestDataTypeObj = {
        id: results[i].resultCode.toString(),
        valueType: results[i].valueType,
      };
      resultTestData.push(resultTestDataObj);
      resultTestDataType.push(resultTestDataTypeObj);
    }
  }

  function getPreviousMonday(date) {
    var day = date.getDay();
    var prevMonday = new Date();
    if (date.getDay() == 0) {
      prevMonday.setDate(date.getDate() - 6);
    } else {
      prevMonday.setDate(date.getDate() - (day - 1));
    }

    return prevMonday;
  }
  function getPreviousSunday(date) {
    var day = date.getDay();
    var prevSunday = new Date();
    if (date.getDay() == 0) {
      prevSunday.setDate(date.getDate() - 7);
    } else {
      prevSunday.setDate(date.getDate() - day);
    }

    return prevSunday;
  }
  function getQuarter(date = new Date()) {
    return Math.floor(date.getMonth() / 3 + 1);
  }

  function getLastQuarter(month, date) {
    date.setDate(1);
    date.setMonth(date.getMonth() - 3);
    //var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var quarter = Math.floor(date.getMonth() / 3 + 1);
    return quarter + "," + year;
  }

  useEffect(() => {
    handleSearchFilterChange("resultValues", showValueField);
  }, [showValueField]);

  const [pagenationState, setPagenationState] = useState(-1);
  const [deleteSearchCriteria, setDeleteSearchCriteria] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * 1) % 2;
    if (selectedFacilityArray.length == 0) {
      handleSearchFilterChange("facilities", allFacilities);
      handleSearchFilterChange("facilitiesIdsArray", allFacilitiesIds);
    }
    handleSearchFilterChange("pageNumber", event.selected + 1);
    setPagenationState(Math.random());
  };
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (pagenationState != -1) {
      setLoader(true);
      getSearchResult();
    }
  }, [pagenationState]);
  const onConfirmationYes = async () => {
    const response = await ApiGetCalls(
      "RemoveSaveSearchCriteria/" + deleteSearchCriteria
    );

    if (response.status == "Success") {
      GetAllSaveSearchCriteria();
      setModalShow(false);
      resetPage();
    }
  };
  const resetPage = () => {
    var curr = new Date();
    const currentDate = new Date();
    const next2DaysDate = new Date(currentDate);
    var curr1 = new Date();
    const currentDate1 = new Date();
    const next2DaysDate1 = new Date(currentDate1);
    setSavedSearchsDropdown(0);
    setDeleteSavedSearchModel(true);
    setOrderStatusState("All");
    setlabNameState("All");
    setReportStatusState("All");
    setSearchName("");
    setShowText("");
    setPatientLastName("");
    setPatientFirstName("");
    setOrderNumberSearch("");
    setSavedSearchCriteriaId(0);
    setInsuranceFilter("");
    setSearchFilter({
      //startDate: moment(getPreviousMonday(new Date())).format("yyyy-MM-DD"),
      //endDate: moment(new Date()).format("yyyy-MM-DD"),
      startDate: moment(
        new Date(currentDate.setDate(currentDate.getDate() - 7))
      ).format("yyyy-MM-DD"),
      endDate: moment(new Date(curr)).format("yyyy-MM-DD"),

      dateType: "Order",
      pageNumber: 1,
      totalRecordPerPage: 10,
      mainOperationType: "Any",
      tableView: "Horizontal",
      resultValues: [],
      facilities: [],
      facilitiesIdsArray: [],
      insurances: [],
      providers: [],
      paginate: true,
      orderStatus: null,
      labName: null,
    });
    setTestAnalyteJson({
      startDate: moment(getPreviousMonday(new Date())).format("yyyy-MM-DD"),
      endDate: moment(new Date()).format("yyyy-MM-DD"),
      dateType: "Order",
    });
    setShowValueField([]);
    setPagenationState(Math.random());
    setSelectedDate("cWeek");
    // setDateRange([getPreviousMonday(new Date()), new Date()]);
    setDateRange([currentDate1.setDate(currentDate1.getDate() - 7), curr1]);
    setDateType("Order");
    setDeleteSearchCriteria(0);
    setTableView("Horizontal");
    setOldValue("");
    setSearchResult([]);
    setProvidersData([]);
    setSelectedFacilityArray([]);
    setSelectedFacilityCodesArray([]);
    setSelectedInsuranceArray([]);
    setSelectedProvidersArray([]);
    setTotalRecordPerPage(10);
  };
  // const [insurances, setInsurance] = useState([]);
  const [insuranceFilter, setInsuranceFilter] = useState("");
  const [showInsuranceResults, setShowInsuranceResults] = useState(false);
  const [insurancesData, setInsurancesData] = useState([]);
  // const [insurancesDataJson, setinsurancesDataJson] = useState(
  //   getTreeOptionsSelected(insurancesData, "id", [])
  // );

  const rowClickHandlerInsurances = (selectedInsuranceObj, id) => {
    setShowInsuranceResults(false);
    setInsuranceFilter(selectedInsuranceObj[1]);
  };

  // const [providers, setProviders] = useState([]);
  const [providerFilter, setProviderFilter] = useState("");
  const [showProviderResults, setShowProviderResults] = useState(false);
  const [providersData, setProvidersData] = useState([]);

  const rowClickHandlerProviders = (selectedInsuranceObj, id) => {
    setShowProviderResults(false);
    setProviderFilter(selectedInsuranceObj[1]);
  };
  const [sex, setSex] = useState([]);
  const [orderStatusState, setOrderStatusState] = useState("All");
  const [labNameState, setlabNameState] = useState("All");
  const [reportStatusState, setReportStatusState] = useState("All");
  const [patientLastName, setPatientLastName] = useState("");
  const [patientFirstName, setPatientFirstName] = useState("");
  const [orderNumberSearch, setOrderNumberSearch] = useState("");
  const GetSex = async () => {
    const response = await ApiGetCalls("GetQuickLookupsByName/Sex");
    if (response.length > 0) {
      setSex(response);
    }
  };
  const [lookOrderInfo, setLookOrderInfo] = useState({
    reportStatuses: [],
    orderStatuses: [],
  });
  async function fetchStatusesInfoDataOnLoad() {
    try {
      const [reportStatuses, orderStatuses] = await Promise.all([
        await ApiGetCalls("GetQuickLookupsByName/reportstatus"),
        await ApiGetCalls("GetQuickLookupsByName/orderstatus"),
      ]);
      setLookOrderInfo({
        reportStatuses: reportStatuses,
        orderStatuses: orderStatuses,
      });
    } catch (error) {}
  }
  const GetMultiFacilityProviders = async (ids) => {
    // const response = await ApiPostCalls(
    //   "GetProvidersByFacilities/" + selectedLocation
    // );
    const response = await ApiPostCalls(
      JSON.stringify(ids),

      "GetProvidersByFacilities"
    );
    if (response.status == "Success") {
      const insurancesDataObjWithAll = {
        id: 0,
        label: "All",
        expanded: true,
        className: "non-selective allSelection",
        children: [],
      };
      for (let i = 0; i < response.data.length; i++) {
        const providersDataObj = {
          //providers[i].id.toString(),
          label: response.data[i].lastName + " " + response.data[i].firstName,
          id: response.data[i].npi,
          className: "",
          children: [],
        };
        insurancesDataObjWithAll.children.push(providersDataObj);
      }
      providersData.push(insurancesDataObjWithAll);
      //setSelectedProvidersArray([]);
      setProvidersData([
        ...getTreeOptionsSelected(providersData, "id", selectedProvidersArray),
      ]);
    }
  };
  const GetInsuranceCompanies = async () => {
    const response = await ApiGetCalls("GetInsuranceCompanies");
    // setInsurance(response);
    const insurancesDataObjWithAll = {
      id: 0,
      label: "All",
      expanded: true,
      className: "non-selective allSelection",
      children: [],
    };
    for (let i = 0; i < response.length; i++) {
      const insurancesDataObj = {
        id: response[i].id,
        label: response[i].name,
        className: "",
        children: [],
      };
      insurancesDataObjWithAll.children.push(insurancesDataObj);
    }
    insurancesData.push(insurancesDataObjWithAll);
    setInsurancesData([...getTreeOptionsSelected(insurancesData, "id", [])]);
  };
  const [testAnalyteJson, setTestAnalyteJson] = useState({});
  const [allFacilities, setAllFacilities] = useState([]);
  const [allFacilitiesIds, setAllFacilitiesIds] = useState([]);
  const handleTestAnalyteJsonFilter = (name, value) => {
    setTestAnalyteJson((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    if (selectedFacilityArray.length == 0) {
      var facilityIds = [];
      var nonSelectedfacilityCodes = [];
      var physicalFacilities = getAllPhysicalFacilitiesFromTree(
        JSON.parse(localStorage.getItem("locations")),
        []
      );
      // physicalFacilities.map((val) => {
      //   facilityIds.push(val.id);
      // });
      physicalFacilities.map((val) => {});
      physicalFacilities.map((val) => {
        if (val.id == JSON.parse(localStorage.getItem("selectedLocation"))) {
          nonSelectedfacilityCodes.push(val.code);
          facilityIds.push(val.id);
        }
      });
      // physicalFacilities.map((val) => {
      //   nonSelectedfacilityCodes.push(val.code);
      // });
      setAllFacilities(nonSelectedfacilityCodes);
      setAllFacilitiesIds(facilityIds);
      handleSearchFilterChange("facilities", nonSelectedfacilityCodes);
      handleTestAnalyteJsonFilter("facilities", nonSelectedfacilityCodes);
      handleSearchFilterChange("facilitiesIdsArray", facilityIds);
      handleTestAnalyteJsonFilter("facilitiesIdsArray", facilityIds);
      handleSearchFilterChange("providers", []);
      handleTestAnalyteJsonFilter("providers", []);
      GetMultiFacilityProviders(facilityIds);
      setSelectedFacilityCodesArray(nonSelectedfacilityCodes);
      setSelectedFacilityArray(facilityIds);
      setLocationsDataJson(
        getTreeOptionsSelected(
          JSON.parse(localStorage.getItem("locations")),
          "id",
          nonSelectedfacilityCodes
        )
      );
    } else {
      setLocationsDataJson(
        getTreeOptionsSelected(
          JSON.parse(localStorage.getItem("locations")),
          "id",
          selectedFacilityArray
        )
      );
    }
  }, [selectedFacilityArray]);
  useEffect(() => {
    //setProvidersData([]);
    if (selectedFacilityArray.length != 0)
      GetMultiFacilityProviders(selectedFacilityArray);
  }, [selectedFacilityArray]);

  useEffect(() => {
    setInsurancesData([
      ...getTreeOptionsSelected(insurancesData, "id", selectedInsuranceArray),
    ]);
  }, [selectedInsuranceArray]);

  useEffect(() => {
    setProvidersData([
      ...getTreeOptionsSelected(providersData, "id", selectedProvidersArray),
    ]);
  }, [selectedProvidersArray]);
  // var facilitiesCodeArray;
  // if (selectedFacilityArray.length == 0) {
  //   facilitiesCodeArray = [];
  // }
  const handleSearchClick = () => {
    // if(allFacilities.length == )
    if (selectedFacilityArray.length == 0) {
      handleSearchFilterChange("facilities", allFacilities);
      handleSearchFilterChange("facilitiesIdsArray", allFacilitiesIds);
    }
    handleSearchFilterChange("pageNumber", 1);
    setCurrentPage(0);
    setCount(count + 1);
    setPagenationState(count + 1);
    // getSearchResult();
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };
  const [printing, setPrintng] = useState(false);
  const [labelCopies, setLabelCopies] = useState(1);
  const [pdflabel, setPdflabel] = useState("");
  const [lablesCountInPdf, setLablesCountInPdf] = useState(null);
  const [pdfBlob, setPdfBlob] = useState("");
  const [orderPdfDetail, setOrderPdfDetail] = useState("");
  const [orderNumberState, setOrderNumberState] = useState("");
  async function printOrderReport(orderNumber) {
    if (printing) {
      return;
    }
    setPrintng(true);
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Response-Type": "blob",
      },
      // body: JSON.stringify({
      //   orderNumber: "A000071",
      // }),
    };
    const response = await fetch(
      apiUrl + "GenerateOrderLabel/" + orderNumber,
      requestOptions
    )
      .then(async (response) => {
        if (response.status.toString() == "200") {
          return response.blob();
        } else {
          if (response.status.toString() == "401") {
            const accessToken = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            const refreshPayload = JSON.stringify({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });

            var refreshTokenResponse = await ApiPostCalls(
              refreshPayload,
              "refresh-token"
            );
            localStorage.setItem("token", refreshTokenResponse["accessToken"]);
            localStorage.setItem(
              "refreshToken",
              refreshTokenResponse["refreshToken"]
            );
            printOrderReport(orderNumber);
            setLabelCopies(1);
          }
          return false;
        }
      })
      .then(async (blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          setPdflabel(base64data);
        };
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Response-Type": "blob",
          },
          body: JSON.stringify({
            orderNumbers: [orderNumber],
            lablesCount: lablesCountInPdf != null ? lablesCountInPdf : 8,
          }),
        };
        const response = await fetch(
          apiUrl + "GenerateOrderAndForeignOrdersPdf",
          requestOptions
        )
          .then((response) => response.blob())
          .then((blob) => {
            // var blob = response.pdf.blob();
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              setPdfBlob(base64data);
              setOrderPdfDetail(orderNumber + "- Requisition Report");
              var a = document.createElement("a");
              a.setAttribute("data-bs-toggle", "modal");
              a.setAttribute(
                "data-bs-target",
                "#PrintPreviewDialogInOrderManifest"
              );
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();
              setPrintng(false);
              setLoader(false);
            };
          });
      });
  }
  useEffect(() => {
    if (lablesCountInPdf != null) {
      setPrintng(true);
      setLoader(true);
      async function printOrderReportOnLablesCount(orderNumber) {
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Response-Type": "blob",
          },
          body: JSON.stringify({
            orderNumbers: [orderNumber],
            lablesCount: lablesCountInPdf != null ? lablesCountInPdf : 8,
          }),
        };

        var response = fetch(
          apiUrl + "GenerateOrderAndForeignOrdersPdf",
          requestOptions
        )
          .then(async (response) => {
            if (response.status.toString() == "200") {
              return response.blob();
            } else {
              if (response.status.toString() == "401") {
                const accessToken = localStorage.getItem("token");
                const refreshToken = localStorage.getItem("refreshToken");
                const refreshPayload = JSON.stringify({
                  accessToken: accessToken,
                  refreshToken: refreshToken,
                });

                var refreshTokenResponse = await ApiPostCalls(
                  refreshPayload,
                  "refresh-token"
                );
                localStorage.setItem(
                  "token",
                  refreshTokenResponse["accessToken"]
                );
                localStorage.setItem(
                  "refreshToken",
                  refreshTokenResponse["refreshToken"]
                );
                printOrderReportOnLablesCount(orderNumber);
              }
              return false;
            }
          })
          .then((blob) => {
            // var blob = response.pdf.blob();
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              setPdfBlob(base64data);
              setOrderPdfDetail(orderNumber + "- Requisition Report");
              setPrintng(false);
              setLoader(false);
            };
          });
      }
      printOrderReportOnLablesCount(orderNumberState);
    }
  }, [lablesCountInPdf]);
  return (
    <div>
      {showLoader && <CustomLoader />}
      <CustomNavBar
        patientDetails={patientDetails}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        selectedOrderPatientId={props.selectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <CollapsibleMobileNav
        patientDetails={patientDetails}
        setSelectedOrderPatientId={props.setSelectedOrderPatientId}
        selectedOrderPatientId={props.selectedOrderPatientId}
        setSavedOrderId={props.setSavedOrderId}
      />
      <div className="pageheight resultsPage withOutbar">
        <Container>
          {/* <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
            Order Manifest
          </h3> */}
          <h3 className="mb-4 d-flex flex-row align-items-end align-content-end">
            Order Manifest
            <Button
              onClick={() => navigate("/order/new")}
              variant="primary"
              className="primary btn button ml-auto"
            >
              Add New Order
            </Button>
          </h3>
          <div className="whitepartition">
            <Row>
              {/* <Col md={{ span: 3 }}>
                <h5 className="label-style">Date</h5>
                <Form.Select
                  className="form-control"
                  value={selectedDate}
                  onChange={(e) => onchangeHandler(e)}
                  onKeyDown={handleKeypress}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="cWeek">This Week</option>
                  <option value="cMonth">This Month</option>
                  <option value="cQuarter">This Quarter</option>
                  <option value="cYear">This Year</option>
                  <option value="lWeek">Last Week</option>
                  <option value="lMonth">Last Month</option>
                  <option value="lQuarter">Last Quarter</option>
                  <option value="lYear">Last Year</option>
                  <option value="dateRange">Custom</option>
                </Form.Select>
              </Col> */}
              <Col md={{ span: 6 }} lg={{ span: 3 }}>
                <h5 className="label-style">Date Range</h5>
                <DatePicker
                  preventOpenOnFocus={true}
                  className="form-control"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM-dd-yyyy"
                  onChange={(update) => {
                    handleSearchFilterChange(
                      "startDate",
                      moment(new Date(update[0])).format("yyyy-MM-DD")
                    );
                    if (update[1] != null) {
                      handleSearchFilterChange(
                        "endDate",
                        moment(new Date(update[1])).format("yyyy-MM-DD")
                      );
                    }
                    setDateRange(update);
                  }}
                  //disabled={showDateRange}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dropdownMode="select"
                />
              </Col>
              {/* <Col md={{ span: 3 }}>
                <h5 className="label-style">Date Type</h5>
                <Form.Select
                  className="form-control"
                  value={dateType}
                  onChange={(e) => {
                    handleSearchFilterChange("dateType", e.target.value);
                    handleTestAnalyteJsonFilter("dateType", e.target.value);
                    setShowValueField([]);
                    setDateType(e.target.value);
                  }}
                  onKeyDown={handleKeypress}
                >
                  <option value="Order">Order Date</option>
                  <option value="Collection">Collection Date</option>
                </Form.Select>
              </Col> */}
              <Col className="d-flex flex-row align-items-end align-content-end"></Col>
            </Row>
            <Container className="resultAdvancesearch">
              <Row className="mt-3">
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Patient Last Name</h5>
                  <input
                    value={patientLastName}
                    onChange={(e) => {
                      setPatientLastName(e.target.value);
                      handleSearchFilterChange(
                        "patientLastName",
                        e.target.value != "" ? e.target.value : null
                      );
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Patient First Name</h5>
                  <input
                    value={patientFirstName}
                    onChange={(e) => {
                      setPatientFirstName(e.target.value);
                      handleSearchFilterChange(
                        "patientFirstName",
                        e.target.value != "" ? e.target.value : null
                      );
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Requisition #</h5>
                  <input
                    value={orderNumberSearch}
                    onChange={(e) => {
                      setOrderNumberSearch(e.target.value);
                      handleSearchFilterChange(
                        "orderNumber",
                        e.target.value != "" ? e.target.value : null
                      );
                    }}
                    className="form-control"
                    onKeyDown={handleKeypress}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Order Status</h5>

                  <Form.Select
                    className="form-control"
                    value={orderStatusState}
                    onChange={(e) => {
                      setOrderStatusState(e.target.value);
                      handleSearchFilterChange(
                        "orderStatus",
                        e.target.value != "All" ? e.target.value : null
                      );
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value="All">All</option>
                    {lookOrderInfo?.orderStatuses.map((val, ind) => {
                      return (
                        <option value={val.optionCode}>{val.options}</option>
                      );
                    })}
                    {/* <option value="Draft">Draft</option>
                    <option value="Submitted">Available For Editing</option>
                    <option value="Sent">Transmitted</option>
                    <option value="Unsolicited">Unsolicited</option> */}
                  </Form.Select>
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Laboratories</h5>

                  <Form.Select
                    className="form-control"
                    value={labNameState}
                    onChange={(e) => {
                      setlabNameState(e.target.value);
                      handleSearchFilterChange(
                        "labName",
                        e.target.value != "All" ? e.target.value : null
                      );
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value="All">All</option>
                    {labs.map((value, Index) => (
                      <option key={Index} value={value.name}>
                        {value.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={{ span: 4 }}>
                  <h5 className="label-style">Report Status</h5>

                  <Form.Select
                    className="form-control"
                    value={reportStatusState}
                    onChange={(e) => {
                      setReportStatusState(e.target.value);
                      handleSearchFilterChange(
                        "reportStatus",
                        e.target.value != "All" ? e.target.value : null
                      );
                    }}
                    onKeyDown={handleKeypress}
                  >
                    <option value="All">All</option>
                    {lookOrderInfo?.reportStatuses.map((val, ind) => {
                      return (
                        <option value={val.optionCode}>{val.options}</option>
                      );
                    })}
                    {/* <option value="IP">In Process</option>
                    <option value="CM">Final</option> */}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{ span: 6 }} className="resultFacilityTree">
                  <h5 className="label-style">Facility</h5>
                  <DropdownTreeSelect
                    mode="multiSelect"
                    keepTreeOnSearch={true}
                    keepChildrenOnSearch={true}
                    keepOpenOnSelect={false}
                    data={locationsDataJson}
                    showPartiallySelected={true}
                    onChange={(e) => {
                      setSelectedProvidersArray([]);
                      setProvidersData([]);
                      setProvidersData([]);
                      if (e.className != "non-selective") {
                        var test = selectedFacilityArray;
                        var idsArray = selectedFacilityCodesArray;
                        if (selectedFacilityArray.length == 0) {
                        }

                        if (test.indexOf(e.id) == -1) {
                          test.push(e.id);
                          handleSearchFilterChange("facilities", test);
                          setSelectedFacilityArray([...test]);
                        } else {
                          test.splice(test.indexOf(e.id), 1);
                          handleSearchFilterChange("facilities", test);

                          setSelectedFacilityArray([...test]);
                        }
                        setShowValueField([]);

                        if (idsArray.indexOf(e.code) == -1) {
                          idsArray.push(e.code);
                          // handleSearchFilterChange("facilities", test);
                          //setSelectedFacilityCodesArray([...idsArray]);
                        } else {
                          idsArray.splice(idsArray.indexOf(e.code), 1);
                        }
                        setSelectedFacilityCodesArray([...idsArray]);
                        handleSearchFilterChange("facilities", idsArray);
                      } else {
                        const found = [];
                        found.push(
                          getChildObjStructure(
                            JSON.parse(localStorage.getItem("locations")),
                            e.id
                          )
                        );
                        var physicalFacilities =
                          getAllPhysicalFacilitiesFromTree(found, []);
                        physicalFacilities.map((val) => {
                          var test = selectedFacilityArray;
                          var idsArray = selectedFacilityCodesArray;
                          if (test.indexOf(val.id) == -1) {
                            test.push(val.id);
                            handleSearchFilterChange(
                              "facilitiesIdsArray",
                              test
                            );
                            handleTestAnalyteJsonFilter(
                              "facilitiesIdsArray",
                              test
                            );

                            setSelectedFacilityArray([...test]);
                          } else if (e.checked == false) {
                            test.splice(test.indexOf(val.id), 1);
                            handleSearchFilterChange(
                              "facilitiesIdsArray",
                              test
                            );
                            handleTestAnalyteJsonFilter(
                              "facilitiesIdsArray",
                              test
                            );
                            setSelectedFacilityArray([...test]);
                          }
                          setShowValueField([]);

                          if (idsArray.indexOf(val.id) == -1) {
                            idsArray.push(val.id);
                            // handleSearchFilterChange("facilities", test);
                            //setSelectedFacilityCodesArray([...idsArray]);
                          } else if (e.checked == false) {
                            idsArray.splice(idsArray.indexOf(val.id), 1);
                          }
                          setSelectedFacilityCodesArray([...idsArray]);
                          handleSearchFilterChange("facilities", idsArray);
                          handleTestAnalyteJsonFilter("facilities", idsArray);
                        });
                      }
                    }}
                    onFocus={() => {
                      setTimeout(function () {
                        var searchButton = document.getElementsByClassName(
                          "facilityTreeOkButton"
                        );
                        if (searchButton.length == 0) {
                          var scrollDiv = document.getElementsByClassName(
                            "infinite-scroll-component"
                          );
                          var div = document.createElement("div");
                          div.className = "customSearchButton";
                          div.id = "customSearchButtonId";
                          var button = document.createElement("button");
                          button.className =
                            "primary btn button SearchBtnCustom facilityTreeOkButton";
                          button.innerHTML = "OK";
                          button.addEventListener("click", (event) => {
                            var facilityDiv =
                              document.getElementsByClassName("facilityTree");
                            var toggleButton =
                              facilityDiv[0].getElementsByClassName(
                                "dropdown-trigger"
                              );
                            toggleButton[0].click();
                          });

                          div.appendChild(button);
                          scrollDiv[0].parentElement.appendChild(div);
                        }
                      }, 100);
                    }}
                    className="bootstrap-demo facilityTree"
                    onKeyDown={handleKeypress}
                  />
                </Col>
                <Col md={{ span: 6 }} className="resultProviderTree">
                  <h5 className="label-style">Provider</h5>
                  <DropdownTreeSelect
                    mode="multiSelect"
                    keepTreeOnSearch={true}
                    keepChildrenOnSearch={true}
                    keepOpenOnSelect={false}
                    onFocus={() => {
                      setTimeout(function () {
                        var searchButton = document.getElementsByClassName(
                          "providerTreeOkButton"
                        );
                        if (searchButton.length == 0) {
                          var scrollDiv = document.getElementsByClassName(
                            "infinite-scroll-component"
                          );
                          var div = document.createElement("div");
                          div.className = "customSearchButton";
                          div.id = "customSearchButtonId";
                          var button = document.createElement("button");
                          button.className =
                            "primary btn button SearchBtnCustom providerTreeOkButton";
                          button.innerHTML = "OK";
                          button.addEventListener("click", (event) => {
                            var facilityDiv =
                              document.getElementsByClassName("providerTree");
                            var toggleButton =
                              facilityDiv[0].getElementsByClassName(
                                "dropdown-trigger"
                              );
                            toggleButton[0].click();
                            // divToMention.classList.remove("form-control");
                          });

                          div.appendChild(button);
                          scrollDiv[0].parentElement.appendChild(div);
                        }
                      }, 100);
                    }}
                    data={providersData}
                    onChange={(e) => {
                      if (e.id != 0) {
                        if (selectedProvidersArray.indexOf(e.id) == -1) {
                          selectedProvidersArray.push(e.id);
                        } else {
                          selectedProvidersArray.splice(
                            selectedProvidersArray.indexOf(e.id),
                            1
                          );
                        }
                        if (e.hide == undefined) {
                          handleSearchFilterChange(
                            "providers",
                            selectedProvidersArray
                          );
                          handleTestAnalyteJsonFilter(
                            "providers",
                            selectedProvidersArray
                          );
                          setShowValueField([]);
                          setSelectedProvidersArray([
                            ...selectedProvidersArray,
                          ]);
                          setProviderSearchQuery("");
                        }
                      } else {
                        const found = [];
                        found.push(getChildObjStructure(providersData, e.id));
                        var physicalFacilities = getAllPhysicalFromTree(
                          found,
                          [],
                          providerSearchQuery
                        );
                        physicalFacilities.map((val) => {
                          if (selectedProvidersArray.indexOf(val.id) == -1) {
                            selectedProvidersArray.push(val.id);
                          } else if (e.checked == false) {
                            selectedProvidersArray.splice(
                              selectedProvidersArray.indexOf(val.id),
                              1
                            );
                          }
                        });
                      }
                    }}
                    onBlur={() => {
                      handleSearchFilterChange(
                        "providers",
                        selectedProvidersArray
                      );
                      handleTestAnalyteJsonFilter(
                        "providers",
                        selectedProvidersArray
                      );
                      setShowValueField([]);
                      setSelectedProvidersArray([...selectedProvidersArray]);
                      setProviderSearchQuery("");
                    }}
                    className="bootstrap-demo providerTree"
                    onKeyDown={handleKeypress}
                  />
                  {/* <InputWithLoader
                    filter={providerFilter}
                    placeholder="Start typing..."
                    apiEndPoint=""
                    setData={setProviders}
                    setShowResults={setShowProviderResults}
                    setFilter={setProviderFilter}
                    name="insuranceCompanies"
                    aria_label="Insurance company name"
                    className="input-style form-control"
                    data_type="text"
                  />
                  {showProviderResults ? (
                    <SelectBody
                      rowClickHandler={rowClickHandlerProviders}
                      filter={providerFilter}
                      column={["Name"]}
                      dataArray={providersData}
                      setShowResults={setShowProviderResults}
                    />
                  ) : null} */}
                </Col>
              </Row>
            </Container>
            <hr></hr>
            <Row>
              <Col className="d-flex flex-row align-items-end align-content-end ">
                {searchResult.length > 0 && (
                  <>
                    <Button
                      variant="primary"
                      className="primary btn button  mb-3"
                      onClick={() => {
                        setLoader(true);
                        exportSearchResult();
                      }}
                    >
                      Excel
                    </Button>
                    {/* <Button
                      variant="primary"
                      className="primary btn button  mb-3 ml-3"
                      onClick={() => {
                        setLoader(true);
                        downloadSearchResults();
                      }}
                    >
                      Download Results
                    </Button> */}
                  </>
                )}
                <Button
                  variant="primary"
                  className="primary btn button ml-auto mb-3"
                  onClick={handleSearchClick}
                >
                  Search
                </Button>
                <Button
                  variant="primary"
                  onClick={() => resetPage()}
                  className="outline button ml-2 mb-3"
                >
                  Reset
                </Button>
              </Col>
            </Row>
            {noResultFound && (
              <Table
                className="mt-4 searchResultsTable"
                responsive
                bordered
                hover
              >
                <thead>
                  <tr className="DarkHeading">
                    <th>Name</th>
                    <th>Requisition #</th>
                    <th>Facility Code</th>
                    <th>Facility Name</th>
                    <th>Provider</th>
                    <th>Order Date</th>
                    <th>Tests Ordered</th>
                    <th>Order Status</th>
                    <th>Report Status</th>
                    <th>Laboratory</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      There are no records matching your criteria
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            {searchResult.length > 0 && (
              <>
                <Table
                  className="mt-4 searchResultsTable"
                  responsive
                  bordered
                  hover
                >
                  <thead>
                    <tr className="DarkHeading">
                      <th>Name</th>
                      <th>Requisition #</th>
                      <th>Facility Code</th>
                      <th>Facility Name</th>
                      <th>Provider</th>
                      <th>Order Date</th>
                      <th>Tests Ordered</th>
                      <th>Order Status</th>
                      <th>Report Status</th>
                      <th>Laboratory</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResult.map(
                      (value, index) =>
                        value.testOrders.length > 0 && (
                          <tr
                            className="cursorPointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (value.orderType == "Order") {
                                navigate("/order/" + value.id);
                              } else {
                                // navigate("/resultDetail/" + value.id);
                              }
                            }}
                          >
                            <td
                              className={
                                value.reportStatus != null &&
                                value.reportStatus != "Pending"
                                  ? "font-weight-bold"
                                  : ""
                              }
                            >
                              {value.patientName}
                            </td>
                            <td
                              className={
                                value.reportStatus != null &&
                                value.reportStatus != "Pending"
                                  ? "font-weight-bold"
                                  : ""
                              }
                            >
                              {value.orderNumber}
                            </td>
                            <td
                              className={
                                value.reportStatus != null &&
                                value.reportStatus != "Pending"
                                  ? "font-weight-bold"
                                  : ""
                              }
                            >
                              {value.facilityCode}
                            </td>
                            <td
                              className={
                                value.reportStatus != null &&
                                value.reportStatus != "Pending"
                                  ? "font-weight-bold"
                                  : ""
                              }
                            >
                              {value.facilityName}
                            </td>

                            <td
                              className={
                                value.reportStatus != null &&
                                value.reportStatus != "Pending"
                                  ? "font-weight-bold"
                                  : ""
                              }
                            >
                              {value.orderingProviderName == ", "
                                ? "-"
                                : value.orderingProviderName}
                            </td>
                            <td
                              className={
                                value.reportStatus != null &&
                                value.reportStatus != "Pending"
                                  ? "font-weight-bold"
                                  : ""
                              }
                            >
                              {dateFormat(value.orderDate, "mm-dd-yyyy")}
                            </td>
                            <td
                              className={
                                value.reportStatus != null &&
                                value.reportStatus != "Pending"
                                  ? "font-weight-bold"
                                  : ""
                              }
                            >
                              {value.testOrders.length > 0
                                ? value.testOrders.map((val, ind) => {
                                    const testName =
                                      typeof val == "string"
                                        ? val
                                        : val.testName;
                                    const isCancelled = val.isCancelled;
                                    const className =
                                      isCancelled === "Yes" ? "strike" : "";

                                    if (val != null && val != "") {
                                      if (
                                        ind === value.testOrders.length - 1 &&
                                        value.testOrders[ind] != undefined &&
                                        value.testOrders[ind] != null
                                      ) {
                                        return (
                                          <span
                                            Title={
                                              className != ""
                                                ? "Click for notes"
                                                : ""
                                            }
                                            key={ind}
                                            className={className}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              if (className != "") {
                                                setNoteId(value.id);
                                                setNoteModalShow(true);
                                              }
                                            }}
                                          >
                                            {testName}
                                          </span>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <span
                                              Title={
                                                className != ""
                                                  ? "Click for notes"
                                                  : ""
                                              }
                                              key={ind}
                                              className={className}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                if (className != "") {
                                                  setNoteId(value.id);
                                                  setNoteModalShow(true);
                                                }
                                              }}
                                            >
                                              {testName}
                                            </span>
                                            {", "}
                                          </>
                                        );
                                      }
                                    } else {
                                      return "";
                                    }
                                  })
                                : "-"}
                            </td>
                            <td
                              className={
                                value.reportStatus != null &&
                                value.reportStatus != "Pending"
                                  ? "font-weight-bold"
                                  : ""
                              }
                              title={
                                value.orderStatus == null
                                  ? "External Report"
                                  : ""
                              }
                            >
                              {value.orderStatus == null
                                ? "Unsolicited"
                                : value.orderStatusForFrontend}
                            </td>
                            <td
                              className={
                                value.reportStatus != null &&
                                value.reportStatus != "Pending"
                                  ? "font-weight-bold"
                                  : ""
                              }
                            >
                              {value.reportStatus == "IP"
                                ? "In Process"
                                : value.reportStatus == "CM"
                                ? "Final"
                                : value.reportStatus == null
                                ? "-"
                                : value.reportStatus}
                            </td>
                            <td
                              className={
                                value.reportStatus != null &&
                                value.reportStatus != "Pending"
                                  ? "font-weight-bold"
                                  : ""
                              }
                            >
                              {value.laboratory == null
                                ? "-"
                                : value.laboratory}
                            </td>
                            <td align="center">
                              {value.isForeign == "Y" && (
                                <>
                                  <BsInfoCircle
                                    title={"Info"}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setInfoModalMsgShow(
                                        "Call " +
                                          value.laboratory +
                                          " for results"
                                      );
                                      setInfoModalShow(true);
                                    }}
                                  />
                                </>
                              )}
                              {value.embeddedPdf != "" &&
                                value.embeddedPdf != null && (
                                  <BsPrinter
                                    title="Print"
                                    className="col-md-12 mx-auto"
                                    size={20}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // setLoader(true);
                                      // setOrderNumberState(value.orderNumber);
                                      // printOrderReport(value.orderNumber);
                                      // setLabelCopies(1);
                                      setLoader(true);
                                      downloadFile(
                                        "downloadFile/" +
                                          value.resultIdsWithSameNumber
                                      ).then((val) => setLoader(false));
                                    }}
                                  />
                                )}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </Table>

                <ReactPaginate
                  forcePage={currentPage}
                  className="pagination mt-3"
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={totalRecords}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                  activeClassName="active"
                  pageClassName="link"
                />

                <>
                  <span>showing</span>
                  <Form.Select
                    value={totalRecordPerPage}
                    className={
                      "form-select w-auto d-inline ml-1 mr-1 form-control"
                    }
                    onChange={(e) => {
                      setTotalRecordPerPage(e.target.value);
                      handleSearchFilterChange(
                        "totalRecordPerPage",
                        e.target.value
                      );
                      if (selectedFacilityArray.length == 0) {
                        handleSearchFilterChange("facilities", allFacilities);
                        handleSearchFilterChange(
                          "facilitiesIdsArray",
                          allFacilitiesIds
                        );
                      }
                      handleSearchFilterChange("pageNumber", 1);
                      setCurrentPage(0);
                      setPagenationState(e.target.value + 30);
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </Form.Select>
                  <span>per page</span>
                </>
              </>
            )}
          </div>
        </Container>
      </div>
      <Modal
        show={noteModalShow}
        onHide={() => {
          setNoteModalShow(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter note">
            Note
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {searchResult
            .filter((x) => x.id == noteId)
            .map((value, ind) =>
              value.testOrders.length > 0
                ? value.testOrders.map((val, index) => (
                    <div key={ind} style={{ whiteSpace: "pre-wrap" }}>
                      {val.notesAndComments.map((commentObj, commentIndex) => (
                        <div key={commentIndex}>
                          {commentObj.comment}
                          {commentIndex < val.notesAndComments.length - 1 && (
                            <br />
                          )}
                        </div>
                      ))}
                    </div>
                  ))
                : "No comment available."
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="primary button btn btn-primary"
            onClick={() => {
              setNoteModalShow(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <CustomFooter showOrderFooterBar={false} />
      <WarningModel
        show={modalShow}
        heading="Confirmation"
        bodytext="Are you sure you want to delete this item?"
        onHide={() => setModalShow(false)}
        onyes={onConfirmationYes}
      />

      <MsgModel
        show={infoModalShow}
        heading="Information"
        bodytext={infoModalMsgShow}
        onHide={(e) => setInfoModalShow(false)}
      />

      <div
        class="modal fade"
        id="ResultPreviewModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content" style={{ height: "600px" }}>
            <div class="modal-header">
              <h5 class="modal-title " id="exampleModalLabel">
                {"Result"}
              </h5>
              <button
                onClick={() => {}}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Container>
                <iframe
                  id="resultPreviewBlob"
                  frameBorder="0"
                  scrolling="auto"
                  height="400px"
                  width="100%"
                ></iframe>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="outline button btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {}}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade requisitionPreview"
        id="PrintPreviewDialogInOrderManifest"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="exampleModalLabelInOrderManifest"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl ">
          <div class="modal-content" style={{ height: "600px" }}>
            <div class="modal-header">
              <h5 class="modal-title " id="exampleModalLabelInOrderManifest">
                {orderPdfDetail}
              </h5>
              <button
                onClick={() => {
                  // if (props.orderData.status != "Sent") {
                  //   navigate("/orders/" + props.clinicalInputs.patientId);
                  // }
                }}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <Container>
                <Row>
                  <Col md={{ span: 9 }}>
                    <iframe
                      className="requisitionPreviewIframe"
                      src={pdfBlob}
                      frameBorder="0"
                      scrolling="auto"
                      width="100%"
                    ></iframe>
                    <Row>
                      <Col className="mt-2 d-flex justify-content-end  align-items-center">
                        Labels to Print on Requisition:
                        <Form.Select
                          disabled={printing}
                          className="w-auto ml-1"
                          name="status"
                          onChange={async (e) => {
                            setLablesCountInPdf(e.target.value);
                          }}
                          value={lablesCountInPdf}
                        >
                          <option value={8}>8</option>
                          <option value={16}>16</option>
                          <option value={24}>24</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={{ span: 3 }}>
                    <iframe
                      id="label1"
                      className="labelDiv"
                      title="Label"
                      allowtransparency={true}
                      src={
                        pdflabel + "#zoom=120%&scrollbar=0&toolbar=0&navpanes=0"
                      }
                      height={"159px"}
                      width={"88%"}
                      type="application/pdf"
                    />
                    <div className="d-flex justify-content-start  align-items-center pr-3">
                      <span>
                        Label Copies:
                        <input
                          min={1}
                          onKeyDown={(e) => {
                            if (e.target.value < 1) {
                              setLabelCopies(1);
                            }
                          }}
                          onKeyUp={(e) => {
                            if (e.target.value < 1) {
                              setLabelCopies(1);
                            }
                          }}
                          value={labelCopies}
                          type="number"
                          className="labelCopies ml-1 form-control labelCopiesInput"
                          onChange={(e) => {
                            setLabelCopies(e.target.value);
                          }}
                        ></input>
                      </span>
                      <a
                        style={{ color: "white" }}
                        className="primary button btn btn-primary mr-4"
                        disabled={labelCopies > 0 ? false : true}
                        onClick={() => {
                          ApiGetCalls(
                            "GenerateLabelString/" +
                              orderNumberState +
                              "/" +
                              labelCopies
                          ).then((value) => {
                            if (value["status"] == "Success") {
                              window.open(value["data"], "_self");
                            } else {
                              snackBar("Error! " + value["message"], "danger");
                            }
                          });
                        }}
                        // href={
                        //   props.patientDetails != undefined
                        //     ? "Atvivo:" +
                        //       props.patientDetails["patientName"] +
                        //       "&" +
                        //       props.tmpOrderNumber +
                        //       "&" +
                        //       props.patientDetails["patientDob"].replaceAll(
                        //         "-",
                        //         "/"
                        //       ) +
                        //       "&" +
                        //       moment(
                        //         new Date(props.orderData["collectionDate"])
                        //       ).format("MM/DD/yyyy hh:mm A") +
                        //       "&" +
                        //       labelCopies
                        //     : ""
                        // }
                      >
                        {"Print"}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="outline button btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  // if (props.orderData.status != "Sent") {
                  //   navigate("/orders/" + props.clinicalInputs.patientId);
                  // }
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OrderManifest;
