import { Form } from "react-bootstrap";

function InputField(props) {
  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      {/* <Form.Label>{props.formValue}</Form.Label> */}
      <h5 className="label-style">{props.formValue}</h5>
      <Form.Control
        className="input-style form-control"
        disabled={props.disabled}
        aria-label={props.formValue}
        defaultValue={props.defaultValue?.answerValue}
        required={props.required}
        name={props.formValue}
        type={props.name == "password" ? props.name : "text"}
        onBlur={(e) => {
          props.onChangeFunction(
            props.orderId != ""
              ? {
                  orderId: props.orderId,
                  testId: props.testId,
                  questionId: props.questionId,
                  answerId: null,
                  answerValue: e.target.value,
                }
              : {
                  testId: props.testId,
                  questionId: props.questionId,
                  answerId: null,
                  answerValue: e.target.value,
                }
          );
        }}
      />
    </Form.Group>
  );
}

export default InputField;
